import {
  NetworkStatus,
  gql,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import { validate } from 'uuid';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-6';
import { BsFillExclamationDiamondFill } from 'react-icons/bs';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '../../components/button';
import { Dropdown } from '../../components/dropdown';
import { Input } from '../../components/react-hook-form-6/input';
import { Label } from '../../components/label';
import { Loading } from '../../components/loading';
import { Modal } from '../../components/modal';
import { TemplateStatusTag } from '../../components/pal-levels/template-status-tag';
import { TextArea } from '../../components/text-area';
import {
  CreateLevelMutation,
  CreateLevelMutationVariables,
  DeleteLevelTreePillarTemplateMutation,
  DeleteLevelTreePillarTemplateMutationVariables,
  GeneratePillarThumbnailUrlMutation,
  GeneratePillarThumbnailUrlMutationVariables,
  LevelTreePublishedState,
  PillarPageQuery,
  PillarPageQueryVariables,
  PublishDisabledReason,
  ReorderGoalsMutation,
  ReorderGoalsMutationVariables,
  TemplateStatus,
  UpdateLevelMutation,
  UpdateLevelMutationVariables,
  UpdatePillarTemplateMutation,
  UpdatePillarTemplateMutationVariables,
} from '../../graphql/types';
import { logger } from '../../logging';
import { useNotifications } from '../../notifications';
import { buildRoute, routes } from '../../utils/routes';
import { useUrlQuery } from '../../utils/use-url-query';
import { pillarNameCharacterLimit } from '../pillars';
import { FaBars } from 'react-icons/fa';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

type Tab = 'details' | 'levels';

const descriptionCharacterLimit = 250;
const levelsDescriptionCharacterLimit = 250;

const formattedPillarPublishDisabledReasons: {
  [key in PublishDisabledReason]: string;
} = {
  NO_CHILDREN: 'Pillars require at least one level to publish.',
  NO_PUBLISHED_CHILDREN:
    'Pillars require at least one published level to publish.',
};

const formattedLevelPublishDisabledReasons: {
  [key in PublishDisabledReason]: string;
} = {
  NO_CHILDREN: 'Levels require at least one goal to publish.',
  NO_PUBLISHED_CHILDREN:
    'Levels require at least one published goal to publish.',
};

const getSelectedTab = (urlQuery: URLSearchParams): Tab =>
  (urlQuery.get('tab') || 'details') as Tab;

const Tab = (props: { tab: Tab }) => {
  const urlQuery = useUrlQuery();
  const selectedTab = getSelectedTab(urlQuery);
  const history = useHistory();
  const location = useLocation();

  return (
    <li className="flex">
      <a
        className={clsx(
          'py-5 px-4 cursor-pointer capitalize',
          props.tab === selectedTab &&
            'font-semibold border-b-4 border-primary text-primary',
        )}
        onClick={() => {
          const params = new URLSearchParams({ tab: props.tab });
          history.replace({
            pathname: location.pathname,
            search: params.toString(),
          });
        }}
      >
        {props.tab}
      </a>
    </li>
  );
};

const PILLAR_TEMPLATE_FRAGMENT = gql`
  fragment PillarTemplate on LevelTreePillarTemplate {
    id
    name
    description
    levelsDescription
    status
    canPublish
    publishDisabledReasons
    thumbnailUrl
    code
    levels {
      id
      status
      levelNumber
      goals {
        id
        name
        position
        status
      }
      canPublish
      publishDisabledReasons
    }
  }
`;

const PillarPage = (): React.ReactElement => {
  const { pillarTemplateId } = useParams<{ pillarTemplateId: string }>();
  const { data, refetch, networkStatus } = useQuery<
    PillarPageQuery,
    PillarPageQueryVariables
  >(
    gql`
      query PillarPage($id: ID!) {
        levelTreePillarTemplate(id: $id) {
          ...PillarTemplate
        }
        levelTreePillarTemplateCodes
      }
      ${PILLAR_TEMPLATE_FRAGMENT}
    `,
    {
      variables: {
        id: pillarTemplateId,
      },
      fetchPolicy: 'cache-first',
      skip: !validate(pillarTemplateId),
    },
  );

  if (networkStatus === NetworkStatus.loading) {
    // networkStatus check required to make sure form doesn't reset when query is re-fetched
    return <Loading />;
  }

  if (!data?.levelTreePillarTemplate) {
    return <span>Pillar not found</span>;
  }

  return (
    <PillarPageTemplate
      levelTreePillarTemplate={data.levelTreePillarTemplate}
      onThumbnailUploadCompleted={async () => {
        await refetch();
      }}
      levelTreePillarTemplateCodes={data.levelTreePillarTemplateCodes}
    />
  );
};

const PillarPageTemplate = (props: {
  levelTreePillarTemplate: {
    id: string;
    name?: string | null;
    description?: string | null;
    levelsDescription?: string | null;
    status?: TemplateStatus | null;
    canPublish?: boolean | null;
    publishDisabledReasons?: PublishDisabledReason[] | null;
    thumbnailUrl?: string | null;
    code?: string | null;
    levels?:
      | {
          id: string;
          levelNumber: number;
          status?: TemplateStatus | null;
          goals?:
            | {
                id: string;
                name?: string | null;
                position: number;
                status?: TemplateStatus | null;
              }[]
            | null;
          canPublish?: boolean | null;
          publishDisabledReasons?: PublishDisabledReason[] | null;
        }[]
      | null;
  };
  levelTreePillarTemplateCodes?: string[] | null;
  onThumbnailUploadCompleted: () => Promise<void>;
}): React.ReactElement => {
  const submittingVariant = useRef<LevelTreePublishedState>();
  const urlQuery = useUrlQuery();
  const selectedTab = getSelectedTab(urlQuery);
  const [showLevelModal, setShowLevelModal] = useState(false);
  const [showDeletePillarModal, setShowDeletePillarModal] = useState(false);
  const apolloClient = useApolloClient();
  const history = useHistory();
  const showNotification = useNotifications();

  const [updatePillar, { loading: updatePillarLoading }] = useMutation<
    UpdatePillarTemplateMutation,
    UpdatePillarTemplateMutationVariables
  >(gql`
    mutation UpdatePillarTemplate($input: UpdateLevelTreePillarTemplateInput!) {
      updateLevelTreePillarTemplate(input: $input) {
        pillarTemplate {
          ...PillarTemplate
        }
      }
    }
    ${PILLAR_TEMPLATE_FRAGMENT}
  `);

  const [createLevel, { loading: createLevelLoading }] = useMutation<
    CreateLevelMutation,
    CreateLevelMutationVariables
  >(gql`
    mutation CreateLevel($input: CreateLevelTreeLevelTemplateInput!) {
      createLevelTreeLevelTemplate(input: $input) {
        levelTemplate {
          id
          pillar {
            ...PillarTemplate
          }
        }
      }
    }
    ${PILLAR_TEMPLATE_FRAGMENT}
  `);

  const [deletePillar, { loading: deletePillarLoading }] = useMutation<
    DeleteLevelTreePillarTemplateMutation,
    DeleteLevelTreePillarTemplateMutationVariables
  >(
    gql`
      mutation DeleteLevelTreePillarTemplate(
        $input: DeleteLevelTreePillarTemplateInput!
      ) {
        deleteLevelTreePillarTemplate(input: $input) {
          levelTreePillarTemplates(problemTypes: [WEIGHT_LOSS]) {
            id
          }
        }
      }
    `,
    {
      onCompleted(data) {
        apolloClient.writeQuery({
          query: gql`
            query DeleteLevelTreePillarTemplateCacheUpdate(
              $pillarTemplateId: ID!
            ) {
              levelTreePillarTemplates(problemTypes: [WEIGHT_LOSS]) {
                id
              }
              levelTreePillarTemplate(id: $pillarTemplateId) {
                id
              }
            }
          `,
          data: {
            levelTreePillarTemplates:
              data.deleteLevelTreePillarTemplate?.levelTreePillarTemplates,
            levelTreePillarTemplate: null,
          },
          variables: {
            pillarTemplateId: props.levelTreePillarTemplate.id,
          },
        });
      },
    },
  );

  const updateLoadingLevelId = useRef<string>();
  const [updateLevel, { loading: updateLevelLoading }] = useMutation<
    UpdateLevelMutation,
    UpdateLevelMutationVariables
  >(gql`
    mutation UpdateLevel($input: UpdateLevelTreeLevelTemplateInput!) {
      updateLevelTreeLevelTemplate(input: $input) {
        levelTemplate {
          id
          publishedState
          canPublish
          publishDisabledReasons
          status
          pillar {
            id
            publishedState
            canPublish
            publishDisabledReasons
            status
          }
        }
      }
    }
  `);

  const form = useForm<{
    name: string;
    description: string;
    levelsDescription: string;
    code: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      name: props.levelTreePillarTemplate?.name ?? '',
      description: props.levelTreePillarTemplate?.description ?? '',
      levelsDescription: props.levelTreePillarTemplate?.levelsDescription ?? '',
      code: props.levelTreePillarTemplate.code ?? undefined,
    },
  });
  const handleSubmit = (publishedState: LevelTreePublishedState) =>
    form.handleSubmit(async (data) => {
      submittingVariant.current = publishedState;
      await updatePillar({
        variables: {
          input: {
            id: props.levelTreePillarTemplate.id,
            name: data.name,
            description: data.description,
            levelsDescription: data.levelsDescription,
            publishedState,
            code: data.code,
          },
        },
      });
      form.reset(data);
      submittingVariant.current = undefined;
    })();

  if (!props.levelTreePillarTemplate.status) {
    return <span>Some information for the pillar could not be found.</span>;
  }

  const formValues = form.watch();
  const { isDirty, isValid } = form.formState;
  const draftSubmitLoading =
    updatePillarLoading && submittingVariant.current === 'DRAFT';
  const publishedSubmitLoading =
    updatePillarLoading && submittingVariant.current === 'PUBLISHED';

  return (
    <>
      <form>
        <div className="bg-white pt-5 px-6 shadow-md">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-semibold">
                Pillar: {props.levelTreePillarTemplate.name}
              </h2>
              <TemplateStatusTag
                status={props.levelTreePillarTemplate.status}
              />
            </div>
            <div className="flex gap-2">
              <div className="flex">
                <Button
                  fullWidth
                  size="small"
                  variant="outline"
                  onClick={() => handleSubmit('DRAFT')}
                  loading={draftSubmitLoading}
                  disabled={!isDirty || !isValid || publishedSubmitLoading}
                >
                  Save changes
                </Button>
              </div>
              <div className="tooltip">
                <Button
                  fullWidth
                  fillHeight
                  size="small"
                  onClick={() => handleSubmit('PUBLISHED')}
                  loading={publishedSubmitLoading}
                  disabled={
                    (!isDirty &&
                      props.levelTreePillarTemplate.status === 'LIVE') ||
                    !props.levelTreePillarTemplate.canPublish ||
                    !isValid ||
                    draftSubmitLoading
                  }
                >
                  Publish changes
                </Button>
                {props.levelTreePillarTemplate.publishDisabledReasons &&
                  props.levelTreePillarTemplate.publishDisabledReasons.length >
                    0 && (
                    <span className="tooltiptext text-sm bg-black text-white p-2 rounded-md normal-case font-normal">
                      {props.levelTreePillarTemplate.publishDisabledReasons
                        .map((r) => formattedPillarPublishDisabledReasons[r])
                        .join(', ')}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <ul className="flex flex-row border-b border-slate-300 gap-2">
            <Tab tab="details" />
            <Tab tab="levels" />
          </ul>
        </div>
        <div className="mt-6 mx-3">
          <div
            className={clsx(
              selectedTab === 'details' ? 'block' : 'hidden',
              'bg-white w-1/2 shadow-md',
            )}
          >
            <div className="p-4 flex flex-col gap-3">
              <Input
                label="Name*"
                name="name"
                ref={form.register({
                  validate: {
                    trailingWhitespace: (v) =>
                      v.trim() === v || 'Cannot have trailing whitespace',
                    required: (v) => !!v || 'Please enter a name',
                  },
                })}
                description="Visible to patients"
                hint={`${
                  pillarNameCharacterLimit -
                  (formValues.name ? formValues.name.length : 0)
                } characters remaining`}
                errorMessage={form.errors.name?.message}
                maxLength={pillarNameCharacterLimit}
              />
              <div>
                <div className="flex flex-col gap-1">
                  <Label htmlFor="description">Description*</Label>
                  <p className="text-sm text-slate-700">Visible to patients</p>
                  <p className="text-sm text-slate-700 italic">
                    A short summary of this pillar and why it’s part of the
                    program.
                  </p>
                </div>
                <TextArea
                  name="description"
                  placeholder=""
                  ref={form.register({
                    validate: {
                      trailingWhitespace: (v) =>
                        v.trim() === v || 'Cannot have trailing whitespace',
                    },
                  })}
                  rows={6}
                  hint={`${
                    descriptionCharacterLimit -
                    (formValues.description ? formValues.description.length : 0)
                  } characters remaining`}
                  errorMessage={form.errors.description?.message}
                  maxLength={descriptionCharacterLimit}
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label>Thumbnail*</Label>
                <ThumbnailUpload
                  thumbnailUrl={props.levelTreePillarTemplate.thumbnailUrl}
                  pillarTemplateId={props.levelTreePillarTemplate.id}
                  onUploadCompleted={() => props.onThumbnailUploadCompleted()}
                />
              </div>
            </div>
            <div className="p-4 border-t">
              <Dropdown
                label="Code*"
                name="code"
                description="Internal use only"
                options={
                  props.levelTreePillarTemplateCodes?.map((c) => ({
                    label: c,
                    value: c,
                  })) || []
                }
                control={form.control}
                tooltipProps={{
                  hoverText: `This code allows pillars to be programmatically identified and is used to enable features like linking pillars to the quiz.
                If you want to enable these features for a new pillar, you'll need to request a new code from engineering.`,
                }}
              />
            </div>
          </div>
          <div
            className={clsx(
              selectedTab === 'levels' ? 'block' : 'hidden',
              'bg-white shadow-md',
            )}
          >
            <div className="flex flex-col gap-4 p-4">
              <TextArea
                label="Levels Description*"
                description={
                  <>
                    Visible to patients
                    <br />A short explanation of what levels in this pillar mean
                    and how they work.
                    <br />
                    &nbsp;
                    <br />
                    E.g. &apos;Your Fuel level reflects your knowledge of and
                    adherence to a balanced diet.
                    <br />
                    The lower your level, the more room you have to grow&apos;.
                  </>
                }
                name="levelsDescription"
                placeholder=""
                ref={form.register({
                  validate: {
                    trailingWhitespace: (v) =>
                      v.trim() === v || 'Cannot have trailing whitespace',
                  },
                })}
                rows={6}
                hint={`${
                  levelsDescriptionCharacterLimit -
                  (formValues.levelsDescription
                    ? formValues.levelsDescription.length
                    : 0)
                } characters remaining`}
                errorMessage={form.errors.levelsDescription?.message}
                maxLength={levelsDescriptionCharacterLimit}
              />
              <div>
                <h3 className="heading-sm mb-1">Levels Structure</h3>
                <p className="text-sm text-slate-700">
                  A maximum of 10 levels can be added.
                </p>
              </div>
              {props.levelTreePillarTemplate.levels?.map((l) => (
                <div
                  key={l.id}
                  className="flex flex-col w-full border border-slate-400 rounded"
                >
                  <div className="bg-slate-200 rounded-t px-6 py-4 flex flex-row justify-between items-center">
                    <h3 className="text-xl font-semibold">
                      Level {l.levelNumber}
                    </h3>
                    <div className="flex flex-row gap-4 items-center">
                      <Link
                        to={buildRoute.goals({
                          pillarId: props.levelTreePillarTemplate.id,
                          levelId: l.id,
                        })}
                        className="text-blue-500 underline text-sm"
                      >
                        View
                      </Link>
                      {l.status === 'LIVE' ? (
                        <TemplateStatusTag status={l.status} />
                      ) : l.status ? (
                        <div className="tooltip">
                          <Button
                            size="small"
                            variant="outline"
                            color="success"
                            onClick={async () => {
                              updateLoadingLevelId.current = l.id;
                              try {
                                await updateLevel({
                                  variables: {
                                    input: {
                                      id: l.id,
                                      publishedState: 'PUBLISHED',
                                    },
                                  },
                                });
                              } finally {
                                updateLoadingLevelId.current = undefined;
                              }
                            }}
                            loading={
                              updateLoadingLevelId.current === l.id &&
                              updateLevelLoading
                            }
                            disabled={!l.canPublish}
                          >
                            Publish
                          </Button>
                          {l.publishDisabledReasons &&
                            l.publishDisabledReasons.length > 0 && (
                              <span className="tooltiptext text-sm bg-black text-white p-2 rounded-md normal-case font-normal z-50">
                                {l.publishDisabledReasons
                                  .map(
                                    (r) =>
                                      formattedLevelPublishDisabledReasons[r],
                                  )
                                  .join(', ')}
                              </span>
                            )}
                        </div>
                      ) : (
                        'Status not found'
                      )}
                    </div>
                  </div>
                  <div className="p-6 border-t border-slate-400">
                    <GoalsTable
                      goals={l.goals}
                      pillarTemplateId={props.levelTreePillarTemplate.id}
                      levelTemplateId={l.id}
                    />
                  </div>
                </div>
              ))}
              <div>
                <Button
                  variant="outline"
                  size="small"
                  onClick={() => setShowLevelModal(true)}
                  disabled={
                    (props.levelTreePillarTemplate.levels?.length ?? 0) >= 10
                  }
                >
                  + Create level
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <Button
              variant="solid"
              color="danger"
              onClick={(): void => {
                setShowDeletePillarModal(true);
              }}
            >
              Delete Pillar
            </Button>
          </div>
        </div>
      </form>
      <Modal show={showLevelModal} onClose={() => setShowLevelModal(false)}>
        <div className="bg-slate-200 p-8 px-6 flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold">
              Are you sure you want to add a new level?
            </p>
            <p>
              A new level can remain as draft until you want to publish it, but
              it can’t be deleted.
            </p>
          </div>
          <div className="flex gap-5">
            <Button
              fullWidth
              variant="outline"
              color="danger"
              onClick={() => setShowLevelModal(false)}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              loading={createLevelLoading}
              color="success"
              onClick={async () => {
                await createLevel({
                  variables: {
                    input: {
                      pillarTemplateId: props.levelTreePillarTemplate.id,
                    },
                  },
                });
                setShowLevelModal(false);
              }}
            >
              Create Level
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        show={showDeletePillarModal}
        onClose={() => setShowDeletePillarModal(false)}
      >
        <div className="bg-slate-200 p-8 px-6 flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold">
              Are you sure you want to delete the{' '}
              {props.levelTreePillarTemplate.name} pillar?
            </p>
            <p>This action is not reversible.</p>
          </div>
          <div className="flex gap-5">
            <Button
              fullWidth
              variant="outline"
              color="primary"
              onClick={() => setShowDeletePillarModal(false)}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              loading={deletePillarLoading}
              color="danger"
              onClick={async () => {
                await deletePillar({
                  variables: {
                    input: {
                      pillarTemplateId: props.levelTreePillarTemplate.id,
                    },
                  },
                });
                setShowDeletePillarModal(false);
                showNotification({
                  type: 'success',
                  message: `${props.levelTreePillarTemplate.name} pillar deleted`,
                });
                history.push(routes.pillars);
              }}
            >
              Delete {props.levelTreePillarTemplate.name} pillar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ThumbnailUpload = ({
  pillarTemplateId,
  thumbnailUrl,
  onUploadCompleted,
}: {
  pillarTemplateId: string;
  thumbnailUrl?: string | null | undefined;
  onUploadCompleted: () => Promise<void>;
}) => {
  const [generateThumbnailUrl] = useMutation<
    GeneratePillarThumbnailUrlMutation,
    GeneratePillarThumbnailUrlMutationVariables
  >(gql`
    mutation GeneratePillarThumbnailUrl(
      $input: GenerateLevelTreePillarThumbnailSignedUploadUrlInput!
    ) {
      generateLevelTreePillarThumbnailSignedUploadUrl(input: $input) {
        signedUploadUrl
        pillarTemplate {
          id
          publishedState
        }
      }
    }
  `);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const showNotification = useNotifications();

  const uploadFile = async (file: File, signedUrl: string) => {
    const options: RequestInit = {
      method: 'PUT',
      body: file,
      headers: { 'content-type': file.type },
    };
    const response = await fetch(signedUrl, options);
    if (!response.ok) {
      throw new Error(
        `Failed to upload file to: ${signedUrl}, status: ${response.status}, statusText: ${response.statusText}`,
      );
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    try {
      const file = e.target.files?.[0];
      if (!file) {
        throw new Error('No file uploaded');
      }
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
      const { data } = await generateThumbnailUrl({
        variables: { input: { fileExtension, pillarTemplateId } },
      });
      const signedUploadUrl =
        data?.generateLevelTreePillarThumbnailSignedUploadUrl?.signedUploadUrl;
      if (signedUploadUrl) {
        await uploadFile(file, signedUploadUrl);
      }
    } catch (err) {
      logger.error('Failed to upload file', { err });
      showNotification({
        message: 'Failed to upload file, please contact #help-technology',
        type: 'error',
      });
    } finally {
      try {
        await onUploadCompleted();
      } catch (err) {
        logger.error('onUploadCompleted failed', { err });
        showNotification({
          message:
            'Failed to reload the pillar, please refresh the page before continuing.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex gap-2">
      <div className="h-28 w-28 flex justify-center items-center">
        {thumbnailUrl ? (
          <img className="max-w-[100%] max-h-[100%]" src={thumbnailUrl} />
        ) : (
          <div>No image</div>
        )}
      </div>
      <div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          className="hidden"
          accept=".svg"
        />
        <Button
          fullWidth
          size="small"
          variant="outline"
          onClick={() => fileInputRef?.current?.click()}
          loading={loading}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

const GoalsTable = (props: {
  pillarTemplateId: string;
  levelTemplateId: string;
  goals?:
    | {
        id: string;
        name?: string | null;
        position: number;
        status?: TemplateStatus | null;
      }[]
    | null;
}) => {
  const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);
  const form = useForm<{
    goals: {
      goalId: string;
      name?: string | null;
      position: number;
      status?: TemplateStatus | null;
    }[];
  }>({
    defaultValues: {
      goals:
        props.goals?.map((g) => ({
          goalId: g.id,
          name: g.name,
          position: g.position,
          status: g.status,
        })) ?? [],
    },
  });
  const { fields, move } = useFieldArray({
    control: form.control,
    name: 'goals',
  });
  const [reorder, { loading }] = useMutation<
    ReorderGoalsMutation,
    ReorderGoalsMutationVariables
  >(gql`
    mutation ReorderGoals($input: [ReorderLevelTreeGoalTemplateInput!]!) {
      reorderLevelTreeGoalTemplates(input: $input) {
        parentTemplate {
          ... on LevelTreeLevelTemplate {
            id
            goals {
              id
              position
            }
          }
        }
      }
    }
  `);
  const handleSubmit = async () => {
    await reorder({
      variables: {
        input: fields.map((g, i) => {
          if (!g.goalId) {
            throw new Error('expected goal template id to be defined');
          }
          return {
            levelTreeGoalTemplateId: g.goalId,
            position: i,
          };
        }),
      },
      onCompleted: () =>
        form.reset({ goals: fields.map(({ id, ...others }) => others) }),
    });
  };

  const handleDrag: OnDragEndResponder = async ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  const { isDirty } = form.formState;

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="rounded border border-slate-400 overflow-hidden">
          <table className="w-full">
            <thead className="border-b border-slate-400 bg-slate-200">
              <tr>
                <th
                  colSpan={5}
                  className="py-2 px-4 text-left font-semibold text-sm"
                >
                  Goals
                </th>
              </tr>
            </thead>
            {fields.length > 0 ? (
              <DragDropContext onDragEnd={handleDrag}>
                <Droppable droppableId="goals-items">
                  {(droppableProvided) => (
                    <tbody
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                    >
                      {fields.map((g, i) => {
                        return (
                          <Draggable
                            key={`goals[${i}]`}
                            draggableId={`item-${i}`}
                            index={i}
                          >
                            {(draggableProvided) => (
                              <tr
                                key={g.id}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                className="even:bg-slate-200 border-b bg-white border-slate-400 last:border-b-0"
                              >
                                <td className="py-2 px-4 border-r border-slate-400">
                                  <div {...draggableProvided.dragHandleProps}>
                                    <FaBars className="text-sm" />
                                  </div>
                                </td>
                                <td className="py-2 px-4 border-r border-slate-400 text-sm">
                                  {i + 1}
                                </td>
                                <td className="w-full py-2 px-4 border-r border-slate-400 text-sm">
                                  {g.name}
                                </td>
                                <td className="py-2 px-4 border-r border-slate-400">
                                  {g.goalId ? (
                                    <Link
                                      to={buildRoute.goal(g.goalId)}
                                      className="text-blue-500 underline text-sm"
                                    >
                                      View
                                    </Link>
                                  ) : (
                                    'Goal link could not be found'
                                  )}
                                </td>
                                <td className="py-2 px-4 flex justify-center">
                                  {g.status ? (
                                    <TemplateStatusTag status={g.status} />
                                  ) : (
                                    'Status not found'
                                  )}
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <tbody>
                <tr>
                  <td className="py-2 px-4 flex flex-row items-center gap-2 w-full border-r border-slate-400">
                    <BsFillExclamationDiamondFill className="text-red-500 w-4 h-4" />
                    <span className="text-sm">
                      There are no goals. You must publish a goal to be able to
                      publish the level.
                    </span>
                  </td>
                  <td className="py-2 text-sm text-center">
                    <Link
                      to={buildRoute.goals({
                        createGoal: 'true',
                        pillarId: props.pillarTemplateId,
                        levelId: props.levelTemplateId,
                      })}
                      className="text-blue-500 underline text-sm"
                    >
                      Add goal
                    </Link>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {isDirty && (
          <div className="p-4 bg-yellow-100 rounded flex flex-row justify-between items-center">
            <div className="flex flex-col gap-2">
              <p className="text-sm font-semibold">Order changed</p>
              <p className="text-sm">
                These goals have changed order and must be confirmed for
                patients to see the changes.
              </p>
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="small"
                onClick={() => form.reset()}
              >
                Undo
              </Button>
              <Button
                size="small"
                onClick={() => setShowConfirmOrderModal(true)}
              >
                Confirm order
              </Button>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={showConfirmOrderModal}
        onClose={() => setShowConfirmOrderModal(false)}
      >
        <div className="bg-slate-200 p-8 px-6 flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold">Confirm new goal ordering</p>
            <p>
              By confirming, the order of these goals will change for the
              patient. This will affect the order they complete their goals for
              the level they’re within.
            </p>
          </div>
          <div className="flex gap-5">
            <Button
              fullWidth
              variant="outline"
              color="danger"
              onClick={() => setShowConfirmOrderModal(false)}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              loading={loading}
              color="success"
              onClick={async () => {
                await handleSubmit();
                setShowConfirmOrderModal(false);
              }}
            >
              Confirm order
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PillarPage;
