import React from 'react';
import {
  Maybe,
  SubmittedQuizApplicationInstance,
  TrackerDetails,
  TrackerMeta,
} from 'graphql/types';
import { QuizApplicationResponses } from './common/quizApplicationResponses';
import { QuestionFormatter } from './common/questionFormatter';
import clsx from 'clsx';
import { AnswerFormatter } from './common/responseFormatter';
import { RefetchTrackerData, TrackerMetric } from '../types';
import {
  getDeletedAt,
  getKeyForMetric,
  getLatestUpdatedAt,
  getQuestionCopyForMetric,
  getQuizFlagLevel,
  getUnitForMetric,
} from '../utils/trackerMeta';
import { formatDateAndTime } from 'utils/misc';
import { TrackerActions } from '../tracker-actions/tracker-actions';

const TrackerOverrides = ({
  meta,
  metric,
}: {
  meta: Maybe<TrackerMeta>;
  metric: TrackerMetric;
}): React.ReactElement | null => {
  const overrides = meta?.overrides;
  const valueKey = getKeyForMetric(metric);

  if (!overrides?.length) {
    return null;
  }

  return (
    <div className="space-y-2">
      {overrides.map((override, i) => {
        const value = override[valueKey];

        return (
          <div key={override.id} className="flex justify-between space-x-3">
            <div>{value}</div>
            <div>
              {i === overrides.length - 1 ? 'Created' : 'Updated'}{' '}
              {override.createdAt
                ? formatDateAndTime(override.createdAt)
                : 'Date not available'}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const WeightTrackerResponse = ({
  metric,
  value,
  meta,
  submittedAt,
  refetch,
  nominatedStartingValue,
}: {
  metric: TrackerMetric;
  value: Maybe<number>;
  meta: Maybe<TrackerMeta>;
  submittedAt: string;
  refetch: RefetchTrackerData;
  nominatedStartingValue?: boolean;
}): React.ReactElement => {
  const deletedAt = getDeletedAt(meta);
  const latestUpdatedAt = getLatestUpdatedAt(meta, metric);
  const metricUnit = getUnitForMetric(metric);

  return (
    <div className={clsx('px-4 pb-2 odd:bg-slate-200')}>
      <div className="flex justify-between space-x-3">
        <QuestionFormatter>
          {getQuestionCopyForMetric(metric)}
        </QuestionFormatter>
        {meta?.observationId && value && !meta?.deletedAt && (
          <div className="pt-2 pb-3">
            {!nominatedStartingValue && (
              <TrackerActions
                observationId={meta.observationId}
                lastUpdatedAtDate={latestUpdatedAt}
                entryDate={submittedAt}
                type={`${metric} (${metricUnit})`}
                value={value}
                refetch={refetch}
              />
            )}
          </div>
        )}
      </div>
      <div className="space-y-2">
        <AnswerFormatter flag={getQuizFlagLevel(meta)}>
          <div className="flex justify-between space-x-3">
            <div>{value}</div>
            <div className="flex flex-col items-end space-y-2">
              {deletedAt && <div>Deleted {deletedAt}</div>}
              {latestUpdatedAt && <div>Updated {latestUpdatedAt}</div>}
            </div>
          </div>
        </AnswerFormatter>
        <TrackerOverrides meta={meta} metric={metric} />
      </div>
    </div>
  );
};

export const WeightLossQuestionnaire = ({
  trackerQuiz,
  refetch,
}: {
  trackerQuiz: TrackerDetails;
  refetch: RefetchTrackerData;
}): React.ReactElement | null => {
  if (!trackerQuiz) {
    return null;
  }

  const submittedAt = trackerQuiz.submittedAt
    ? formatDateAndTime(trackerQuiz.submittedAt)
    : 'Date not available';

  return (
    <div>
      {trackerQuiz.bodyWeight && (
        <WeightTrackerResponse
          metric="WEIGHT"
          value={trackerQuiz.bodyWeight}
          meta={trackerQuiz.bodyWeightMeta}
          submittedAt={submittedAt}
          refetch={refetch}
          nominatedStartingValue={trackerQuiz.nominatedStartingValue ?? false}
        />
      )}
      {trackerQuiz.waistSize && (
        <WeightTrackerResponse
          metric="WAIST_SIZE"
          value={trackerQuiz.waistSize}
          meta={trackerQuiz.waistSizeMeta}
          submittedAt={submittedAt}
          refetch={refetch}
        />
      )}
      {trackerQuiz.quizResponses && (
        <QuizApplicationResponses
          quizResponses={
            trackerQuiz.quizResponses as SubmittedQuizApplicationInstance[]
          }
        />
      )}
    </div>
  );
};
