import { Maybe, PaymentGateway } from 'graphql/types';

export const PaymentMethodSection = ({
  defaultPaymentMethod,
}: {
  defaultPaymentMethod: Maybe<PaymentGateway>;
}): React.ReactElement => (
  <div className="overflow-hidden rounded shadow bg-white my-4">
    <div className="px-4 py-5 border-b border-slate-200">
      <h3 className="text-lg leading-6 font-medium text-slate-900">
        Default Payment Gateway
      </h3>
    </div>
    <div>
      <dl>
        <div className="bg-slate-100 px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm leading-5 text-slate-500">Gateway</dt>
          <dd className="text-sm leading-5 sm:mt-0 sm:col-span-2">
            {defaultPaymentMethod || 'None'}
          </dd>
        </div>
      </dl>
    </div>
  </div>
);
