import { config } from 'config';
import {
  Maybe,
  OrderShippingCarrier,
  OrderShippingStatus,
  PluripharmOrderStatus,
  PluripharmShipmentStatus,
  OrderQuery,
} from 'graphql/types';
import React from 'react';
import { CardField } from './card-field';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';

export const FulfillmentInfo = ({
  shipping,
}: {
  shipping?: NonNullable<OrderQuery['order']>['shipping'] | null;
}): React.ReactElement | null => {
  if (!shipping) {
    return (
      <div className="px-4 py-2 text-sm text-slate-500">
        No details available yet
      </div>
    );
  }

  switch (shipping.__typename) {
    case 'ShopifyOrderShippingDetails':
      return <ShopifyFulfillmentInfo shopifyOrderId={shipping.id} />;
    case 'AponsOrderShippingDetails':
      return (
        <AponsFulfillmentInfo
          status={shipping.aponsStatus}
          carrier={shipping.carrier}
          trackingNumber={shipping.trackingNumber}
          trackingUrl={shipping.trackingUrl}
          isTest={shipping.isTest}
          fulfilledAt={
            shipping.fulfilledAt ? new Date(shipping.fulfilledAt) : null
          }
        />
      );
    case 'EvermedOrderShippingDetails':
      return (
        <EvermedFulfillmentInfo
          evermedOrderId={shipping.id}
          trackingNumber={shipping.trackingNumber}
          trackingUrl={shipping.trackingUrl}
        />
      );
    case 'PluripharmOrderShippingDetails':
      return (
        <PluripharmFulfillmentInfo
          status={shipping.pluripharmStatus}
          pluripharmOrderId={shipping.pluripharmOrderId}
          parcels={shipping.parcels}
        />
      );
    default:
      return null;
  }
};

const EvermedFulfillmentInfo = ({
  evermedOrderId,
  trackingNumber,
  trackingUrl,
}: {
  evermedOrderId: string;
  trackingNumber: Maybe<string>;
  trackingUrl: Maybe<string>;
}): React.ReactElement => {
  if (evermedOrderId) {
    return (
      <div>
        <CardField label="Evermed Order">{evermedOrderId}</CardField>
        <CardField label="Evermed Tracking Number">{trackingNumber}</CardField>
        {trackingUrl && (
          <CardField label="Evermed Tracking URL">
            <a
              className="underline"
              href={trackingUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {trackingUrl}
            </a>
            <CopyToClipboardButton value={trackingUrl} />
          </CardField>
        )}
      </div>
    );
  }
  return <></>;
};

const ShopifyFulfillmentInfo = ({
  shopifyOrderId,
}: {
  shopifyOrderId: string;
}): React.ReactElement => {
  return (
    <CardField label="Shopify">
      <a
        className="underline"
        href={`https://${config.shopifyHostname}/admin/orders/${shopifyOrderId}?orderListBeta=true`}
        target="_blank"
        rel="noreferrer"
      >
        {shopifyOrderId}
      </a>
    </CardField>
  );
};

const AponsFulfillmentInfo = ({
  status,
  carrier,
  trackingNumber,
  trackingUrl,
  isTest,
  fulfilledAt,
}: {
  status: Maybe<OrderShippingStatus>;
  carrier: Maybe<OrderShippingCarrier>;
  trackingNumber: Maybe<string>;
  trackingUrl: Maybe<string>;
  isTest: Maybe<boolean>;
  fulfilledAt: Maybe<Date>;
}): React.ReactElement => {
  return (
    <div>
      <CardField label="Apons Status">
        {isTest ? 'TEST ORDER' : status}
      </CardField>
      <CardField label="Apons Carrier">{carrier}</CardField>
      <CardField label="Apons Tracking Number">{trackingNumber}</CardField>
      <CardField label="Apons Tracking URL">
        <a
          className="underline"
          {...(trackingUrl ? { href: trackingUrl } : {})}
          target="_blank"
          rel="noreferrer"
        >
          {trackingUrl}
        </a>
      </CardField>
      <CardField label="Fulfilled At (UTC)">
        {fulfilledAt?.toUTCString()}
      </CardField>
    </div>
  );
};

const PluripharmFulfillmentInfo = ({
  status,
  pluripharmOrderId,
  parcels,
}: {
  status?: PluripharmOrderStatus | null;
  pluripharmOrderId?: string | null;
  parcels?:
    | {
        id: string;
        trackingId?: string | null;
        trackingUrl?: string | null;
        carrier?: string | null;
        status?: PluripharmShipmentStatus | null;
      }[]
    | null;
}): React.ReactElement => {
  return (
    <div>
      <CardField label="Pluripharm Status">{status}</CardField>
      <CardField label="Pluripharm Order ID">{pluripharmOrderId}</CardField>
      {parcels?.map((p) => (
        <>
          <CardField label="Parcel ID">{p.id}</CardField>
          <CardField label="Carrier">{p.carrier}</CardField>
          <CardField label="Tracking ID">{p.trackingId}</CardField>
          <CardField label="Tracking URL">
            {p.trackingUrl && (
              <a
                className="underline"
                href={p.trackingUrl}
                target="_blank"
                rel="noreferrer"
              >
                {p.trackingUrl}
              </a>
            )}
          </CardField>
          <CardField label="Status">{p.status}</CardField>
        </>
      ))}
    </div>
  );
};
