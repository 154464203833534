import { gql, useMutation, useQuery } from '@apollo/client';
import { Tag } from 'components/tag';
import { config } from 'config';
import clsx from 'clsx';
import {
  CreateShopItemMutation,
  CreateShopItemMutationVariables,
  ProblemType,
  ShopItemAvailability,
  ShopPageQuery,
  ShopPageQueryVariables,
  UpdateShopItemAvailabilityMutation,
  UpdateShopItemAvailabilityMutationVariables,
  UpdateShopItemTitleMutation,
  UpdateShopItemTitleMutationVariables,
  UpdateShopItemVisibilityMutation,
  UpdateShopItemVisibilityMutationVariables,
  UpdateShopSectionInput,
  UpdateShopSectionMutation,
  UpdateShopSectionMutationVariables,
} from 'graphql/types';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Switch } from 'components/switch';
import { CellProps, Column, useTable } from 'react-table';
import {
  Colors,
  getProblemTypeFromString,
  getProblemTypeColor,
} from 'utils/misc';
import { Link } from 'react-router-dom';
import { routes } from 'utils/routes';
import { GoLinkExternal, GoPencil } from 'react-icons/go';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { Loading } from 'components/loading';
import { Button } from 'components/button';
import { requiredValidation } from 'utils/form-validation';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { z } from 'zod';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Tooltip } from 'components/tooltip';
import { Modal } from 'components/modal';
import { Input } from 'components/react-hook-form-6/input';
import { useNotifications } from 'notifications';
import { useForm } from 'react-hook-form-6';
import { Dropdown, Option } from 'components/dropdown';
import { FaBars } from 'react-icons/fa';
import { Copyable } from 'components/copyable';

const shopConfigSchema = z.object({
  defaultSectionId: z.string().optional(),
});

const SHOP_ITEM_FRAGMENT = gql`
  fragment ShopItemFragment on ShopItem {
    id
    title
    availability
    plugsExposure
    pafInitialExposure
    otcOffering {
      id
      friendlyName
      status
      problemTypes
    }
  }
`;

const shopPageQuery = gql`
  query ShopPage {
    shopItems {
      ...ShopItemFragment
    }
    shopSections {
      id
      ... on ItemListShopSection {
        items {
          ...ShopItemFragment
        }
      }
    }
    offerings(problemTypes: [WEIGHT_LOSS]) {
      id
      friendlyName
      status
      sequenceSets {
        id
        sequences {
          id
        }
      }
    }
  }
  ${SHOP_ITEM_FRAGMENT}
`;

const CreateShopItemModal = (props: {
  showModal: boolean;
  offeringOptions: Option[];
  onCloseModal: () => void;
}): ReactElement => {
  const { control, handleSubmit, errors, formState } = useForm<{
    offering: string;
  }>();
  const showNotification = useNotifications();

  const [createShopItem] = useMutation<
    CreateShopItemMutation,
    CreateShopItemMutationVariables
  >(
    gql`
      mutation CreateShopItem($input: CreateShopItemInput!) {
        createShopItem(input: $input) {
          shopItem {
            ...ShopItemFragment
          }
        }
      }
      ${SHOP_ITEM_FRAGMENT}
    `,
    {
      update(cache, { data }) {
        if (!data?.createShopItem?.shopItem) {
          return;
        }

        const existingShopData = cache.readQuery<ShopPageQuery>({
          query: shopPageQuery,
        });

        if (!existingShopData?.shopItems) {
          return;
        }

        cache.writeQuery<ShopPageQuery>({
          query: shopPageQuery,
          data: {
            shopItems: [
              data.createShopItem.shopItem,
              ...existingShopData.shopItems,
            ],
            shopSections: existingShopData.shopSections,
            offerings: existingShopData.offerings,
          },
        });
      },
    },
  );

  const onSubmit = handleSubmit(async ({ offering }) => {
    const [offeringId, title] = offering.split(':');

    await createShopItem({
      variables: {
        input: {
          title,
          offeringId,
        },
      },
    });

    showNotification({
      message: 'Shop item created',
      type: 'success',
    });

    props.onCloseModal();
  });

  return (
    <Modal
      show={props.showModal}
      onClose={props.onCloseModal}
      isAutoOverflow={false}
      width="max-w-screen-md"
    >
      <form className="bg-slate-200 p-8 px-6 space-y-8">
        <div className="space-y-3">
          <h3 className="text-lg font-semibold">Create new product</h3>
          <p>
            Your new product will be a ‘draft’ and will have its visibility set
            to ‘none’ by default.
          </p>
          <p>
            You can update this by toggling the product to be ‘available’ and by
            making it visible in shop and/or plugs.
          </p>
        </div>
        <Dropdown
          options={props.offeringOptions}
          control={control}
          name="offering"
          label="Offering"
          placeholder="Select an option"
          rules={requiredValidation('offering')}
          errorMessage={errors.offering?.message}
        />
        <div className="flex space-x-5">
          <Button
            variant="outline"
            color="danger"
            fullWidth
            onClick={props.onCloseModal}
            disabled={formState.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            fullWidth
            onClick={onSubmit}
            loading={formState.isSubmitting}
          >
            Create new product
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const shopItemAvailabilityToTagColor: Record<ShopItemAvailability, Colors> = {
  AVAILABLE: 'green',
  UNAVAILABLE: 'blue',
  DRAFT: 'gray',
};

type ShopItemsTableData = {
  id: string;
  dnd: {
    shopItemId: string;
  };
  title: {
    shopItemId: string;
    shopItemTitle: string;
    isDisabled: boolean;
  };
  availability: {
    shopItemId: string;
    availability: ShopItemAvailability;
    shopItemTitle: string;
    isDisabled: boolean;
  };
  visibility: {
    shopItemId: string;
    shopItemTitle: string;
    isPlugItem: boolean;
    isPafInitialItem: boolean;
    isInShop: boolean;
    shopSectionId?: string;
    isDisabled: boolean;
    shopItemIdsAvailableInShop: string[];
  };
  offering: {
    offeringName: string;
    offeringId: string;
  };
  problemTypes: ProblemType[];
};

const ShopItemAvailabilityColumn = (
  cell: CellProps<ShopItemsTableData, ShopItemsTableData['availability']>,
) => {
  const showNotification = useNotifications();
  const [showDisableModal, setShowDisableModal] = useState(false);
  const { register, handleSubmit, reset } = useForm<{
    isAvailable: Record<string, boolean>;
  }>({
    defaultValues: {
      isAvailable: {
        [cell.value.shopItemId]: cell.value.availability === 'AVAILABLE',
      },
    },
  });

  const [updateShopItem, { loading }] = useMutation<
    UpdateShopItemAvailabilityMutation,
    UpdateShopItemAvailabilityMutationVariables
  >(gql`
    mutation UpdateShopItemAvailability($input: UpdateShopItemInput!) {
      updateShopItem(input: $input) {
        shopItem {
          id
          availability
        }
      }
    }
  `);

  const handleUpdateShopItem = async (availability: ShopItemAvailability) => {
    const resp = await updateShopItem({
      variables: {
        input: {
          shopItemId: cell.value.shopItemId,
          availability,
        },
      },
    });

    if (resp.data?.updateShopItem?.shopItem) {
      showNotification({
        message: `Shop item availability updated`,
        type: 'success',
      });
    }

    if (showDisableModal) {
      setShowDisableModal(false);
    }
  };

  const onFormChange = handleSubmit(async (formData) => {
    if (formData.isAvailable[cell.value.shopItemId]) {
      handleUpdateShopItem('AVAILABLE');
    } else {
      setShowDisableModal(true);
    }
    return;
  });

  return (
    <>
      <form
        className={clsx('flex space-x-4 items-center', {
          'opacity-50': cell.value.isDisabled,
        })}
        onChange={onFormChange}
      >
        <Switch
          ref={register()}
          checked={cell.value.availability === 'AVAILABLE'}
          value={`isAvailable[${cell.value.shopItemId}]`}
          name={`isAvailable[${cell.value.shopItemId}]`}
          disabled={cell.value.isDisabled}
        />
        <Tag
          size="small"
          color={shopItemAvailabilityToTagColor[cell.value.availability]}
        >
          <span className="uppercase">{cell.value.availability}</span>
        </Tag>
      </form>
      <Modal
        show={showDisableModal}
        onClose={() => setShowDisableModal(false)}
        isAutoOverflow={false}
        width="max-w-screen-md"
      >
        <form className="bg-slate-200 p-8 px-6">
          <div className="space-y-2 mb-4">
            <h3 className="text-lg font-semibold">
              Are you sure you want to make this product unavailable?
            </h3>
            <p className="text-base">
              You are about to make the following product unavailable:
            </p>
          </div>
          <ul className="mb-4 list-disc pl-5 text-base">
            <li>
              <strong>{cell.value.shopItemTitle}</strong>
            </li>
          </ul>
          <p className="mb-8 text-base">
            If you do this it will no longer be available for health coaches to
            plug, will be removed from shop, and will be labelled unavailable to
            patients with an link to the product.
          </p>
          <div className="flex space-x-5">
            <Button
              variant="outline"
              color="danger"
              fullWidth
              onClick={() => {
                reset({
                  isAvailable: {
                    [cell.value.shopItemId]: true,
                  },
                });
                setShowDisableModal(false);
              }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              fullWidth
              onClick={() => handleUpdateShopItem('UNAVAILABLE')}
              loading={loading}
            >
              Make unavailable
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const ShopItemVisibilityColumn = (
  cell: CellProps<ShopItemsTableData, ShopItemsTableData['visibility']>,
) => {
  const showNotification = useNotifications();
  const {
    isPlugItem,
    isPafInitialItem,
    isInShop,
    shopItemId,
    shopItemTitle,
    shopSectionId,
    isDisabled,
    shopItemIdsAvailableInShop,
  } = cell.value;
  const mobileShopEnabled = !!shopSectionId;
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);

  const { register, handleSubmit, watch, formState, reset } = useForm<{
    plugsToggle: boolean;
    shopToggle: boolean;
    pafInitialToggle: boolean;
  }>({
    defaultValues: {
      plugsToggle: isPlugItem,
      shopToggle: isInShop,
      pafInitialToggle: isPafInitialItem,
    },
  });

  useEffect(() => {
    reset({
      plugsToggle: isPlugItem,
      shopToggle: isInShop,
      pafInitialToggle: isPafInitialItem,
    });
  }, [isPlugItem, isInShop, isPafInitialItem, reset]);

  const [updateShopItem] = useMutation<
    UpdateShopItemVisibilityMutation,
    UpdateShopItemVisibilityMutationVariables
  >(gql`
    mutation UpdateShopItemVisibility(
      $updateShopItem: Boolean!
      $updateShopItemInput: UpdateShopItemInput!
      $updateShopSection: Boolean!
      $updateShopSectionInput: UpdateShopSectionInput!
    ) {
      updateShopItem(input: $updateShopItemInput)
        @include(if: $updateShopItem) {
        shopItem {
          id
          plugsExposure
          pafInitialExposure
        }
      }
      updateShopSection(input: $updateShopSectionInput)
        @include(if: $updateShopSection) {
        shopSection {
          id
          ... on ItemListShopSection {
            items {
              id
            }
          }
        }
      }
    }
  `);

  const onSubmit = handleSubmit(async (formData) => {
    const updateShopSection = isInShop !== !!formData.shopToggle;

    let updatedShopSectionItems: UpdateShopSectionInput['itemListShopSection']['shopItems'] =
      [];

    if (updateShopSection) {
      if (!isInShop && !!formData.shopToggle) {
        updatedShopSectionItems = [
          ...shopItemIdsAvailableInShop.map((id, i) => ({
            shopItemId: id,
            position: i,
          })),
          {
            shopItemId: shopItemId,
            position: shopItemIdsAvailableInShop.length,
          },
        ];
      } else {
        updatedShopSectionItems = shopItemIdsAvailableInShop
          .filter((id) => id !== shopItemId)
          .map((id, i) => ({ shopItemId: id, position: i }));
      }
    }

    await updateShopItem({
      variables: {
        updateShopItem: !!(
          formState.dirtyFields.plugsToggle ||
          formState.dirtyFields.pafInitialToggle
        ),
        updateShopItemInput: {
          shopItemId,
          domainExposure: {
            plugs: !!formData.plugsToggle,
            pafInitial: !!formData.pafInitialToggle,
            pafFur: false,
          },
        },
        updateShopSection,
        updateShopSectionInput: {
          shopSectionId: shopSectionId ?? '',
          itemListShopSection: {
            shopItems: updatedShopSectionItems,
          },
        },
      },
    });

    showNotification({
      message: 'Shop item visibility updated',
      type: 'success',
    });

    setShowVisibilityModal(false);
  });

  const domainExposures: {
    label: string;
    exposed: boolean;
  }[] = [
    {
      label: 'Mobile Shop',
      exposed: isInShop,
    },
    {
      label: 'Plugs',
      exposed: isPlugItem,
    },
    {
      label: 'PAF Initial',
      exposed: isPafInitialItem,
    },
  ];

  let visibility = 'All';
  if (domainExposures.every((d) => !d.exposed)) {
    visibility = 'None';
  } else if (domainExposures.some((d) => !d.exposed)) {
    visibility = domainExposures
      .filter((d) => d.exposed)
      .map((d) => d.label)
      .join(', ');
  }

  return (
    <>
      <button
        title={
          isDisabled
            ? 'You must make an item available before you can update visibility'
            : ''
        }
        className={clsx('flex items-center space-x-3', {
          'cursor-not-allowed text-slate-400': isDisabled,
        })}
        disabled={isDisabled}
        onClick={() => setShowVisibilityModal(true)}
      >
        <GoPencil size={16} />
        <div className="flex items-center gap-1">
          {visibility === 'None' ? (
            <AiOutlineEyeInvisible size={16} />
          ) : (
            <AiOutlineEye size={16} />
          )}
          <p className="text-nowrap">{visibility}</p>
        </div>
      </button>
      {showVisibilityModal && (
        <Modal
          show={true}
          onClose={() => setShowVisibilityModal(false)}
          isAutoOverflow={false}
          width="max-w-screen-md"
        >
          <form className="bg-slate-200 p-8 px-6 space-y-6">
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Edit product visibility</h3>
              <p className="text-base mb-4">
                You are about to make changes to the following product:
              </p>
              <ul className="mb-4 list-disc pl-5 text-base">
                <li>
                  <strong>{shopItemTitle}</strong>
                </li>
              </ul>
              <p className="mb-8 text-base">
                Toggle the options below on or off to control where products are
                displayed to patients and health coaches.
              </p>
              <p className="uppercase font-semibold">Visibility</p>
              {config.plugsEnabled && (
                <Switch
                  label="Plugs"
                  ref={register()}
                  checked={!!watch().plugsToggle}
                  value="plugsToggle"
                  name="plugsToggle"
                  disabled={formState.isSubmitting}
                />
              )}
              {mobileShopEnabled && (
                <Switch
                  label="Mobile app shop"
                  ref={register()}
                  checked={!!watch().shopToggle}
                  value="shopToggle"
                  name="shopToggle"
                  disabled={formState.isSubmitting}
                />
              )}
              <Switch
                label="PAF initial flow"
                ref={register()}
                checked={!!watch().pafInitialToggle}
                value="pafInitialToggle"
                name="pafInitialToggle"
                disabled={formState.isSubmitting}
              />
            </div>
            <div className="flex space-x-5">
              <Button
                variant="outline"
                color="danger"
                fullWidth
                onClick={() => setShowVisibilityModal(false)}
                disabled={formState.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                fullWidth
                onClick={onSubmit}
                disabled={!formState.isDirty}
                loading={formState.isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

const ProblemTypesColumn = (
  cell: CellProps<ShopItemsTableData, ShopItemsTableData['problemTypes']>,
) => (
  <div className="flex flex-wrap items-center gap-1">
    {cell.value.map((type) => (
      <Tag key={type} size="small" color={getProblemTypeColor(type)}>
        {getProblemTypeFromString(type)}
      </Tag>
    ))}
  </div>
);

const ShopItemIdColumn = (
  cell: CellProps<ShopItemsTableData, ShopItemsTableData['id']>,
) => (
  <span className="inline-flex text-xs leading-5">
    <Copyable text={cell.value}>
      {(copied) => (
        <pre className="cursor-pointer">
          {copied ? 'Copied' : cell.value.slice(-6)}
        </pre>
      )}
    </Copyable>
  </span>
);

const OfferingCell = (
  cell: CellProps<ShopItemsTableData, ShopItemsTableData['offering']>,
) => (
  <Link to={`${routes.offerings}/${cell.value.offeringId}`}>
    <div className="flex items-center space-x-2">
      <div>
        <GoLinkExternal size={20} />
      </div>
      <p>{cell.value.offeringName}</p>
    </div>
  </Link>
);

const ShopItemTitleColumn = (
  cell: CellProps<ShopItemsTableData, ShopItemsTableData['title']>,
) => {
  const showNotification = useNotifications();
  const { register, handleSubmit, watch, reset } = useForm<{ title: string }>({
    defaultValues: {
      title: cell.value.shopItemTitle,
    },
  });
  const [showUpdateTitleModal, setShowUpdateTitleModal] = useState(false);
  const [updateShopItem, { loading }] = useMutation<
    UpdateShopItemTitleMutation,
    UpdateShopItemTitleMutationVariables
  >(gql`
    mutation UpdateShopItemTitle($input: UpdateShopItemInput!) {
      updateShopItem(input: $input) {
        shopItem {
          id
          title
        }
      }
    }
  `);

  const handleUpdateShopItem = handleSubmit(async (formData) => {
    const resp = await updateShopItem({
      variables: {
        input: {
          shopItemId: cell.value.shopItemId,
          title: formData.title,
        },
      },
    });

    if (resp.data?.updateShopItem?.shopItem) {
      showNotification({
        message: `Shop item title updated`,
        type: 'success',
      });
    }

    if (showUpdateTitleModal) {
      setShowUpdateTitleModal(false);
    }
  });

  const onClose = () => {
    setShowUpdateTitleModal(false);
    reset({ title: cell.value.shopItemTitle });
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <button
          className={clsx('flex items-center space-x-3', {
            'cursor-not-allowed text-slate-400': cell.value.isDisabled,
          })}
          disabled={cell.value.isDisabled}
          onClick={() => setShowUpdateTitleModal(true)}
        >
          <GoPencil size={16} />
          <p className="text-start">{cell.value.shopItemTitle}</p>
        </button>
        <Modal
          show={showUpdateTitleModal}
          onClose={onClose}
          isAutoOverflow={false}
          width="max-w-screen-md"
        >
          <form className="bg-slate-200 p-8 px-6 space-y-8">
            <div className="flex flex-col space-y-4">
              <div className="space-y-2">
                <h3 className="text-lg font-semibold">
                  Update name (patient facing)
                </h3>
                <p className="text-base">
                  You are about to set a new name for:{' '}
                  <strong> {cell.value.shopItemTitle}</strong>.
                </p>
                <p className="text-base">
                  Please note this name will be visible to patients and health
                  coaches.
                </p>
              </div>
              <Input ref={register} name="title" label="New name" />
            </div>
            <div className="flex space-x-5">
              <Button
                variant="outline"
                color="danger"
                fullWidth
                onClick={onClose}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                fullWidth
                onClick={handleUpdateShopItem}
                loading={loading}
                disabled={watch().title.trim().length === 0}
              >
                Save
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

const baseColumns: Column<ShopItemsTableData>[] = [
  {
    Header: (
      <div className="flex items-center">
        Availability
        <Tooltip hoverText="Available products can be displayed in shop or plugged by a health coach (depending on where they are made visible)." />
      </div>
    ),
    accessor: 'availability',
    Cell: ShopItemAvailabilityColumn,
  },
  {
    Header: 'Name (patient facing)',
    accessor: 'title',
    Cell: ShopItemTitleColumn,
  },
  {
    Header: 'Offering',
    accessor: 'offering',
    Cell: OfferingCell,
  },
  {
    Header: 'Problem type',
    accessor: 'problemTypes',
    Cell: ProblemTypesColumn,
  },
  {
    Header: (
      <div className="flex items-center">
        Visibility
        <Tooltip hoverText="Choose whether products can be displayed in shop and/or plugged by a health coach." />
      </div>
    ),
    accessor: 'visibility',
    Cell: ShopItemVisibilityColumn,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ShopItemIdColumn,
  },
];

const Shop = (): ReactElement => {
  const showNotification = useNotifications();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [shopViewEditingEnabled, setShopViewEditingEnabled] = useState(false);
  const [orderedShopItems, setOrderedShopItems] =
    useState<NonNullable<ShopPageQuery['shopSections']>[0]['items']>();
  const featureFlagClient = useFeatureFlagClient();
  const { data, loading, error } = useQuery<
    ShopPageQuery,
    ShopPageQueryVariables
  >(shopPageQuery);

  const shopConfig = shopConfigSchema.parse(
    featureFlagClient.getJson('mobile_shop_default_items_section'),
  );
  const mobileShopEnabled = !!shopConfig.defaultSectionId;

  const [updateShopSection, { loading: updateOrderLoading }] = useMutation<
    UpdateShopSectionMutation,
    UpdateShopSectionMutationVariables
  >(
    gql`
      mutation UpdateShopSection(
        $updateShopSectionInput: UpdateShopSectionInput!
      ) {
        updateShopSection(input: $updateShopSectionInput) {
          shopSection {
            id
            ... on ItemListShopSection {
              items {
                id
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          message: 'Shop items reordered',
          type: 'success',
        });
        setShopViewEditingEnabled(false);
      },
    },
  );

  // api returns shopItems within a section already ordered by position
  const defaultShopItems = data?.shopSections?.find(
    (s) => s.id === shopConfig.defaultSectionId,
  )?.items;
  const shopItemIdsAvailableInShop = defaultShopItems?.map((i) => i.id) ?? [];

  useEffect(() => {
    setOrderedShopItems(defaultShopItems);
  }, [defaultShopItems]);

  const columns: Column<ShopItemsTableData>[] = useMemo(() => {
    if (shopViewEditingEnabled) {
      return [
        {
          accessor: 'dnd',
          Cell: (cell) => (
            <td
              className="px-4 h-16"
              key={cell.value.shopItemId}
              {...cell.dragProps}
            >
              <FaBars className="text-md" />
            </td>
          ),
        },
        ...baseColumns,
      ];
    }
    return baseColumns;
  }, [shopViewEditingEnabled]);

  const itemsToDisplay = shopViewEditingEnabled
    ? orderedShopItems
    : data?.shopItems;

  const table = useTable({
    columns,
    data:
      itemsToDisplay?.reduce<ShopItemsTableData[]>((acc, item) => {
        const isInShop = shopItemIdsAvailableInShop.includes(item.id);
        const isPlugItem = !!item.plugsExposure;
        const isPafInitialItem = !!item.pafInitialExposure;
        if (item.id && item.otcOffering?.friendlyName) {
          acc.push({
            id: item.id,
            dnd: {
              shopItemId: item.id,
            },
            title: {
              shopItemId: item.id,
              shopItemTitle: item.title,
              isDisabled: shopViewEditingEnabled,
            },
            offering: {
              offeringName: item.otcOffering.friendlyName,
              offeringId: item.otcOffering.id,
            },
            availability: {
              shopItemId: item.id,
              shopItemTitle: item.title,
              availability: item.availability,
              isDisabled:
                (item.otcOffering.status !== 'AVAILABLE' &&
                  item.availability === 'UNAVAILABLE') ||
                (item.availability === 'AVAILABLE' &&
                  (isInShop || isPlugItem)) ||
                shopViewEditingEnabled,
            },
            visibility: {
              shopItemId: item.id,
              shopItemTitle: item.title,
              isPlugItem,
              isPafInitialItem,
              isInShop,
              shopSectionId: shopConfig.defaultSectionId,
              isDisabled:
                item.availability !== 'AVAILABLE' || shopViewEditingEnabled,
              shopItemIdsAvailableInShop,
            },
            problemTypes: item.otcOffering.problemTypes ?? [],
          });
        }

        return acc;
      }, []) ?? [],
  });

  const handleOnDragEnd = useCallback(
    async ({ source, destination }: DropResult): Promise<void> => {
      if (
        !orderedShopItems ||
        !destination ||
        destination.index === source.index
      ) {
        return;
      }

      const reorderedItems = orderedShopItems.slice();
      const [movedItem] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, movedItem);

      setOrderedShopItems(reorderedItems);
    },
    [orderedShopItems],
  );

  if (loading) {
    return <Loading />;
  }

  if (!data || error) {
    return (
      <div>Unable to load shop data, please contact #help-technology.</div>
    );
  }

  const offeringsOptions =
    data.offerings?.reduce<Option[]>((acc, o) => {
      if (
        o.__typename === 'OtcOffering' &&
        o.status === 'AVAILABLE' &&
        o.sequenceSets.length === 1 &&
        o.sequenceSets[0].sequences?.[0]
      ) {
        acc.push({
          label: o.friendlyName,
          value: `${o.id}:${o.friendlyName}`,
        });
      }

      return acc;
    }, []) ?? [];

  const handleShopViewSave = async () => {
    if (!shopConfig.defaultSectionId) {
      throw new Error('expected default shop section to be in config');
    }
    if (!orderedShopItems) {
      return;
    }
    await updateShopSection({
      variables: {
        updateShopSectionInput: {
          shopSectionId: shopConfig.defaultSectionId,
          itemListShopSection: {
            shopItems: orderedShopItems.map((item, i) => ({
              shopItemId: item.id,
              position: i,
            })),
          },
        },
      },
    });
  };

  return (
    <>
      <section className="space-y-10">
        <div className="flex justify-between items-end space-x-5">
          {shopViewEditingEnabled && (
            <>
              <div className="flex flex-col text-slate-700">
                <p>
                  Drag and drop products to change the order they appear in
                  shop.
                </p>
                <br />
                <p>
                  All <strong>available</strong> products that have{' '}
                  <strong>shop visibility enabled</strong> will appear in the
                  order they are arranged in on this page.
                </p>
              </div>
              <div className="flex space-x-3">
                <Button
                  onClick={() => setShopViewEditingEnabled(false)}
                  variant="outline"
                  disabled={updateOrderLoading}
                >
                  Cancel
                </Button>
                <Button
                  loading={updateOrderLoading}
                  onClick={handleShopViewSave}
                >
                  Save
                </Button>
              </div>
            </>
          )}
          {!shopViewEditingEnabled && (
            <>
              <div className="flex flex-col text-slate-700">
                <p>
                  Manage where non-Rx products and services can be purchased by
                  patients.
                </p>
                <br />
                <p>
                  Go to <strong>offering</strong> to edit patient-facing product
                  details like description or image.
                </p>
              </div>
              <div className="flex space-x-3">
                {mobileShopEnabled && (
                  <Button
                    onClick={() => setShopViewEditingEnabled(true)}
                    variant="outline"
                  >
                    Edit shop display
                  </Button>
                )}
                <Button onClick={() => setShowCreateModal(true)}>
                  Create new
                </Button>
              </div>
            </>
          )}
        </div>
        {/* TODO: Add filters -  https://linear.app/eucalyptus/issue/ENG-1518/[admins-ui]-impl-shop-filters */}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="shopItems">
            {(provided): JSX.Element => (
              <div
                className="flex flex-col"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Table tableInstance={table}>
                  <TableHead />
                  <TableBody>
                    {table.rows.map((row, i) => {
                      table.prepareRow(row);
                      return (
                        <Draggable key={row.id} draggableId={row.id} index={i}>
                          {(provided, { isDragging }): JSX.Element => (
                            // TODO: fix dragging style issue https://linear.app/eucalyptus/issue/ENG-1608/[admins-ui]-fix-dragging-row-styling-issue
                            <TableRow
                              row={row}
                              key={row.id}
                              isClickable={false}
                              isDragging={isDragging}
                              draggableProvided={provided}
                            >
                              {row.cells.map((cell) => (
                                <TableCell
                                  cell={cell}
                                  key={`${cell.column.id}-${cell.row.original.id}`}
                                  userProps={{
                                    dragProps: provided.dragHandleProps,
                                  }}
                                />
                              ))}
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                  </TableBody>
                </Table>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </section>
      <CreateShopItemModal
        showModal={showCreateModal}
        onCloseModal={() => setShowCreateModal(false)}
        offeringOptions={offeringsOptions}
      />
    </>
  );
};

export default Shop;
