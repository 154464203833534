import clsx from 'clsx';
import React from 'react';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  description?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export const Checkbox = React.forwardRef(function BaseCheckbox(
  props: Props,
  ref: React.Ref<HTMLInputElement>,
) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id={props.name}
          ref={ref}
          type="checkbox"
          name={props.name}
          disabled={props.disabled}
          onChange={props.onChange}
          className="h-5 w-5 text-slate-900 accent-primary"
        />
      </div>
      <div
        className={clsx(
          'text-sm leading-6 select-none',
          (props.label || props.description) && 'ml-3',
        )}
      >
        <label htmlFor={props.name} className="font-medium text-slate-900">
          {props.label}
        </label>
        {props.description && (
          <p id={`${props.name}-description`} className="text-slate-500">
            {props.description}
          </p>
        )}
      </div>
    </div>
  );
});
