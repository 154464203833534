import { ReactElement } from 'react';

export const SmallTableHeadings = ({
  headings,
}: {
  headings: string[];
}): ReactElement => {
  return (
    <tr>
      {headings.map((h) => (
        <th
          key={h}
          className="px-6 py-2 whitespace-nowrap bg-slate-50 text-left text-xs leading-4 font-medium text-slate-500 uppercase"
        >
          {h}
        </th>
      ))}
    </tr>
  );
};
