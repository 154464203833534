import React from 'react';
import { statusColorBackground, statusColorText } from '../../utils/misc';
import { useMutation, gql } from '@apollo/client';
import { useNotifications } from '../../notifications';
import { useHasPermissions } from '../../components/permissions';
import { useParams } from 'react-router-dom';
import { Customer } from './types';
import {
  UnverifyUserMutation,
  UnverifyUserMutationVariables,
} from 'graphql/types';

const verifyQuery = gql`
  mutation verifyUser($userId: String!) {
    verifyUser(userId: $userId) {
      id
      verification {
        id
        name
        data
        sub
      }
    }
  }
`;

const unverifyQuery = gql`
  mutation unverifyUser($userId: ID!) {
    unverifyUser(userId: $userId) {
      id
      verification {
        id
        name
        data
        sub
      }
    }
  }
`;

export const VerificationPanel = ({
  customer,
}: {
  customer: Customer;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const { customerId } = useParams<{ customerId: string }>();

  const canEditPatients = useHasPermissions(['EDIT_PATIENTS']);

  const [verifyMutation, { loading: verifyInProgress }] =
    useMutation(verifyQuery);
  const handleVerifyCustomer = async (): Promise<void> => {
    await verifyMutation({
      variables: {
        userId: customerId,
      },
    });
    showNotification({
      type: 'success',
      message: 'User verified',
    });
  };

  const [unverifyMutation, { loading: unverifyInProgress }] = useMutation<
    UnverifyUserMutation,
    UnverifyUserMutationVariables
  >(unverifyQuery);
  const handleUnverifyCustomer = async (): Promise<void> => {
    await unverifyMutation({
      variables: {
        userId: customerId,
      },
    });
    showNotification({
      type: 'success',
      message: 'User unverified',
    });
  };
  return (
    <div className="overflow-hidden rounded shadow bg-white">
      <div className="px-4 py-5 border-b border-slate-200">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Verification
          <span
            className={`ml-4 px-2 text-sm leading-5 rounded-full ${statusColorBackground(
              customer.verification?.id ? 'ACTIVE' : 'CANCELED',
            )} ${statusColorText(
              customer.verification?.id ? 'ACTIVE' : 'CANCELED',
            )}`}
          >
            {customer.verification?.id ? `Verified` : `Not Verified`}
          </span>
        </h3>
      </div>
      <div>
        <dl>
          <div className="bg-slate-100 px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm leading-5 text-slate-500">ID</dt>
            <dd className="text-sm leading-5 text-slate-500 sm:mt-0 sm:col-span-2">
              {customer.verification?.id}
            </dd>
          </div>
          <div className="bg-white px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm leading-5 text-slate-500">Name</dt>
            <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
              {customer.verification?.name}
            </dd>
          </div>
          <div className="bg-slate-100 px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm leading-5 text-slate-500">DOB</dt>
            <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
              {customer.verification?.data?.birthdate}
            </dd>
          </div>
          <div className="flex justify-end p-4">
            <span className="hidden sm:block rounded">
              <button
                type="button"
                className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white transition duration-150 ease-in-out"
                disabled={
                  verifyInProgress || unverifyInProgress || !canEditPatients
                }
                onClick={
                  customer.verification?.id
                    ? handleUnverifyCustomer
                    : handleVerifyCustomer
                }
              >
                ⚡️ {customer.verification?.id ? 'Unverify' : 'Verify'}
              </button>
            </span>
          </div>
        </dl>
      </div>
    </div>
  );
};
