import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { ReactElement, useState } from 'react';
import { formatDateAndTime, upperSnakeCaseToCapitalCase } from 'utils/misc';
import { QuizApplications } from '../types';
import { QuizModal } from './quiz-modal';
import { Copyable } from 'components/copyable';

export const QuizTableBody = ({
  quiz: quizzes,
}: {
  quiz: NonNullable<QuizApplications>;
}): ReactElement => {
  const [showQuizModal, setShowQuizModal] = useState<
    NonNullable<QuizApplications>[number] | null
  >(null);

  const onClickHandle = (quiz: NonNullable<QuizApplications>[number]): void => {
    setShowQuizModal(quiz);
  };

  return (
    <>
      {quizzes.map((c) => (
        <tr key={c.id} className="text-xs">
          <td className="px-6 py-2 whitespace-nowrap leading-5 text-slate-500">
            <button onClick={(): void => onClickHandle(c)}>{c.id}</button>
            <CopyToClipboardButton value={c.id} />
          </td>
          <td className="px-6 py-2 whitespace-nowrap leading-5 text-slate-500">
            <Copyable text={c.quizId}>
              {(copied) => (
                <pre className="cursor-pointer leading-5 text-slate-500">
                  {copied ? 'Copied' : c.quizId.slice(-6)}
                </pre>
              )}
            </Copyable>
          </td>
          <td className="px-6 py-2 whitespace-nowrap">
            <span
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-slate-100`}
            >
              {upperSnakeCaseToCapitalCase(c.purpose)}
            </span>
          </td>
          {[c.createdAt, c.submittedAt, c.archivedAt].map((value) => (
            <td className="px-6 py-2 whitespace-nowrap" key={value}>
              <div className="flex items-center">
                <div className="leading-5 text-slate-900">
                  {value ? formatDateAndTime(value) : `-`}
                </div>
              </div>
            </td>
          ))}
        </tr>
      ))}
      {showQuizModal && (
        <tr>
          <td>
            <QuizModal
              closeHandler={(): void => setShowQuizModal(null)}
              quiz={showQuizModal}
            />
          </td>
        </tr>
      )}
    </>
  );
};
