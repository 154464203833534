import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown-v7';
import { Modal } from 'components/modal';
import { Input } from 'components/input';
import { useForm } from 'react-hook-form';
import { trackedMetricDropdownOptions } from './dropdown-options';
import { TextArea } from 'components/text-area';
import { v4 } from 'uuid';
import { useNotifications } from 'notifications';
import { ObjectiveTrackedMetric } from 'graphql/types';

type CreateObjectiveFormData = {
  id: string;
  name: string;
  description: string;
  trackedMetrics: ObjectiveTrackedMetric[];
};

export const CreateObjectiveModal = (props: {
  onClose: () => void;
  onCreateObjective: (formData: CreateObjectiveFormData) => Promise<void>;
}): React.ReactElement => {
  const id = v4();
  const showNotification = useNotifications();

  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CreateObjectiveFormData>({
    mode: 'onChange',
    defaultValues: {
      id: id,
      name: '',
      description: '',
      trackedMetrics: [],
    },
  });

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await props.onCreateObjective(formData);
      props.onClose();
    } catch (error) {
      showNotification({
        type: 'error',
        message: 'Could not create new objective.',
      });
      throw error;
    }
  });

  return (
    <Modal isAutoOverflow={false} show={true} onClose={props.onClose}>
      <div className="bg-white rounded p-4">
        <form onSubmit={onSubmit}>
          <div className="flex-col space-y-6">
            <div className="flex flex-col space-y-2">
              <p className="text-lg text-slate-900 font-semibold">
                Create an Objective
              </p>
              <p className="text-slate-800">
                Objective will be created with the{' '}
                <span className="font-semibold">draft</span> status. Once it is
                created, you can change the status to{' '}
                <span className="font-semibold">active</span>.
              </p>
            </div>
            <div className="w-full space-x-4">
              <Input label="Objective ID" disabled={true} {...register('id')} />
            </div>
            <div className="w-full space-x-4">
              <Input
                placeholder="e.g. Weight Loss"
                label="Objective Name"
                {...register('name', { required: true })}
              />
            </div>
            <div className="w-full space-x-4">
              <TextArea
                placeholder="Succinct description of the objective."
                rows={5}
                label="Description"
                {...register('description', { required: true })}
              />
            </div>
            <div className="w-full space-x-4">
              <Dropdown
                name="trackedMetrics"
                label="Tracked Metrics"
                isMulti
                options={trackedMetricDropdownOptions}
                control={control}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="pt-4">
            <Button fullWidth type="submit" loading={isSubmitting}>
              Create
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
