import { gql } from '@apollo/client';
import { NoteRowFragment } from 'graphql/types';
import { useCallback, useState } from 'react';
import { NoteRow } from './note-row';
import clsx from 'clsx';
import { noteHistoryDocument } from './history/note-history-modal';

export const noteRowFragment = gql`
  fragment noteRow on Note {
    id
    body
    domain
    author {
      id
      role
      fullName
      clinicianName
      roles {
        id
        value
      }
    }
    createdAt
    updatedAt
    canArchive
    canEdit
    legacy
    archivalNote {
      id
      body
      author {
        id
        role
        fullName
        clinicianName
      }
      updatedAt
    }
    ... on ConsultationNote {
      consultation {
        id
        type
        stage
      }
    }
    ... on CustomerNote {
      customer {
        id
      }
    }
    ...addendums
    ...NoteHistory
  }

  fragment addendums on Note {
    ... on ConsultationNote {
      canAddAddendum
      addendums {
        id
        body
        domain
        updatedAt
        author {
          id
          role
          fullName
          clinicianName
        }
      }
    }

    ... on CustomerNote {
      canAddAddendum
      addendums {
        id
        body
        domain
        updatedAt
        author {
          id
          role
          fullName
          clinicianName
        }
      }
    }
  }

  ${noteHistoryDocument}
`;

const classNames = {
  table: {
    header:
      'px-6 py-2 bg-slate-50 text-left text-xs leading-4 font-medium text-slate-500 uppercase tracking-wider',
  },
};

export const NotesTable: React.FC<{
  notes: NoteRowFragment[];
}> = ({ notes }) => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const toggleRowExpansion = useCallback(
    (rowId: string) =>
      setExpandedRows((current) => ({ ...current, [rowId]: !current[rowId] })),
    [setExpandedRows],
  );

  const toggleExpandAll = useCallback(() => {
    const isExpanded = expandedRows[notes[0].id];
    setExpandedRows(
      notes.reduce(
        (acc, current) => ({ ...acc, [current.id]: !isExpanded }),
        {},
      ),
    );
  }, [setExpandedRows, notes, expandedRows]);

  return (
    <table className="w-full divide-y divide-slate-200 text-xs">
      <thead>
        <tr>
          <th className={classNames.table.header}>Created</th>
          <th className={classNames.table.header}>Author</th>
          <th className={classNames.table.header}>Note Preview</th>
          <th className={clsx(classNames.table.header, 'text-center')}>
            <button onClick={toggleExpandAll} className="underline uppercase">
              Expand all
            </button>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-slate-200">
        {notes.length === 0 && (
          <tr>
            <td colSpan={6} className="px-4 py-2 text-slate-500 text-sm">
              No notes recorded
            </td>
          </tr>
        )}
        {notes.map((note) => (
          <NoteRow
            key={note.id}
            isExpanded={expandedRows[note.id]}
            onToggleExpand={() => toggleRowExpansion(note.id)}
            note={note}
          />
        ))}
      </tbody>
    </table>
  );
};
