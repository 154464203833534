import React, { useEffect } from 'react';
import clsx from 'clsx';
import { tinykeys } from 'tinykeys';

interface Props {
  children: React.ReactElement;
  show: boolean;
  width?: string;
  height?: string;
  isAutoOverflow?: boolean;
  onClose: () => void;
}

export const Modal = ({
  children,
  show,
  width = 'w-1/2',
  height = undefined,
  isAutoOverflow = true,
  onClose,
}: Props): JSX.Element | null => {
  useEffect(() => {
    return tinykeys(window, {
      Escape: () => onClose(),
    });
  }, [onClose]);

  return (
    <div
      className={clsx(
        'fixed py-8 inset-0 z-50 bg-black bg-opacity-50 overflow-hidden !m-0 transition-opacity',
        show ? 'opacity-100' : 'opacity-0 pointer-events-none',
      )}
      onClick={(e: React.MouseEvent): void => {
        if (e.currentTarget === e.target) {
          onClose();
        }
      }}
    >
      <div
        className={clsx(
          { 'overflow-auto': isAutoOverflow },
          'relative mx-auto max-h-full',
          width,
          height,
        )}
      >
        {children}
      </div>
    </div>
  );
};
