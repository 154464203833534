import { gql, useMutation } from '@apollo/client';
import {
  DoctorQuery,
  RevalidateHpiiMutation,
  RevalidateHpiiMutationVariables,
} from 'graphql/types';
import React from 'react';
import { FaRedo } from 'react-icons/fa';
import { Loading } from '../../components/loading';
import { useNotifications } from '../../notifications';
import { formatDateAndTimeSeconds } from '../../utils/misc';

export const HealthcareProviderIdentifierSection = ({
  clinician,
  refetch,
}: {
  clinician: DoctorQuery['user'];
  refetch: () => void;
}): React.ReactElement => {
  const showNotification = useNotifications();

  const [revalidateHpiiMutation, { loading }] = useMutation<
    RevalidateHpiiMutation,
    RevalidateHpiiMutationVariables
  >(
    gql`
      mutation RevalidateHpii($doctorId: String!) {
        revalidateHpii(doctorId: $doctorId) {
          id
          healthcareProviderIdentifer {
            id
            status
            hpii
            createdAt
          }
        }
      }
    `,
    {
      onCompleted: () =>
        showNotification({ type: 'success', message: 'Hpi Data Refreshed' }),
      onError: (errors) => {
        showNotification({
          type: 'error',
          message: errors.graphQLErrors[0].message,
        });
        refetch();
      },
    },
  );

  const handleRefresh = (): void => {
    if (clinician) {
      revalidateHpiiMutation({
        variables: {
          doctorId: clinician?.id,
        },
      });
    }
  };

  return (
    <div className="overflow-hidden my-4 rounded shadow bg-white">
      <div className="px-4 py-5 border-b border-slate-200 flex items-center justify-between ">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Healthcare Provider Identifier{' '}
        </h3>
        <div onClick={handleRefresh}>
          <FaRedo className="cursor-pointer" />
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : clinician?.healthcareProviderIdentifer ? (
        <div>
          <dl>
            <div className="bg-slate-100 px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm leading-5 text-slate-500">HPII</dt>
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
                {clinician?.healthcareProviderIdentifer?.hpii}
              </dd>
            </div>
            <div className="bg-white px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm leading-5 text-slate-500">Status</dt>
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
                {clinician?.healthcareProviderIdentifer?.status}
              </dd>
            </div>
            <div className="bg-white px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm leading-5 text-slate-500">
                Last updated at
              </dt>
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
                {formatDateAndTimeSeconds(
                  new Date(clinician?.healthcareProviderIdentifer?.createdAt),
                )}
              </dd>
            </div>
          </dl>
        </div>
      ) : (
        <span className="px-4 py-3">No Hpi Data</span>
      )}
    </div>
  );
};
