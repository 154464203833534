import { gql, useQuery } from '@apollo/client';
import {
  AuditLogRowFragment,
  AuditLogsQuery,
  AuditLogsQueryVariables,
  AuditLogTargetType,
} from 'graphql/types';
import { ReactElement, useState } from 'react';
import { formatDateAndTime, upperSnakeCaseToCapitalCase } from 'utils/misc';
import { Loading } from '../loading';
import { AuditDetails } from './audit-details';
import { AuditLogsModal } from './audit-logs-modal';
import { Customer } from 'pages/customer/types';
import { SmallTableHeadings } from 'components/small-table/small-table-headings';
import { Copyable } from 'components/copyable';

export const AuditLogTable = ({
  targetId,
  targetType,
  customer,
}: {
  targetId: string;
  targetType: AuditLogTargetType;
  customer?: Customer;
}): ReactElement => {
  const [showAuditLogsModal, setShowAuditLogsModal] = useState(false);
  const { data, loading } = useQuery<AuditLogsQuery, AuditLogsQueryVariables>(
    gql`
      query AuditLogsTable(
        $targetId: String!
        $targetType: AuditLogTargetType!
      ) {
        auditLogs(
          orderBy: { createdAt: asc }
          where: {
            targetId: { equals: $targetId }
            targetType: { equals: $targetType }
          }
        ) {
          ...AuditLogRow
        }
      }
      ${AuditLogRows.fragment}
    `,
    { variables: { targetId, targetType } },
  );

  if (loading) {
    return <Loading />;
  }

  const entries = data?.auditLogs || [];

  return (
    <>
      <div className="flow-root px-4 py-5 border-b border-slate-200">
        <h3 className="float-left text-lg leading-6 font-medium text-slate-900">
          Audit Logs
          <span className="ml-2 text-slate-500">{entries.length}</span>
        </h3>
        {customer && (
          <span className="float-right">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white hover:text-slate-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white"
              onClick={(): void => setShowAuditLogsModal(true)}
            >
              Show all
            </button>
          </span>
        )}
      </div>
      <div>
        <AuditLogRows data={data?.auditLogs || []} />
      </div>
      {customer && (
        <div>
          <AuditLogsModal
            customer={customer}
            onClose={(): void => setShowAuditLogsModal(false)}
            show={showAuditLogsModal}
          />
        </div>
      )}
    </>
  );
};

export const AuditLogRows = ({
  data,
}: {
  data: AuditLogRowFragment[];
}): ReactElement => {
  return (
    <div className="-mt-2 overflow-x-auto">
      <div className="pt-2 min-w-full">
        <table className="min-w-full divide-y divide-slate-200 text-xs">
          <thead>
            <SmallTableHeadings
              headings={[
                'Created',
                'Type',
                'Target ID',
                'Action',
                'User',
                'Details',
              ]}
            />
          </thead>
          <tbody className="bg-white divide-y divide-slate-200">
            {data.map((auditLog) => (
              <tr key={auditLog.id}>
                <td className="px-6 py-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="leading-5 text-slate-900">
                      {formatDateAndTime(auditLog.createdAt)}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  <div className="leading-5 text-slate-900">
                    {upperSnakeCaseToCapitalCase(auditLog.targetType)}
                  </div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  <Copyable text={auditLog.targetId}>
                    {(copied) => (
                      <pre className="cursor-pointer text-slate-500">
                        {copied ? 'Copied' : auditLog.targetId.slice(-6)}
                      </pre>
                    )}
                  </Copyable>
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="leading-5 text-slate-900">
                      {auditLog.action}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {auditLog.user
                    ? `${auditLog.user.fullName} (${auditLog.user.role})`
                    : ''}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  <AuditDetails details={auditLog.details} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

AuditLogRows.fragment = gql`
  fragment AuditLogRow on AuditLog {
    id
    createdAt
    targetType
    targetId
    action
    details
    user {
      id
      fullName
      role
    }
  }
`;
