import {
  useState,
  type FunctionComponent,
  type ReactNode,
  useEffect,
} from 'react';
import clsx from 'clsx';
import { isWithinInterval } from 'date-fns';
import { QueueSummaryWindowFragment } from 'graphql/types';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';

type QueueTableProps = {
  columnHeadings: string[];
  rowHeadings: string[];
  cells: Map<string, QueueSummaryWindowFragment>;
  currentRow?: string;
  changedCapacities?: Map<string, boolean>;
  children?: ReactNode;
};

const QueueTable: FunctionComponent<QueueTableProps> = ({
  columnHeadings,
  rowHeadings,
  cells,
  currentRow,
  changedCapacities,
  children,
}) => {
  const featureFlagClient = useFeatureFlagClient();

  // Remove animation class from changed capacity cells once completed
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  useEffect(() => {
    if (changedCapacities) {
      setShowAnimation(true);

      setTimeout(() => {
        setShowAnimation(false);
      }, 3000);
    }
  }, [changedCapacities, setShowAnimation]);

  const ffEnableWindowTotalBookingsCount = featureFlagClient.getBoolean(
    'ff-enable-window-total-bookings-count',
  );

  return (
    <div className="bg-white shadow">
      <table className="w-full table-fixed text-center text-sm border-b border-slate-300">
        <thead className="h-9">
          <tr>
            <th></th>
            {columnHeadings.map((heading, columnIndex) => (
              <th
                key={heading}
                className={clsx('font-normal', {
                  // first day is always the current day
                  'font-semibold': columnIndex === 0,
                })}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowHeadings.map((row) => {
            return (
              <tr key={row} className="h-9 border-t border-slate-300">
                <td
                  className={clsx('text-right p-2', {
                    'font-semibold': row === currentRow,
                  })}
                >
                  {row}
                </td>
                {columnHeadings.map((column) => {
                  const cell = cells.get(`${column}${row}`);

                  if (!cell) {
                    return (
                      <td
                        key={`${column}${row}`}
                        className="p-2 font-semibold text-slate-600"
                      >
                        N/A
                      </td>
                    );
                  }

                  const isCurrentWindow = isWithinInterval(new Date(), {
                    start: new Date(cell.startAt),
                    end: new Date(cell.endAt),
                  });
                  const isReachingCapacity =
                    cell.unassignedBookingsCount / cell.capacity >= 0.8;
                  const hasCapacityChanges = !!changedCapacities?.get(cell.id);

                  const classNames = {
                    cell: () => {
                      let className = '';

                      switch (true) {
                        case hasCapacityChanges && showAnimation:
                          className = 'animate-pulse-bg-purple';
                          break;

                        case isCurrentWindow && !cell.available:
                          className =
                            'bg-red-500 text-white border border-red-500';
                          break;

                        case isCurrentWindow && isReachingCapacity:
                          className = 'bg-yellow-500 border border-yellow-500';
                          break;

                        case isCurrentWindow && cell.available:
                          className =
                            'bg-green-500 text-white border border-green-500';
                          break;

                        case !isCurrentWindow && !cell.available:
                          className =
                            'bg-red-100 text-red-700 border border-red-700';
                          break;

                        case !isCurrentWindow && isReachingCapacity:
                          className = 'bg-yellow-200 border border-yellow-500';
                          break;
                      }

                      return className;
                    },

                    capacity: () => {
                      let className = '';

                      switch (true) {
                        case hasCapacityChanges && showAnimation:
                          className = 'text-neutral-500';
                          break;

                        case isCurrentWindow && !isReachingCapacity:
                          className = 'text-white';
                          break;

                        case !isCurrentWindow:
                          className = 'text-neutral-500';
                          break;
                      }

                      return className;
                    },
                  };

                  return (
                    <td
                      key={cell.id}
                      className={clsx('p-2 align-middle', classNames.cell())}
                    >
                      <span className="inline-block text-base font-semibold">
                        {cell.unassignedBookingsCount}
                      </span>

                      {!ffEnableWindowTotalBookingsCount && (
                        <span className={classNames.capacity()}>
                          {' '}
                          / {cell.capacity}
                        </span>
                      )}
                      {ffEnableWindowTotalBookingsCount && (
                        <span className={classNames.capacity()}>
                          {' '}
                          ({cell.totalBookingsCount}/{cell.capacity})
                        </span>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {children}
    </div>
  );
};

export default QueueTable;
