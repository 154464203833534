import Select from 'react-select';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';
import { Label } from 'components/label';
import { InputError } from 'components/input-error';
import { TimeInterval } from 'graphql/types';
import { Input } from './input';
import { FaMinusCircle } from 'react-icons/fa';
import clsx from 'clsx';

export type Value = {
  type: TimeInterval;
  count: number;
};

interface Props<P extends FieldValues> {
  name: FieldPath<P>;
  control: Control<P>;
  label: string;
  disabled?: boolean;
  errorMessage?: string;
  description?: string;
}

const intervals = [
  { value: 'DAYS', label: 'Days' },
  { value: 'HOURS', label: 'Hours' },
  { value: 'MONTHS', label: 'Months' },
] as const satisfies Array<{ value: TimeInterval; label: string }>;

function parse(input: unknown) {
  let count: number | undefined;
  let type: TimeInterval | undefined;
  if (typeof input === 'object' && input != null) {
    if ('count' in input) {
      if (typeof input.count === 'number') {
        count = input.count;
      }
      if (typeof input.count === 'string') {
        count = Number(input.count);
      }
    }

    if (
      'type' in input &&
      typeof input.type === 'string' &&
      (input.type === 'DAYS' ||
        input.type === 'MONTHS' ||
        input.type === 'HOURS')
    ) {
      type = input.type;
    }
  }

  return { type, count };
}

export const IntervalInput = <P extends FieldValues>({
  name,
  control,
  label,
  disabled = false,
  errorMessage,
  description,
}: Props<P>): JSX.Element => (
  <div>
    {(label || description) && (
      <div className="mb-3 flex flex-col gap-1">
        {label && <Label htmlFor={name}>{label}</Label>}
        {description && <p className="text-sm text-slate-700">{description}</p>}
      </div>
    )}
    <Controller
      render={({ field }) => {
        const parsed = parse(field.value);

        return (
          <div className="flex gap-2 items-center">
            <div className="flex-1">
              <Input
                type="number"
                placeholder="Quantity"
                value={parsed?.count?.toString() ?? ''}
                disabled={disabled}
                onChange={(evt) => {
                  field.onChange(
                    parse({
                      count: evt.currentTarget.value,
                      type: parsed?.type,
                    }),
                  );
                }}
              />
            </div>
            <div className="flex-1">
              <Select
                {...field}
                inputId={field.name}
                placeholder="Interval"
                options={intervals}
                isDisabled={disabled}
                className="dropdown"
                menuPlacement="auto"
                classNamePrefix="dd"
                innerRef={field.ref}
                value={
                  intervals.find((it) => it.value === parsed?.type) ?? null
                }
                onChange={(v) => {
                  if (v && 'value' in v) {
                    field.onChange(
                      parse({ count: parsed?.count, type: v.value }),
                    );
                  }
                }}
              />
            </div>
            <FaMinusCircle
              onClick={() => field.onChange(undefined)}
              className={clsx(
                !field.value
                  ? 'pointer-events-none cursor-not-allowed text-slate-300'
                  : 'text-slate-500 cursor-pointer hover:text-slate-700',
              )}
            />
          </div>
        );
      }}
      name={name}
      control={control}
      rules={{
        validate: (val) => {
          const parsed = parse(val);
          if (!parsed.type && !parsed.count) {
            return true;
          }

          if (!parsed.type) {
            return 'Interval is required';
          }

          if (!parsed.count) {
            return 'Quantity is required';
          }
        },
      }}
    />
    <InputError>{errorMessage}</InputError>
  </div>
);
