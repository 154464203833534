import {
  SubmittedQuizApplicationInstance,
  TrackerDetails,
} from 'graphql/types';
import React from 'react';
import ProgressEntryImage from './common/progress-entry-image';
import { QuestionFormatter } from './common/questionFormatter';
import { QuizApplicationResponses } from './common/quizApplicationResponses';
import { AnswerFormatter } from './common/responseFormatter';

export const HairTrackerQuestionnaire = ({
  trackerQuiz,
}: {
  trackerQuiz: TrackerDetails;
}): React.ReactElement | null => {
  if (
    !trackerQuiz.signedPhotoTop ||
    !trackerQuiz.signedPhotoFront ||
    !trackerQuiz.signedPhotoBack
  ) {
    return null;
  }

  return (
    <div>
      <HairTrackerResponses
        photoTop={trackerQuiz.signedPhotoTop}
        photoFront={trackerQuiz.signedPhotoFront}
        photoBack={trackerQuiz.signedPhotoBack}
      />
      {trackerQuiz.quizResponses && (
        <QuizApplicationResponses
          quizResponses={
            trackerQuiz.quizResponses as SubmittedQuizApplicationInstance[]
          }
        />
      )}
    </div>
  );
};

const HairTrackerResponses = ({
  photoTop,
  photoFront,
  photoBack,
}: {
  photoTop: string;
  photoFront: string;
  photoBack: string;
}): React.ReactElement => {
  return (
    <>
      <div className="px-4 pb-2 odd:bg-slate-200">
        <div className="flex justify-between space-x-3">
          <QuestionFormatter>Hair Photos</QuestionFormatter>
        </div>

        <AnswerFormatter>
          <div className="flex justify-between space-x-3 py-1">
            <div className="flex space-x-0.5 w-full">
              <ProgressEntryImage imageId={photoTop} />
              <ProgressEntryImage imageId={photoFront} />
              <ProgressEntryImage imageId={photoBack} />
            </div>
          </div>
        </AnswerFormatter>
      </div>
    </>
  );
};
