import { ReactElement } from 'react';
import { Column } from 'react-table';
import { Button } from 'components/button';
import { Drawer } from 'components/drawer';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from 'components/table';
import { formatDateAndTime } from 'utils/misc';
import { Consultation, Maybe } from '../../graphql/types';

type PractitionerBookingHistory = NonNullable<
  Consultation['practitionerBookingEvents']
>;

const DateCell = ({
  value,
}: {
  value: string | null | undefined;
}): ReactElement => {
  if (!value) {
    return <p className="text-xs text-slate-600">-</p>;
  }
  return <div className="text-xs">{formatDateAndTime(value)}</div>;
};

const ValueCell = ({
  value,
}: {
  value: string | null | undefined;
}): ReactElement => {
  if (!value) {
    return <p className=" text-slate-600">-</p>;
  }
  return <div>{value}</div>;
};

const columns: Column<PractitionerBookingHistory[number]>[] = [
  {
    Header: 'Joined queue at',
    accessor: 'joinedQueueAt',
    Cell: DateCell,
    className: 'w-1/5',
  },
  {
    Header: 'Window',
    accessor: 'windowDetails',
    Cell: ValueCell,
    disableSortBy: true,
    className: 'w-2/5',
  },
  {
    Header: 'Event',
    accessor: (row): Maybe<string> =>
      row.removedAtDetails ?? row.assignedByDetails ?? row.bookedByDetails,
    Cell: ValueCell,
    disableSortBy: true,
    className: 'w-2/5',
  },
];

export type PractitionerBookingHistoryDrawerProps = {
  history: PractitionerBookingHistory;
  show: boolean;
  onClose: () => void;
};

export const PractitionerBookingHistoryDrawer: React.FC<
  PractitionerBookingHistoryDrawerProps
> = ({ show, onClose, history }) => {
  const tableInstance = usePaginatingSortingTable({
    columns,
    data: history,
    pageNumber: 1,
  });

  return (
    <Drawer format="wide" show={show} onClose={onClose}>
      <div className="h-full bg-slate-200 overflow-scroll space-y-5">
        <div className="flex justify-between mx-5 top-0 p-5">
          <h1 className="text-2xl font-semibold text-slate-900">
            Practitioner Booking History
          </h1>
          <div>
            <Button fullWidth size="small" variant="outline" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
        <section className="flex flex-col table-fixed mx-5 bg-slate-200">
          <div className="flex flex-col pb-5">
            {history.length === 0 && (
              <p className="text-slate-700 w-full">
                No Practitioner Booking History
              </p>
            )}
            {history.length > 0 && (
              <Table tableInstance={tableInstance}>
                <TableHead />
                <TableBody>
                  <>
                    {tableInstance.page?.map((row) => {
                      tableInstance.prepareRow(row);
                      return (
                        <TableRow row={row} key={row.id}>
                          <>
                            {row.cells.map((cell) => (
                              <TableCell
                                key={`${cell.row.original.id}-${cell.column.id}`}
                                cell={cell}
                              />
                            ))}
                          </>
                        </TableRow>
                      );
                    })}
                  </>
                </TableBody>
              </Table>
            )}
          </div>
        </section>
      </div>
    </Drawer>
  );
};
