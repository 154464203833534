import React from 'react';
import { ProblemType } from 'graphql/types';
import { Tracker } from './types';
import { WeightProgressOverview } from './weightProgressOverview';

export const ProgressOverviewSection = ({
  problemType,
  tracker,
}: {
  problemType: ProblemType;
  tracker: Tracker;
}): React.ReactElement | null => {
  if (problemType !== 'WEIGHT_LOSS') {
    return null;
  }

  const weightLoss = tracker?.weightLoss;
  const weightGoal = tracker?.weightLoss.weightGoal;

  return (
    <div className="overflow-hidden rounded shadow bg-white px-4 py-5 border-b border-slate-200">
      <div className="items-center flex justify-between">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Progress Overview
        </h3>
      </div>
      <WeightProgressOverview
        bmi={weightLoss?.bmiProgress}
        waist={weightLoss?.waistProgress}
        weight={weightLoss?.weightProgress}
        initialDateString={weightLoss?.initialWeightTrackedDate}
        mostRecentDateString={weightLoss?.mostRecentWeightTrackedDate}
        currentWeightKg={weightLoss?.currentWeightValueKg}
        initialWeightKg={weightLoss?.initialWeightValueKg}
        goalFeedbackType={weightGoal?.feedbackType}
        goalWeightKg={weightGoal?.weightKg}
        goalWeightBmi={weightGoal?.bmi}
        milestones={weightGoal?.milestones}
      />
    </div>
  );
};
