import { Button } from 'components/button';
import { AddNoteModalButton } from 'components/notes/add-note/add-note-modal-button';
import { NotesTable } from 'components/notes/notes-table';
import {
  AddNoteModalConsultationFragment,
  NoteRowFragment,
} from 'graphql/types';
import { useHistory } from 'react-router-dom';
import { buildRoute } from 'utils/routes';

export const NotesSection: React.FC<{
  customer: {
    id: string;
    fullName: string;
  };
  notes: NoteRowFragment[];
  consultations: AddNoteModalConsultationFragment[];
}> = ({ customer, notes, consultations }) => {
  const history = useHistory();

  return (
    <div className="bg-white shadow overflow-hidden rounded">
      <div className="px-4 py-5 flex space-x-4 border-b">
        <h3 className="text-lg leading-6 font-medium text-slate-900 flex-grow">
          Notes
          <span className="ml-2 text-slate-500">{notes.length}</span>
        </h3>

        <Button
          variant="outline"
          size="small"
          onClick={() => history.push(buildRoute.customerNotes(customer.id))}
        >
          View notes
        </Button>
        <AddNoteModalButton
          customer={customer}
          consultations={consultations}
          defaultValues={{
            consultation: consultations[0],
            canEditTarget: true,
          }}
        />
      </div>

      <NotesTable notes={notes} />
    </div>
  );
};
