import React from 'react';
import { ProblemType } from 'graphql/types';
import { useNotifications } from 'notifications';
import { Modal } from 'components/modal';
import { ReopenLastConsultation } from './main';

type ReopenLastConsultationButtonProps = {
  problemType: ProblemType;
  patientFullName: string;
  consultationId: string;
};

export const ReopenLastConsultationButton: React.FC<
  ReopenLastConsultationButtonProps
> = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const showNotification = useNotifications();

  return (
    <>
      <button
        type="button"
        className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 hover:bg-slate-100 active:text-slate-800 active:bg-white transition duration-150 ease-in-out"
        onClick={(): void => {
          setShowModal(true);
        }}
      >
        ⬅️ Reopen Consultation
      </button>
      {showModal && (
        <Modal
          show
          onClose={(): void => setShowModal(false)}
          isAutoOverflow={false}
          width="w-5/6"
        >
          <ReopenLastConsultation
            onCancel={(): void => setShowModal(false)}
            onCompleted={(): void => {
              setShowModal(false);
              showNotification({
                type: 'success',
                message: 'Successfully reopened last consultation',
              });
            }}
            {...props}
          />
        </Modal>
      )}
    </>
  );
};
