import { gql, useQuery } from '@apollo/client';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { Loading } from 'components/loading';
import { Modal } from 'components/modal';
import { Tag } from 'components/tag';
import { useBuildUserQueryFilter } from 'components/user-filter';
import { UserWhereInput } from 'graphql/types';
import { useEffect, useState } from 'react';
import { UserResponse } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { formatDateAndTime } from 'utils/misc';

export const ActivityModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const { client } = useChatContext();
  const activeFilter = useBuildUserQueryFilter('DOCTOR');
  const [practitionerData, setPractitionerData] =
    useState<UserResponse<DefaultStreamChatGenerics>[]>();

  const { data: queryResp, loading } = useQuery<
    {
      practitioners: {
        id: string;
        isAvailable: boolean;
      }[];
    },
    {
      where: UserWhereInput;
    }
  >(
    gql`
      query PractitionerActivitityContent($where: UserWhereInput) {
        practitioners: users(where: $where) {
          id
          isAvailable
        }
      }
    `,
    {
      variables: {
        where: activeFilter,
      },
      skip: !client,
    },
  );

  useEffect(() => {
    const loadPractitionerData = async () => {
      const activePractitionerIds =
        queryResp?.practitioners
          .filter((p) => p.isAvailable)
          .map((p) => p.id) ?? [];

      const { users } = await client.queryUsers(
        {
          id: {
            $in: activePractitionerIds,
          },
        },
        { last_active: -1, online: -1 },
        { presence: true },
      );

      setPractitionerData(users);
    };

    if (queryResp && queryResp?.practitioners.length > 0) {
      loadPractitionerData();
    }
  }, [client, queryResp]);

  return (
    <Modal show onClose={onClose} width="w-3/4">
      <div className="bg-white p-6 text-left">
        {loading ? (
          <Loading />
        ) : (
          <div>
            {practitionerData && (
              <div className="space-y-4">
                <h2 className="heading-md">Practitioners</h2>
                <table className="w-full">
                  <thead>
                    <th>
                      <p className="text-base font-bold">User ID</p>
                    </th>
                    <th>
                      <p className="text-base font-bold">Name</p>
                    </th>
                    <th>
                      <p className="text-base font-bold">Activity</p>
                    </th>
                    <th>
                      <p className="text-base font-bold text-right w-full">
                        Last Active
                      </p>
                    </th>
                  </thead>
                  <tbody>
                    {practitionerData.map((practitioner) => {
                      const isActive = practitioner.online;

                      return (
                        <tr key={practitioner.id} className="py-2">
                          <td className="py-2 pr-2 text-sm text-slate-500">
                            <span>{practitioner.id}</span>
                            <CopyToClipboardButton value={practitioner.id} />
                          </td>
                          <td className="py-2 pr-4">{practitioner.name}</td>
                          <td className="pr-4">
                            <div className="space-x-1 flex items-center">
                              <Tag
                                size="small"
                                color={isActive ? 'green' : 'red'}
                              >
                                {practitioner.online ? 'Online' : 'Offline'}
                              </Tag>
                            </div>
                          </td>
                          <td className="text-right">
                            {practitioner.last_active
                              ? formatDateAndTime(
                                  new Date(practitioner.last_active),
                                  'h:mm a, do MMM',
                                )
                              : 'N/A'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
