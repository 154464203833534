import React, { useMemo, useState } from 'react';
import {
  UpdateShippingAddressMutation,
  UpdateShippingAddressMutationVariables,
} from 'graphql/types';
import { gql, useMutation } from '@apollo/client';
import { useHasPermissions } from 'components/permissions';
import { config } from 'config';
import { useNotifications } from 'notifications';
import { useForm } from 'react-hook-form-6';
import { useParams } from 'react-router-dom';
import { AddressInputIntl } from 'components/address-input/address-input-intl';
import { AddressSubmit } from 'components/address-input/types';
import { Customer } from './types';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { AddressHistoryDrawer } from './addressHistoryDrawer';
import { Copyable } from 'components/copyable';

const buttonClass =
  'inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white hover:text-slate-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white transition duration-150 ease-in-out';

export const ShippingAddressSection = ({
  customer,
}: {
  customer: Customer;
}): React.ReactElement => {
  const showNotification = useNotifications();

  const { customerId } = useParams<{ customerId: string }>();
  const shippingAddressHistory = customer.address?.history ?? [];
  const [showModal, setShowModal] = useState(false);
  const featureFlagClient = useFeatureFlagClient();
  const historyEnabled = featureFlagClient.getBoolean(
    'ff_pra7_address_history',
  );

  const [updateShippingAddressMutation, { loading }] = useMutation<
    UpdateShippingAddressMutation,
    UpdateShippingAddressMutationVariables
  >(
    gql`
      mutation UpdateShippingAddress(
        $patientId: String!
        $shippingAddress: ShippingAddressCreateInput!
      ) {
        updateShippingAddress(
          patientId: $patientId
          shippingAddress: $shippingAddress
        ) {
          address {
            id
            history {
              id
              createdAt
              formattedString
              author {
                id
                role
                fullName
                clinicianName
              }
            }
            user {
              id
              address {
                id
                line1
                line2
                city
                postalCode
                prefecture
                municipality
                townArea
                state
                country
                deliveryInstructions
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Updated patient shipping address',
        });
      },
    },
  );

  const defaultValues = useMemo(() => {
    return {
      patientId: customerId,
      shippingAddress: {
        line1: customer?.address?.line1 || undefined,
        line2: customer?.address?.line2 || undefined,
        city: customer?.address?.city || undefined,
        postalCode: customer?.address?.postalCode || undefined,
        prefecture: customer?.address?.prefecture || undefined,
        municipality: customer?.address?.municipality || undefined,
        townArea: customer?.address?.townArea || undefined,
        state: customer?.address?.state || undefined,
        country: customer?.address?.country || config.country,
        deliveryInstructions:
          customer?.address?.deliveryInstructions || undefined,
        company: customer?.address?.company || undefined,
        building: customer?.address?.building || undefined,
      },
    };
  }, [customer, customerId]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = useForm<AddressSubmit>({
    defaultValues,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const updateShippingAddress = async (
    variables: UpdateShippingAddressMutationVariables,
  ): Promise<void> => {
    try {
      await updateShippingAddressMutation({
        variables,
      });
    } catch {
      return;
    }
  };

  const submitShippingAddress = async (
    formData: AddressSubmit,
  ): Promise<void> => {
    if (isDirty) {
      await updateShippingAddress({
        patientId: customerId,
        shippingAddress: {
          line1: formData.shippingAddress?.line1 || undefined,
          line2: formData.shippingAddress?.line2 || undefined,
          city: formData.shippingAddress?.city || undefined,
          state: formData.shippingAddress?.state || undefined,
          postalCode: formData.shippingAddress?.postalCode || undefined,
          prefecture: formData.shippingAddress?.prefecture || undefined,
          municipality: formData.shippingAddress?.municipality || undefined,
          townArea: formData.shippingAddress?.townArea || undefined,
          country: formData.shippingAddress?.country || undefined,
          deliveryInstructions:
            formData.shippingAddress?.deliveryInstructions || undefined,
          company: formData.shippingAddress?.company || undefined,
          building: formData.shippingAddress?.building || undefined,
        },
      });
    }
  };

  const canEditPatients = useHasPermissions(['EDIT_PATIENTS']);

  return (
    <div className="bg-white shadow overflow-hidden rounded my-4">
      <div className="flex px-4 pt-5 pb-4 border-b border-slate-200 justify-between">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Shipping Address
        </h3>
        <Copyable text={customer.address?.id ?? ''}>
          {(copied) => (
            <pre className="text-sm cursor-pointer text-slate-500">
              {copied ? 'Copied' : customer.address?.id.slice(-6)}
            </pre>
          )}
        </Copyable>
      </div>
      <form
        onSubmit={handleSubmit(submitShippingAddress)}
        className="overflow-hidden bg-white p-4"
      >
        <div className="mb-3 text-sm">
          <AddressInputIntl
            name="shippingAddress"
            registerField={register}
            errors={errors.shippingAddress}
          />
        </div>
        <div className="flex justify-end gap-2">
          {historyEnabled && (
            <button onClick={() => setShowModal(true)} className={buttonClass}>
              View History
            </button>
          )}
          <button
            type="submit"
            className={buttonClass}
            disabled={isSubmitting || !isDirty || loading || !canEditPatients}
          >
            Save changes
          </button>
        </div>
      </form>
      <AddressHistoryDrawer
        show={showModal}
        onClose={() => setShowModal(false)}
        history={shippingAddressHistory}
      />
    </div>
  );
};
