import { ReactElement } from 'react';
import { SmallTableHeadings } from '../../../components/small-table/small-table-headings';
import { QuizApplications } from '../types';
import { QuizTableBody } from './quiz-table-body';

export const QuizWithNoConsult = ({
  quiz,
}: {
  quiz: NonNullable<QuizApplications>;
}): ReactElement => {
  const headings = [
    'Quiz Application ID',
    'Quiz ID',
    'Purpose',
    'Created',
    'Submitted',
    'Archived',
  ];
  return (
    <div className="overflow-hidden rounded shadow bg-white">
      <div className="px-4 py-5 border-b border-slate-200">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Quizzes
          <span className="ml-2 text-slate-500">{quiz.length}</span>
        </h3>
      </div>
      <div className="overflow-x-auto">
        <div className="min-w-full">
          <table className="min-w-full divide-y divide-slate-200">
            <thead className="bg-slate-50 w-full text-slate-500">
              <SmallTableHeadings headings={headings} />
            </thead>
            <tbody className="bg-white divide-y divide-slate-200">
              <QuizTableBody quiz={quiz} />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
