import { Markdown } from 'components/markdown';
import { NoteRowFragment } from 'graphql/types';
import { Addendums } from './addendums';
import { NoteHistoryModalButton } from './history/note-history-modal-button';

export const NoteView: React.FC<{ note: NoteRowFragment }> = ({ note }) => {
  const showHistory = note.history && note.history.length > 1;
  return (
    <div className="space-y-4 h-full flex flex-col justify-between">
      <Markdown src={note.body ?? ''} />
      <div className="w-5/6">
        <Addendums note={note} />
      </div>
      {showHistory && (
        <div className="pt-2 border-t border-slate-500">
          <NoteHistoryModalButton title="Note" note={note} />
        </div>
      )}
    </div>
  );
};
