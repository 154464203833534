import { gql, useApolloClient } from '@apollo/client';
import clsx from 'clsx';
import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown-v7';
import { Input } from 'components/input';
import { Modal } from 'components/modal';
import {
  CustomerPlanCompleteActionMutation,
  CustomerPlanCompleteActionMutationVariables,
  CustomerPlanDiscardActionMutation,
  CustomerPlanDiscardActionMutationVariables,
  CustomerPlanFragment,
  CustomerPlanInjectActionFragment,
  CustomerPlanStageActionMutation,
  CustomerPlanStageActionMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheck, FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { v4 } from 'uuid';

type Props = { query: CustomerPlanFragment };
type Form = { title: string; actionId: string; url: string };

const col = clsx(
  'rounded-md shadow border bg-slate-50 border-slate-200 flex-1 min-w-0',
);
const colTitle = clsx(
  'mx-4 mt-3 text-xs text-slate-500 select-none font-semibold',
);
const colContainer = clsx('px-2 mt-3 pb-2 flex flex-col gap-2');

export function Plan(props: Props) {
  const [id, setId] = useState('');
  const form = useForm<Form>();
  const client = useApolloClient();
  const history = useHistory();
  const notify = useNotifications();
  const customer = props.query.customer;
  const actions = props.query.actions ?? [];

  const staged = [];
  const surfaced = [];
  const completed = [];
  for (const a of customer?.allPlanActions ?? []) {
    switch (a.status) {
      case 'COMPLETED':
      case 'DISCARDED':
        completed.push(a);
        break;
      case 'STAGED':
        staged.push(a);
        break;
      case 'SURFACED':
        surfaced.push(a);
        break;
    }
  }

  return (
    <div
      className={clsx(
        'bg-white shadow border border-slate-200 rounded-md flex flex-col flex-1 overflow-hidden text-slate-700',
      )}
    >
      <div className="w-full flex justify-between items-center bg-slate-50 px-5 py-4 border-b border-slate-200">
        <h1 className="font-semibold select-none">
          {customer?.firstName ? `${customer.firstName}'s Plan` : 'Plan'}
        </h1>
        <Button onClick={() => setId(v4())} size="small" variant="text">
          <div className="inline-flex gap-1.5 items-center text-slate-700">
            Stage action
          </div>
        </Button>
      </div>
      <div className="flex gap-3 px-4 py-3">
        <div className={col}>
          <div className={colTitle}>Staged</div>
          <div className={colContainer}>
            {staged.map((a) => (
              <PlanAction
                key={a.id}
                onActionClick={() =>
                  history.push(`${routes.actions}?action=${a.action?.id}`)
                }
                actionName={a.action?.name ?? '—'}
                title={a.title}
                url={a.url}
                titleTemplate={a.action?.titleTemplate ?? '—'}
                urlTemplate={a.action?.urlTemplate ?? '—'}
                actionDescription={a.action?.description ?? '—'}
                actionableAt={
                  a.actionableAt
                    ? new Date(a.actionableAt).toLocaleString()
                    : undefined
                }
              />
            ))}
          </div>
        </div>
        <div className={col}>
          <div className={colTitle}>Surfaced</div>
          <div className={colContainer}>
            {surfaced.map((a) => (
              <PlanAction
                key={a.id}
                onActionClick={() =>
                  history.push(`${routes.actions}?action=${a.action?.id}`)
                }
                actionName={a.action?.name ?? '—'}
                title={a.title}
                url={a.url}
                titleTemplate={a.action?.titleTemplate ?? '—'}
                urlTemplate={a.action?.urlTemplate ?? '—'}
                actionDescription={a.action?.description ?? '—'}
                actionableAt={
                  a.actionableAt
                    ? new Date(a.actionableAt).toLocaleString()
                    : undefined
                }
                onCompleteClick={async () => {
                  await client.mutate<
                    CustomerPlanCompleteActionMutation,
                    CustomerPlanCompleteActionMutationVariables
                  >({
                    mutation: gql`
                      mutation CustomerPlanCompleteAction(
                        $input: CompletePlanActionInput!
                      ) {
                        completePlanAction(input: $input) {
                          planAction {
                            id
                            status
                          }
                        }
                      }
                    `,
                    variables: {
                      input: {
                        id: a.id,
                      },
                    },
                    optimisticResponse: {
                      __typename: 'Mutation',
                      completePlanAction: {
                        __typename: 'CompletePlanActionPayload',
                        planAction: {
                          __typename: 'PlanAction',
                          id: a.id,
                          status: 'COMPLETED',
                        },
                      },
                    },
                  });
                }}
                onDiscardClick={async () => {
                  await client.mutate<
                    CustomerPlanDiscardActionMutation,
                    CustomerPlanDiscardActionMutationVariables
                  >({
                    mutation: gql`
                      mutation CustomerPlanDiscardAction(
                        $input: DiscardPlanActionInput!
                      ) {
                        discardPlanAction(input: $input) {
                          planAction {
                            id
                            status
                          }
                        }
                      }
                    `,
                    variables: {
                      input: {
                        id: a.id,
                      },
                    },
                    optimisticResponse: {
                      __typename: 'Mutation',
                      discardPlanAction: {
                        __typename: 'DiscardPlanActionPayload',
                        planAction: {
                          __typename: 'PlanAction',
                          id: a.id,
                          status: 'DISCARDED',
                        },
                      },
                    },
                  });
                }}
              />
            ))}
          </div>
        </div>
        <div className={col}>
          <div className={colTitle}>Completed</div>
          <div className={colContainer}>
            {completed.map((a) => (
              <PlanAction
                key={a.id}
                onActionClick={() =>
                  history.push(`${routes.actions}?action=${a.action?.id}`)
                }
                actionName={a.action?.name ?? '—'}
                title={a.title}
                url={a.url}
                titleTemplate={a.action?.titleTemplate ?? '—'}
                urlTemplate={a.action?.urlTemplate ?? '—'}
                actionDescription={a.action?.description ?? '—'}
                actionableAt={
                  a.actionableAt
                    ? new Date(a.actionableAt).toLocaleString()
                    : undefined
                }
              />
            ))}
          </div>
        </div>
      </div>

      <Modal show={!!id} onClose={() => setId('')}>
        <form
          className="bg-white rounded shadow border-slate-200"
          onSubmit={form.handleSubmit(async (data) => {
            try {
              const resp = await client.mutate<
                CustomerPlanStageActionMutation,
                CustomerPlanStageActionMutationVariables
              >({
                mutation: gql`
                  mutation CustomerPlanStageAction(
                    $input: StagePlanActionInput!
                  ) {
                    stagePlanAction(input: $input) {
                      planAction {
                        id
                        action {
                          id
                          name
                          description
                          titleTemplate
                          urlTemplate
                          priority
                        }
                        title
                        url
                        status
                        actionableAt
                      }
                    }
                  }
                `,
                variables: {
                  input: {
                    id,
                    actionId: data.actionId,
                    customerId: customer?.id ?? '',
                    title: data.title,
                    url: data.url,
                  },
                },
              });

              const newPlanAction = resp.data?.stagePlanAction?.planAction;
              const allPlanActions = customer?.allPlanActions?.slice() ?? [];
              if (newPlanAction) {
                const idx = allPlanActions.findIndex(
                  (pa) =>
                    (newPlanAction.action?.priority ?? 0) >
                    (pa.action?.priority ?? 0),
                );

                if (idx === -1) {
                  allPlanActions.push(newPlanAction);
                } else {
                  allPlanActions.splice(idx, 0, newPlanAction);
                }
              }

              client.writeFragment<CustomerPlanInjectActionFragment>({
                fragmentName: 'CustomerPlanInjectAction',
                fragment: gql`
                  # eslint-disable-next-line @graphql-eslint/no-unused-fragments
                  fragment CustomerPlanInjectAction on User {
                    __typename
                    id
                    allPlanActions {
                      id
                      action {
                        id
                        name
                        description
                        titleTemplate
                        urlTemplate
                        priority
                      }
                      title
                      url
                      status
                      actionableAt
                    }
                  }
                `,
                data: {
                  __typename: 'User',
                  id: customer?.id ?? '',
                  allPlanActions,
                },
              });

              notify({
                type: 'success',
                message: 'Successfully staged action',
              });
            } finally {
              setId('');
            }
          })}
        >
          <div className="bg-slate-50 px-5 py-4 border-b border-slate-200">
            <h1 className="font-semibold select-none">Add Action</h1>
          </div>
          <div className="px-5 py-4">
            <div className="flex flex-col gap-4">
              <Dropdown
                label="Action"
                name="actionId"
                placeholder="Action ID"
                control={form.control}
                handleSingleSelect={(o) => {
                  for (const a of actions) {
                    if (a.id === o.value) {
                      form.setValue('title', a.titleTemplate);
                      form.setValue('url', a.urlTemplate);
                      return;
                    }
                  }
                }}
                options={
                  props.query.actions?.map((a) => ({
                    value: a.id,
                    label: a.name,
                  })) ?? []
                }
              />
              <Input
                label="Title"
                placeholder="Title"
                {...form.register('title')}
              />
              <Input label="URL" placeholder="URL" {...form.register('url')} />
            </div>
            <div className="flex gap-3 justify-end mt-6">
              <Button
                onClick={() => {
                  form.reset();
                  setId('');
                }}
                variant="outline"
              >
                Cancel
              </Button>
              <Button loading={form.formState.isSubmitting} type="submit">
                Submit
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}

function PlanAction(props: {
  onActionClick: () => void;
  onDiscardClick?: () => void;
  onCompleteClick?: () => void;
  title: string;
  titleTemplate: string;
  url: string;
  urlTemplate: string;
  actionableAt?: string;
  actionDescription: string;
  actionName: string;
}) {
  return (
    <div className="group bg-white rounded-md px-2 py-2 shadow hover:shadow-md hover:border-slate-300 border border-slate-200 text-slate-600 text-xs select-none transition-all hover:scale-[101%]">
      <div className="relative font-medium mb-2 flex justify-between">
        <div className="group-hover:opacity-0 truncate">{props.title}</div>
        <div className="opacity-0 group-hover:opacity-100 top-0 left-0 absolute w-[80%] truncate">
          {props.titleTemplate}
        </div>
        <div className="flex gap-2 text-slate-400 opacity-0 group-hover:opacity-100 transition-all duration-200">
          <FaTrash
            onClick={props.onDiscardClick}
            className={clsx(
              'hover:text-slate-600 cursor-pointer transition-all',
              !props.onDiscardClick && 'hidden',
            )}
          />
          <FaCheck
            onClick={props.onCompleteClick}
            className={clsx(
              'hover:text-slate-600 cursor-pointer transition-all',
              !props.onCompleteClick && 'hidden',
            )}
          />
        </div>
      </div>
      <pre className="relative">
        <div className="group-hover:opacity-0 truncate">{props.url}</div>
        <div className="opacity-0 group-hover:opacity-100 top-0 left-0 absolute w-full truncate">
          {props.urlTemplate}
        </div>
      </pre>
      <div
        className={clsx(
          'truncate text-gray-400',
          !props.actionableAt ? 'hidden' : 'mt-2',
        )}
      >
        {props.actionableAt}
      </div>
      <div
        onClick={props.onActionClick}
        className="mt-3 space-y-2 cursor-pointer rounded-md bg-slate-50 px-2 py-1 border border-slate-100 hover:border-slate-200 hover:shadow-sm transition-all"
      >
        <div className="text-slate-600 truncate">{props.actionName}</div>
        <div className="text-slate-500 truncate">{props.actionDescription}</div>
      </div>
    </div>
  );
}

Plan.fragment = gql`
  fragment CustomerPlan on Query {
    actions {
      id
      name
      titleTemplate
      urlTemplate
    }
    customer: user(where: $where) {
      id
      firstName
      allPlanActions {
        id
        action {
          id
          name
          description
          titleTemplate
          urlTemplate
          priority
        }
        title
        url
        status
        actionableAt
      }
    }
  }
`;
