import { SubmittedQuizApplicationInstance } from 'graphql/types';
import clsx from 'clsx';
import { QuestionFormatter } from './questionFormatter';
import { QuizResponseFormatter } from './responseFormatter';

export const QuizApplicationResponses = ({
  quizResponses,
}: {
  quizResponses: SubmittedQuizApplicationInstance[];
}): JSX.Element => {
  return (
    <>
      {quizResponses
        .flatMap((quizResponse) => quizResponse?.responses)
        .map((res, i) => {
          if (!res) return <></>;

          return (
            <div
              key={`${res.question?.id} ${i}`}
              className={clsx('px-4 pb-2 odd:bg-slate-200')}
            >
              <QuestionFormatter>{res.question?.title}</QuestionFormatter>
              <QuizResponseFormatter quizResponse={res} />
            </div>
          );
        })}
    </>
  );
};
