import React from 'react';
import classNames from 'clsx';

export const BandedRow: React.FC<{
  label: React.ReactElement | string;
  value: React.ReactElement | string | undefined | null;
  bgColor: 'white' | 'grey';
  placeholderValue: React.ReactElement | string;
  className?: string;
}> = ({ placeholderValue, label, value, bgColor, className }) => {
  return (
    <div
      className={classNames(
        `px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`,
        {
          'bg-slate-100': bgColor === 'grey',
          'bg-white': bgColor === 'white',
        },
        className,
      )}
    >
      <dt className="text-sm leading-5 text-slate-500">{label}</dt>
      <dd className="text-sm leading-5 sm:mt-0 sm:col-span-2">
        {value || placeholderValue}
      </dd>
    </div>
  );
};
