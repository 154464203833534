import { gql, useMutation } from '@apollo/client';
import {
  MutationCancelMembershipSubscriptionArgs,
  MutationReactivateMembershipSubscriptionArgs,
} from 'graphql/types';
import { AuditLogTable } from 'components/audit/audit-log-table';
import { Button } from 'components/button';
import { onError } from 'logging';
import { useNotifications } from 'notifications';
import React from 'react';
import { useAlert } from '../../alert';
import { Modal } from '../../components/modal';
import { formatDateAndTime, formatCurrencyAmountCents } from '../../utils/misc';
import { CancelModal } from './cancelModal';
import { JobTable } from './jobTable';
import { ReactivateModal } from './reactivateModal';
import { CustomerMembershipView } from './types';

const cancelSubscriptionMutation = gql`
  mutation cancelMembershipSubscription($userId: String!, $type: ProblemType!) {
    cancelMembershipSubscription(userId: $userId, type: $type) {
      id
    }
  }
`;

const reactivateSubscriptionMutation = gql`
  mutation reactivateSubscription($userId: String!, $type: ProblemType!) {
    reactivateMembershipSubscription(userId: $userId, type: $type) {
      id
    }
  }
`;

export const MembershipPanel = ({
  membership,
  onUpdated,
}: {
  membership: CustomerMembershipView;
  onUpdated: () => void;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const showAlert = useAlert();

  const [cancelSubscription, { loading: cancelSubscriptionLoading }] =
    useMutation<void, MutationCancelMembershipSubscriptionArgs>(
      cancelSubscriptionMutation,
      {
        onError,
      },
    );

  const [reactivateSubscription, { loading: reactivateSubscriptionLoading }] =
    useMutation<void, MutationReactivateMembershipSubscriptionArgs>(
      reactivateSubscriptionMutation,
      {
        onError,
      },
    );
  const loading = cancelSubscriptionLoading || reactivateSubscriptionLoading;

  const [cancelModalActive, setCancelModalActive] = React.useState(false);
  const [reactivateModalActive, setReactivateModalActive] =
    React.useState(false);
  return (
    <>
      <div className="overflow-hidden rounded shadow my-4 bg-white">
        <div className="">
          <div className="px-4 py-5 border-b border-slate-200 flex-1">
            <div className="flex">
              <h3 className="text-lg leading-6 font-medium text-slate-900">
                Memberships
              </h3>
              <div className="flex-initial ml-auto">
                {!membership.isValid &&
                  membership.status !== 'PAYMENT_FAILED' && (
                    <Button
                      fullWidth
                      loading={loading}
                      onClick={async (): Promise<void> => {
                        setReactivateModalActive(true);
                      }}
                    >
                      Reactivate Membership
                    </Button>
                  )}
                {(membership.isValid ||
                  membership.status === 'PAYMENT_FAILED') && (
                  <Button
                    fullWidth
                    color="danger"
                    loading={loading}
                    onClick={async (): Promise<void> => {
                      setCancelModalActive(true);
                    }}
                  >
                    Cancel Membership
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        {membership ? (
          <>
            <div className="flex bg-slate-100">
              <dl className="w-1/2 px-4 py-3 flex items-center sm:grid sm:grid-cols-4 sm:gap-1 sm:px-3">
                <dt className="text-sm leading-5 font-medium text-slate-500">
                  Status
                </dt>
                <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                  {membership.status}
                </dd>
              </dl>
              <dl className="w-1/2 px-4 py-3 flex items-center sm:grid sm:grid-cols-4 sm:gap-1 sm:px-3">
                <dt className="text-sm leading-5 font-medium text-slate-500">
                  Auto-renew
                </dt>
                <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2 flex items-center ">
                  {membership.subscriptionStatus === 'ACTIVE' ? 'ON' : 'OFF'}
                  <div className="flex-initial ml-2">
                    {membership.status === 'ACTIVE' &&
                      membership.subscriptionStatus === 'ACTIVE' && (
                        <Button
                          fullWidth
                          color="danger"
                          loading={loading}
                          variant="outline"
                          onClick={async (): Promise<void> => {
                            const result = await showAlert({
                              content: `Are you sure you want to stop auto-renew for this patient? This will stop automatically charging them for their membership, but they will still be able to access their treatment.`,
                            });
                            if (!result) {
                              return;
                            }
                            await cancelSubscription({
                              variables: {
                                userId: membership.userId,
                                type: membership.type,
                              },
                            });
                            showNotification({
                              message: 'Auto renew has been turned off',
                              type: 'success',
                            });
                            onUpdated();
                          }}
                        >
                          Turn off
                        </Button>
                      )}
                    {membership.status === 'ACTIVE' &&
                      membership.subscriptionStatus === 'CANCELED' && (
                        <Button
                          fullWidth
                          loading={loading}
                          variant="outline"
                          onClick={async (): Promise<void> => {
                            const result = await showAlert({
                              content: `Are you sure to turn on the auto renew?`,
                            });
                            if (!result) {
                              return;
                            }
                            await reactivateSubscription({
                              variables: {
                                userId: membership.userId,
                                type: membership.type,
                              },
                            });
                            showNotification({
                              message: 'Auto renew has been turned on',
                              type: 'success',
                            });
                            onUpdated();
                          }}
                        >
                          Turn on
                        </Button>
                      )}
                  </div>
                </dd>
              </dl>
            </div>
            <div className="flex">
              <dl className="w-1/2 px-4 py-3 flex items-center sm:grid sm:grid-cols-4 sm:gap-1 sm:px-3">
                <dt className="text-sm leading-5 font-medium text-slate-500">
                  Start Date
                </dt>
                <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                  {membership.startDate
                    ? formatDateAndTime(membership.startDate)
                    : ''}
                </dd>
              </dl>
              <dl className="w-1/2 px-4 py-3 flex items-center sm:grid sm:grid-cols-4 sm:gap-1 sm:px-3">
                <dt className="text-sm leading-5 font-medium text-slate-500">
                  End Date
                </dt>
                <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                  {membership.endDate
                    ? formatDateAndTime(membership.endDate)
                    : ''}
                </dd>
              </dl>
            </div>
            <div className="flex">
              <dl className="w-1/2 px-4 py-3 flex items-center sm:grid sm:grid-cols-4 sm:gap-1 sm:px-3">
                <dt className="text-sm leading-5 font-medium text-slate-500">
                  Amount
                </dt>
                <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                  {formatCurrencyAmountCents(membership.amount)}
                </dd>
              </dl>
              <dl className="w-1/2 px-4 py-3 flex items-center sm:grid sm:grid-cols-4 sm:gap-1 sm:px-3">
                <dt className="text-sm leading-5 font-medium text-slate-500">
                  Next Payment
                </dt>
                <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                  {membership.nextPaymentDue
                    ? formatDateAndTime(membership.nextPaymentDue)
                    : ''}
                </dd>
              </dl>
            </div>
            <div className="divide-y divide-slate-200">
              <JobTable jobs={membership?.jobs} />
              <AuditLogTable targetId={membership.id} targetType="MEMBERSHIP" />
            </div>
          </>
        ) : (
          <p className="px-4 py-3">No memberships</p>
        )}
      </div>
      <Modal
        show={cancelModalActive}
        isAutoOverflow={false}
        onClose={(): void => setCancelModalActive(false)}
      >
        <CancelModal
          onCancelled={(): void => {
            showNotification({
              message: 'Membership has been canceled',
              type: 'success',
            });
            onUpdated();
          }}
          close={(): void => setCancelModalActive(false)}
          type={membership.type}
          userId={membership.userId}
        />
      </Modal>
      <Modal
        show={reactivateModalActive}
        isAutoOverflow={false}
        onClose={(): void => setReactivateModalActive(false)}
      >
        <ReactivateModal
          onReactivated={(): void => {
            showNotification({
              message: 'Membership has been reactivated',
              type: 'success',
            });
            onUpdated();
          }}
          close={(): void => setReactivateModalActive(false)}
          type={membership.type}
          userId={membership.userId}
        />
      </Modal>
    </>
  );
};
