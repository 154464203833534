import React from 'react';
import {
  upperSnakeCaseToCapitalCase,
  statusColorBackground,
  statusColorText,
} from '../../utils/misc';
import { JobTable } from './jobTable';
import { OrdersTable } from './ordersTable';
import { TreatmentActions } from './treatmentActions/treamentActions';
import { Customer, CustomerTreatmentView } from './types';
import { TreatmentTable } from './treatmentTable';
import { TreatmentLogsTable } from './treatmentLogsTable';
import { PaymentPlanTable } from './paymentPlanTable';
import { PaymentPlanActions } from './treatmentActions/paymentPlanActions';
import { OtcSchedulesTable } from './otcSchedulesTable';

export const TreatmentSection = (props: {
  treatment: CustomerTreatmentView;
  showActions: boolean;
  refetchCustomer: () => void;
  customer: Customer;
}): React.ReactElement => {
  return (
    <div className="bg-white shadow overflow-hidden rounded">
      <div className="px-4 py-5 border-b border-slate-200">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Treatment
          <span
            className={`ml-4 px-2 text-sm leading-5 rounded-full ${statusColorBackground(
              props.treatment.status,
            )} ${statusColorText(props.treatment.status)}`}
          >
            {upperSnakeCaseToCapitalCase(props.treatment.status)}
          </span>
        </h3>
      </div>
      <>
        <TreatmentTable treatment={props.treatment} />
        {props.showActions && (
          <TreatmentActions
            customer={props.customer}
            treatment={props.treatment}
            refetchCustomer={props.refetchCustomer}
          />
        )}
        <PaymentPlanTable treatment={props.treatment} />
        <PaymentPlanActions
          treatment={props.treatment}
          refetchCustomer={props.refetchCustomer}
        />
        <OtcSchedulesTable schedules={props.treatment.otcSchedules} />
        <JobTable jobs={props.treatment.jobs} />
        <OrdersTable completedOrders={props.treatment.completedOrders} />
        <TreatmentLogsTable treatment={props.treatment} />
      </>
    </div>
  );
};
