import { SmallTableHeadings } from 'components/small-table/small-table-headings';
import { Consultation } from './types';
import { Copyable } from 'components/copyable';
import { Link } from 'react-router-dom';
import { buildRoute, routes } from 'utils/routes';
import { formatDistanceToNow } from 'date-fns';
import { Tag } from 'components/tag';

type Props = {
  prescribedSequences: NonNullable<Consultation['prescribedSequences']>;
};

export function PrescribedSequenceSection(props: Props) {
  return (
    <div className="overflow-hidden rounded shadow bg-white pt-2 border-b border-slate-200">
      <div className="border-b px-4 py-4 border-slate-200 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Prescribed Sequences
          <span className="ml-2 text-slate-500">
            {props.prescribedSequences.length}
          </span>
        </h3>
      </div>
      {!!props.prescribedSequences.length && (
        <div className="overflow-x-auto">
          <div className="min-w-full">
            <table className="min-w-full divide-y divide-slate-200">
              <thead>
                <SmallTableHeadings
                  headings={[
                    'ID',
                    'Consultation',
                    'Sequence',
                    'Purchase',
                    'Created',
                    'Status',
                  ]}
                />
              </thead>
              <tbody className="divide-y divide-slate-200">
                {props.prescribedSequences.map((p) => (
                  <tr key={p.id}>
                    <td className="px-6 py-2 w-10">
                      <span className="inline-flex text-xs leading-5">
                        <Copyable text={p.id}>
                          {(copied) => (
                            <pre className="cursor-pointer">
                              {copied ? 'Copied' : p.id.slice(-6)}
                            </pre>
                          )}
                        </Copyable>
                      </span>
                    </td>
                    <td className="px-6 py-2 w-24">
                      <span className="inline-flex text-xs leading-5 text-slate-600 underline">
                        <Link
                          to={`${routes.consultations}/${p.consultation?.id}`}
                        >
                          {p.consultation?.id.slice(-6)}
                        </Link>
                      </span>
                    </td>
                    <td className="px-6 py-2">
                      <span className="inline-flex text-xs leading-5 text-slate-600 underline">
                        <Link
                          className="truncate w-72"
                          to={routes.sequence.replace(
                            ':sequenceId',
                            p.sequence?.id ?? '',
                          )}
                        >
                          {p.sequence?.internalName}
                        </Link>
                      </span>
                    </td>
                    <td className="px-6 py-2">
                      {p.sequenceContext ? (
                        <span className="inline-flex text-xs leading-5 text-slate-600 underline">
                          <Link
                            to={buildRoute.sequenceContext(
                              p.consultation?.customer?.id ?? '',
                              p.sequenceContext.purchase?.id ?? '',
                              p.id ?? '',
                            )}
                          >
                            {p.sequenceContext.purchase?.id.slice(-6)}
                          </Link>
                        </span>
                      ) : (
                        <span className="inline-flex whitespace-nowrap text-xs leading-5 text-slate-300">
                          None Linked
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-2 w-40">
                      <span
                        title={p.createdAt}
                        className="inline-flex whitespace-nowrap text-xs leading-5 cursor-pointer"
                      >
                        <Copyable text={p.createdAt}>
                          {(copied) =>
                            copied
                              ? 'Copied'
                              : formatDistanceToNow(new Date(p.createdAt), {
                                  addSuffix: true,
                                })
                          }
                        </Copyable>
                      </span>
                    </td>
                    <td className="px-6 py-2 w-40">
                      <Tag
                        size="small"
                        color={(() => {
                          switch (p.status) {
                            case 'DELETED':
                              return 'red';
                            case 'ACTIVATED':
                              return 'green';
                            case 'PENDING':
                              return 'gray';
                          }
                        })()}
                      >
                        <span className="capitalize">
                          {p.status.toLowerCase()}
                        </span>
                      </Tag>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
