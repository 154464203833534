import { gql } from '@apollo/client';
import { ReactElement } from 'react';
import { formatDateAndTime, upperSnakeCaseToCapitalCase } from 'utils/misc';
import { SmallTableHeadings } from 'components/small-table/small-table-headings';
import { Copyable } from 'components/copyable';
import { LorikeetAuditLogsFragment } from 'graphql/types';

export const LorikeetAuditLogTable = ({
  auditLogs,
}: {
  auditLogs?: LorikeetAuditLogsFragment['lorikeetAuditLogs'];
}): ReactElement => {
  return (
    <>
      <div className="flow-root px-4 py-5 border-b border-slate-200">
        <h3 className="float-left text-lg leading-6 font-medium text-slate-900">
          Lorikeet audit logs
          <span className="ml-2 text-slate-500">
            {auditLogs && auditLogs.length > 0 ? auditLogs.length : 0}
          </span>
        </h3>
      </div>
      <div>
        <AuditLogRows data={auditLogs || []} />
      </div>
    </>
  );
};

const AuditLogRows = ({
  data,
}: {
  data: NonNullable<LorikeetAuditLogsFragment['lorikeetAuditLogs']>;
}): ReactElement => {
  return (
    <div className="-mt-2 overflow-x-auto">
      <div className="pt-2 min-w-full">
        <table className="min-w-full divide-y divide-slate-200 text-xs">
          <thead>
            <SmallTableHeadings
              headings={[
                'Created',
                'Type',
                'Outcome',
                'Consultation ID',
                'Purchase ID',
              ]}
            />
          </thead>
          {data.length > 0 && (
            <tbody className="bg-white divide-y divide-slate-200">
              {data.map((auditLog) => (
                <tr key={auditLog.id}>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="flex items-center">
                      {auditLog.createdAt && (
                        <div className="leading-5 text-slate-900">
                          {formatDateAndTime(auditLog.createdAt)}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="leading-5 text-slate-900">
                      {upperSnakeCaseToCapitalCase(auditLog.action)}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="leading-5 text-slate-900">
                        {auditLog.outcome}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {auditLog.consultation && (
                      <Copyable text={auditLog.consultation.id}>
                        {(copied) => (
                          <pre className="cursor-pointer text-slate-500">
                            {copied
                              ? 'Copied'
                              : auditLog.consultation?.id.slice(-6)}
                          </pre>
                        )}
                      </Copyable>
                    )}
                  </td>
                  {auditLog.purchase && (
                    <td className="px-6 py-2 whitespace-nowrap">
                      <Copyable text={auditLog.purchase.id}>
                        {(copied) => (
                          <pre className="cursor-pointer text-slate-500">
                            {copied
                              ? 'Copied'
                              : auditLog.purchase?.id.slice(-6)}
                          </pre>
                        )}
                      </Copyable>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {data.length <= 0 && (
          <div className="flex justify-center w-full ">
            <div className="p-3 text-sm">No logs found</div>
          </div>
        )}
      </div>
    </div>
  );
};

LorikeetAuditLogTable.fragment = gql`
  fragment LorikeetAuditLogs on User {
    lorikeetAuditLogs {
      action
      createdAt
      id
      outcome
      consultation {
        id
        status
        createdAt
      }
      purchase {
        id
        status
        createdAt
      }
    }
  }
`;
