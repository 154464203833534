import React, { ReactNode } from 'react';
import {
  ContentItemStatus,
  UpdateItemMutation,
  UpdateItemMutationVariables,
  UpdateVideoScreenQuery,
  VideosPageQuery,
} from '../../../graphql/types';
import { useNotifications } from '../../../notifications';
import { useForm } from 'react-hook-form-6';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Checkbox } from '../../../components/checkbox';
import { Button } from '../../../components/button';
import { Dropdown } from '../../../components/dropdown';
import ReactPlayer from 'react-player';
import { ContentImage } from '../../../components/content-image';
import { Markdown } from '../../../components/markdown';

type UpdateItemProps = {
  id: string;
  contentfulId: string;
  tags: {
    id: string;
    description: string;
    name: string;
  }[];
  status: ContentItemStatus;
  title: string;
  onCancel: () => void;
  onUpdateSuccess: () => void;
  children?: ReactNode;
};

const UpdateItem: React.FC<UpdateItemProps> = ({
  children,
  id,
  contentfulId,
  status,
  tags,
  title,
  onCancel,
  onUpdateSuccess,
}) => {
  const [loading, setLoading] = React.useState(false);
  const showNotification = useNotifications();
  const { register, control, handleSubmit } = useForm<{
    tagIds: string[];
    published: boolean;
  }>({
    defaultValues: {
      tagIds: tags.map((t) => t.id) ?? [],
      published: status === 'PUBLISHED',
    },
  });

  const resp = useQuery<UpdateVideoScreenQuery>(gql`
    query UpdateVideoScreen {
      contentTags {
        id
        name
      }
    }
  `);

  const [updateItem] = useMutation<
    UpdateItemMutation,
    UpdateItemMutationVariables
  >(gql`
    mutation UpdateItem($input: UpdateContentItemInput!) {
      updateContentItem(input: $input) {
        item {
          id
          status
          tags {
            id
            name
          }
        }
      }
    }
  `);

  if (!id) {
    return <div>Could not show item</div>;
  }

  const onUpdateItem = handleSubmit(async ({ published, tagIds }) => {
    const newStatus = published ? 'PUBLISHED' : 'PRIVATE';

    try {
      setLoading(true);
      await updateItem({
        variables: {
          input: {
            id,
            status: newStatus,
            tagIds: tagIds ?? [],
          },
        },
      });
      showNotification({
        type: 'success',
        message: `Item status updated to ${newStatus}`,
      });
      onUpdateSuccess();
    } catch (e) {
      if (e instanceof Error) {
        showNotification({
          message: e.message,
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <div>
      <form onSubmit={onUpdateItem}>
        <div className="flex flex-col space-y-4">
          <h2 className="heading-md">{title}</h2>
          {children}
          <div>
            <Dropdown
              name="tagIds"
              label="Tags"
              control={control}
              isMulti
              options={
                resp.data?.contentTags?.map((t) => ({
                  label: t.name,
                  value: t.id,
                })) ?? []
              }
            />
          </div>
          <div className="flow-root align-baseline">
            <div className="float-left">
              <Checkbox name="published" ref={register} label="Published" />
            </div>
            <div className="float-right pt-4 text-sm">
              <span className="italic text-slate-600 pr-2">Contentful ID:</span>{' '}
              {contentfulId}
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <Button fullWidth variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              fullWidth
              color="success"
              loading={loading}
              disabled={loading}
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

type UpdateModalProps = {
  item: NonNullable<VideosPageQuery['contentItems']>[0] | undefined;
  onCancel: () => void;
  onUpdateSuccess: () => void;
};

export const UpdateItemModal: React.FC<UpdateModalProps> = ({
  item,
  onCancel,
  onUpdateSuccess,
}) => {
  switch (item?.__typename) {
    case 'VideoContentItem':
      return (
        <UpdateItem
          id={item.id}
          contentfulId={item.contentfulId}
          tags={item.tags}
          status={item.status}
          title={item.title}
          onCancel={onCancel}
          onUpdateSuccess={onUpdateSuccess}
        >
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 space-y-3">
              <div>
                <h3 className="font-medium">Description</h3>
                <p className="text-sm">{item.description}</p>
              </div>
              <ContentImage
                label="Thumbnail"
                imgUrl={item.thumbnailUrl}
                title={item.title}
              />
            </div>
            <div className="col-span-1">
              <ReactPlayer
                url={item.playbackUrl}
                controls
                width="100%"
                playing
                muted
              />
            </div>
          </div>
        </UpdateItem>
      );
    case 'VideoWithReadingContentItem':
      return (
        <UpdateItem
          id={item.id}
          contentfulId={item.contentfulId}
          tags={item.tags}
          status={item.status}
          title={item.title}
          onCancel={onCancel}
          onUpdateSuccess={onUpdateSuccess}
        >
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 space-y-3">
              <div>
                <h3 className="font-medium">Description</h3>
                <p className="text-sm">{item.description}</p>
              </div>
              <ContentImage
                label="Thumbnail"
                imgUrl={item.thumbnailUrl}
                title={item.title}
              />
            </div>
            <div className="col-span-1">
              <ReactPlayer
                url={item.playbackUrl}
                controls
                width="100%"
                playing
                muted
              />
            </div>
          </div>
        </UpdateItem>
      );
    case 'ReadingContentItem':
      return (
        <UpdateItem
          id={item.id}
          contentfulId={item.contentfulId}
          tags={item.tags}
          status={item.status}
          title={item.title}
          onCancel={onCancel}
          onUpdateSuccess={onUpdateSuccess}
        />
      );
    case 'FaqContentItem':
      return (
        <UpdateItem
          id={item.id}
          contentfulId={item.contentfulId}
          tags={item.tags}
          status={item.status}
          title={item.question}
          onCancel={onCancel}
          onUpdateSuccess={onUpdateSuccess}
        >
          <div className="overflow-y-scroll max-h-80">
            <Markdown src={item.answer} />
          </div>
        </UpdateItem>
      );
    case 'RecipeContentItem':
      return (
        <UpdateItem
          id={item.id}
          contentfulId={item.contentfulId}
          tags={item.tags}
          status={item.status}
          title={item.title}
          onCancel={onCancel}
          onUpdateSuccess={onUpdateSuccess}
        >
          <div className="grid gap-4 grid-cols-3">
            <div className="col-span-1 space-y-3">
              {item.recipeDescription && (
                <div className="space-y-1">
                  <h3 className="font-medium">Description</h3>
                  <p className="text-sm">{item.recipeDescription}</p>
                </div>
              )}
              <div className="space-y-2">
                <p>
                  <span className="font-medium">Serves:</span> {item.serves}
                </p>
                <p>
                  <span className="font-medium">Cooking time (mins):</span>{' '}
                  {item.cookingTimeMins}
                </p>
                {item.kiloCalories && (
                  <p>
                    <span className="font-medium">Kilocalories:</span>{' '}
                    {item.kiloCalories}
                  </p>
                )}
                <ContentImage
                  label="Hero"
                  imgUrl={item.heroUrl}
                  title={item.title}
                />
              </div>
            </div>
            <div className="col-span-1 space-y-3">
              <ContentImage
                label="Thumbnail"
                imgUrl={item.thumbnailUrl}
                title={item.title}
              />
            </div>

            <div className="col-span-1 space-y-3">
              <h3 className="font-medium">Video</h3>
              {item.recipeVideoPlaybackUrl ? (
                <ReactPlayer
                  url={item.recipeVideoPlaybackUrl}
                  controls
                  width="100%"
                  playing
                  muted
                />
              ) : (
                <p>No video</p>
              )}
            </div>
          </div>
        </UpdateItem>
      );
    default:
      return null;
  }
};
