import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { config } from 'config';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'utils/routes';
import {
  formatDateAndTime,
  upperSnakeCaseToCapitalCase,
  statusColorBackground,
  statusColorText,
  consultationStageTitle,
} from '../../../utils/misc';
import {
  CustomerConsultationView,
  ConsultDoctorCompletedStatus,
} from '../types';

export const ConsultationInfoRow = ({
  consult,
}: {
  consult: CustomerConsultationView;
}): ReactElement => {
  const getCompletedStatus = (): ConsultDoctorCompletedStatus => {
    if (consult.status === 'DOCTOR_COMPLETED') {
      return consult.isApproved
        ? ConsultDoctorCompletedStatus.Approved
        : ConsultDoctorCompletedStatus.Declined;
    }
    return ConsultDoctorCompletedStatus.Incomplete;
  };

  const completedStatus = getCompletedStatus();

  let prompt;
  switch (consult.purchasePrompt?.__typename) {
    case 'FurPurchasePrompt':
      prompt = 'FUR';
      break;
    case 'ConfirmPurchasePrompt':
      prompt = 'Confirm';
      break;
    case 'InitialPurchasePrompt':
      prompt = 'Initial';
      break;
    case 'SubstitutePurchasePrompt':
      prompt = 'Substitute';
      break;
    case 'RestartFlowPrompt':
      prompt = 'Reset';
      break;
    case undefined:
      prompt = '—';
      break;
    default:
      prompt = 'Unknown';
  }

  return (
    <tr key={consult.id}>
      <td className="px-6 py-2 text-xs inline-flex leading-5 text-slate-500">
        <Link to={`${routes.consultations}/${consult.id}`}>
          <pre>{consult.id.slice(-6)}</pre>
        </Link>
        <CopyToClipboardButton value={consult.id} />
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColorBackground(
            consult.status,
            completedStatus,
          )} ${statusColorText(consult.status)}`}
        >
          {consult.status === 'DOCTOR_COMPLETED'
            ? completedStatus
            : upperSnakeCaseToCapitalCase(consult.status)}
        </span>
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <span className="text-xs leading-5">
          {consultationStageTitle(consult.stage, consult.reviewReason)}
        </span>
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <span className="inline-flex text-xs leading-5">{prompt}</span>
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <span className="text-xs leading-5">
          {formatDateAndTime(consult.createdAt)}
        </span>
      </td>
      {config.syncConsultationsEnabled && (
        <td className="px-6 py-2 whitespace-nowrap">
          <span className="text-xs leading-5">
            {consult.callRecorded ? 'Yes' : 'No'}
          </span>
        </td>
      )}
      <td className="px-6 py-2 whitespace-nowrap">
        <span className="text-xs leading-5">
          {consult.completed ? formatDateAndTime(consult.completed) : '—'}
        </span>
      </td>
      <td className="px-6 py-2 whitespace-nowrap">
        <span className="text-xs leading-5">{consult.doctor ?? '—'}</span>
      </td>
    </tr>
  );
};
