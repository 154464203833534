import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Customer, CustomerTreatmentView } from '../types';
import { ActionButton } from '../../../components/actionButton';
import { ModalButton } from '../../../components/modalButton';
import { RescheduleModal } from './rescheduleModal';
import { PauseModal } from './pauseModal';
import {
  refillTreatmentDocument,
  pauseTreatmentDocument,
  unpauseTreatmentDocument,
  revertReviewTreatmentDocument,
} from './graphql-documents';
import { CancelTreatmentButton } from 'components/cancel-treatment-button';
import { RequestReviewButton } from 'components/request-review/button';

type TreatmentActionButtonConfig =
  | {
      type: 'ACTION';
      payload: ActionButton;
    }
  | {
      type: 'MODAL';
      payload: ModalButton;
    }
  | {
      type: 'CUSTOM';
      payload: {
        component: React.ReactElement;
      };
    };

const renderButton = (
  config: TreatmentActionButtonConfig,
): React.ReactElement => {
  switch (config.type) {
    case 'ACTION':
      return <ActionButton key={config.payload.label} {...config.payload} />;
    case 'MODAL':
      return (
        <ModalButton key={config.payload.label} {...config.payload}>
          {config.payload.modal}
        </ModalButton>
      );
    case 'CUSTOM':
      return config.payload.component;
  }
};

export const TreatmentActions = ({
  customer,
  treatment,
  refetchCustomer,
}: {
  customer: Customer;
  treatment: CustomerTreatmentView;
  refetchCustomer: () => void;
}): React.ReactElement => {
  const featureFlagClient = useFeatureFlagClient();
  const [rescheduleActive, setRescheduleActive] = useState<boolean>(false);
  const [pauseActive, setPauseActive] = useState<boolean>(false);
  const { customerId } = useParams<{ customerId: string }>();
  const enablePauseReason = featureFlagClient.getBoolean('admin_pause_reason');
  const buttons: TreatmentActionButtonConfig[] = [
    {
      type: 'ACTION',
      payload: {
        label: '⚡️ Refill Now',
        disabled: !treatment.canRefill,
        mutationArgs: { treatmentId: treatment.id },
        refetchCustomer,
        confirmMsg:
          'Are you sure you want to refill this treatment immediately?',
        successMsg: 'Refill successfully triggered',
        mutationGql: refillTreatmentDocument,
      },
    },
    {
      type: 'ACTION',
      payload: {
        label: '💲 Free Refill',
        disabled: !treatment.canRefill,
        mutationArgs: { treatmentId: treatment.id, free: true },
        mutationGql: refillTreatmentDocument,
        refetchCustomer,
        confirmMsg:
          'Are you sure you want to refill this treatment immediately for free?',
        successMsg: 'Free refill successfully triggered',
      },
    },
    enablePauseReason
      ? {
          type: 'MODAL',
          payload: {
            label: '⏸ Pause',
            disabled: !treatment.canPause,
            toggleModal: (): void => setPauseActive(true),
            modal: (
              <PauseModal
                pauseActive={pauseActive}
                setPauseActive={setPauseActive}
                refetchCustomer={refetchCustomer}
                treatment={treatment}
              />
            ),
          },
        }
      : {
          type: 'ACTION',
          payload: {
            label: '⏸ Pause',
            disabled: !treatment.canPause,
            mutationArgs: { id: treatment.id },
            mutationGql: pauseTreatmentDocument,
            refetchCustomer,
            confirmMsg: 'Are you sure you want to pause this treatment?',
            successMsg: 'Treatment successfully paused',
          },
        },
    {
      type: 'CUSTOM',
      payload: {
        component: (
          <CancelTreatmentButton
            status={treatment.status}
            treatmentId={treatment.id}
            refetchCustomer={refetchCustomer}
            key="cancelTreatment"
          />
        ),
      },
    },
    {
      type: 'ACTION',
      payload: {
        label: `▶ Unpause`,
        disabled: treatment.status !== 'PAUSED',
        mutationArgs: { id: treatment.id },
        mutationGql: unpauseTreatmentDocument,
        refetchCustomer,
        confirmMsg: 'Are you sure you want to unpause this treatment?',
        successMsg: 'Treatment successfully unpaused',
      },
    },
    {
      type: 'CUSTOM',
      payload: {
        component: (
          <RequestReviewButton
            customer={customer}
            refetchCustomer={refetchCustomer}
            disabled={!treatment.canReview}
            key="requestReview"
            consultationId={treatment.consultation?.id ?? ''}
            customerId={customerId}
            problemType={treatment.type}
            treatmentId={treatment.id}
          />
        ),
      },
    },
    {
      type: 'ACTION',
      payload: {
        label: '↩️ Revert Review',
        disabled: !treatment.canRevertReview,
        mutationArgs: { treatmentId: treatment.id, type: treatment.type },
        mutationGql: revertReviewTreatmentDocument,
        refetchCustomer,
        confirmMsg: 'Are you sure you want to review the review?',
        successMsg: 'Successfully requested review',
      },
    },
    {
      type: 'MODAL',
      payload: {
        label: '📅 Reschedule',
        disabled: !treatment.canReschedule,
        toggleModal: (): void => {
          setRescheduleActive(!rescheduleActive);
        },
        modal: (
          <RescheduleModal
            rescheduleActive={rescheduleActive}
            setRescheduleActive={setRescheduleActive}
            refetchCustomer={refetchCustomer}
            treatment={treatment}
          />
        ),
      },
    },
  ];

  return (
    <div className="flex justify-end gap-2 p-2 border-t border-slate-200">
      {buttons.map(renderButton)}
    </div>
  );
};
