import { Modal } from 'components/modal';
import { UpsertNoteForm } from '../upsert-note-form';
import { NoteRowFragment } from 'graphql/types';

export type ArchiveNoteModalProps = {
  note: NoteRowFragment;
  show: boolean;
  onClose: () => void;
};

export const ArchiveNoteModal: React.FC<ArchiveNoteModalProps> = ({
  note,
  show,
  onClose,
}) => {
  return (
    <Modal show={show} onClose={onClose} width="w-2/3">
      <section className="bg-slate-200">
        <header className="border-b border-slate-500 py-3 px-5 bg-slate-300">
          <h2 className="text-lg font-semibold mb-2">Archive Note</h2>
        </header>
        <div className="space-y-3 px-5 py-3">
          <p className="text-sm">
            This will mark the note as “archived” so other users can disregard
            it.
          </p>

          <UpsertNoteForm
            title="Archival Note"
            onCompleted={onClose}
            noteInput={{
              targetType: 'NOTE',
              targetId: note.id,
              domain: 'NOTE_ARCHIVAL',
            }}
            allowPreview={false}
          />
        </div>
      </section>
    </Modal>
  );
};
