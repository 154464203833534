import { NoteRowFragment } from 'graphql/types';
import { Link } from 'react-router-dom';
import { routes } from 'utils/routes';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { AuthorTypeTag } from 'components/tags/author-type-tag';
import { NoteView } from './note-view';
import { AddAddendumModalButton } from './add-addendum/add-addendum-modal-button';
import { useMemo } from 'react';
import { EditNoteModalButton } from './edit-note/edit-note-modal-button';
import { ArchiveReason } from './archive/archive-reason';
import { ArchiveNoteModalButton } from './archive/archive-note-modal-button';
import { LegacyReason } from './legacy/legacy-reason';
import { upperSnakeCaseToCapitalCase } from 'utils/misc';

const classNames = {
  heading:
    'text-xs leading-4 font-medium text-slate-500 uppercase tracking-wider',
};

export const NoteRowBody: React.FC<{
  note: NoteRowFragment;
}> = ({ note }) => {
  const consultation = useMemo(
    () => ('consultation' in note ? note.consultation : null),
    [note],
  );

  const canAddAddendum = 'canAddAddendum' in note && note.canAddAddendum;
  const canEdit = note.canEdit;
  const canArchive = note.canArchive;

  const showActions = canAddAddendum || canEdit || canArchive;

  return (
    <div className="flex flex-col">
      <ArchiveReason note={note} />
      <LegacyReason note={note} />
      <div className="px-6 py-4 text-sm flex space-x-4 text-slate-900">
        <div className="flex-grow space-y-4 pr-4 border-r border-slate-500">
          <NoteView note={note} />
        </div>
        <div className="space-y-2 w-52">
          <div className={classNames.heading}>Author Role</div>
          <div>
            <AuthorTypeTag author={note.author} />
          </div>
          <div className={classNames.heading}>Problem Type</div>
          <div>
            <ProblemTypeTag problemType={consultation?.type} />
          </div>
          <div className={classNames.heading}>Consultation</div>
          <div className="font-medium">
            {consultation
              ? upperSnakeCaseToCapitalCase(consultation.stage)
              : '-'}
          </div>
          <div className={classNames.heading}>Consultation Link</div>
          <div className="text-medium text-slate-900 flex">
            {consultation ? (
              <>
                <span className="underline">
                  <Link to={`${routes.consultations}/${consultation.id}`}>
                    {consultation.id}
                  </Link>
                </span>
                <CopyToClipboardButton value={consultation.id} />
              </>
            ) : (
              '-'
            )}
          </div>
          <div className={classNames.heading}>Actions</div>
          {!showActions && '-'}
          {canEdit && <EditNoteModalButton note={note} />}
          {canAddAddendum && <AddAddendumModalButton note={note} />}
          {canArchive && <ArchiveNoteModalButton note={note} />}
        </div>
      </div>
    </div>
  );
};
