import { gql, useMutation, useQuery } from '@apollo/client';
import { ReactElement, useState } from 'react';
import {
  DeliveryPageQuery,
  DeliveryPageQueryVariables,
  UpdateColdShippingProviderMutation,
  UpdateColdShippingProviderMutationVariables,
} from 'graphql/types';
import { Loading } from 'components/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { CellProps, Column, useTable } from 'react-table';
import { Tag } from '../../components/tag';
import { useNotifications } from '../../notifications';
import { useForm } from 'react-hook-form-6';
import { Modal } from '../../components/modal';
import { Button } from '../../components/button';
import { useHasPermissions } from '../../components/permissions';
import { Checkbox } from '../../components/checkbox';
import { FaPencilAlt } from 'react-icons/fa';
import { Dropdown, Option } from '../../components/dropdown';

const deliveryPageQuery = gql`
  query DeliveryPage {
    coldShippingProviders {
      id
      name
      enabled
      ... on AuColdShippingProvider {
        includedStates
      }
    }
  }
`;

type DeliveryProviderTableData = {
  id: string;
  name: string;
  enabled: boolean;
  includedStates: string[];
};

const DisableDeliveryProviderModal = (props: {
  providerName: string;
  showModal: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  isSubmitting: boolean;
}): ReactElement => {
  return (
    <Modal
      show={props.showModal}
      onClose={props.onClose}
      isAutoOverflow={false}
      width="max-w-screen-sm"
    >
      <form className="bg-slate-200 p-8 px-6" onSubmit={props.onConfirm}>
        <div className="space-y-2 mb-4">
          <h3 className="text-lg font-semibold">Disable delivery provider</h3>
          <p className="text-base">
            You are about to disable the following provider:
          </p>
        </div>
        <ul className="mb-4 list-disc pl-5 text-base">
          <li>{props.providerName}</li>
        </ul>
        <p className="mb-8 text-base">
          Patient addresses will not be validated against this providers
          delivery range
        </p>
        <div className="flex space-x-5">
          <Button
            variant="outline"
            color="danger"
            fullWidth
            onClick={props.onClose}
            disabled={props.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            color="success"
            fullWidth
            onClick={props.onConfirm}
            loading={props.isSubmitting}
            disabled={props.isLoading}
          >
            Disable provider
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const DeliveryProviderEnabledColumn = (
  cell: CellProps<
    DeliveryProviderTableData,
    DeliveryProviderTableData['enabled']
  >,
) => {
  const canEditDeliveryProviders = useHasPermissions([
    'UPDATE_COLD_SHIPPING_PROVIDER',
  ]);
  const showNotification = useNotifications();
  const [showDisableModal, setShowDisableModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<{ enabled: boolean }>({
    defaultValues: {
      enabled: cell.value,
    },
  });
  const [updateProvider, { loading }] = useMutation<
    UpdateColdShippingProviderMutation,
    UpdateColdShippingProviderMutationVariables
  >(gql`
    mutation UpdateColdShippingProvider(
      $input: UpdateColdShippingProviderInput!
    ) {
      updateColdShippingProvider(input: $input) {
        coldShippingProvider {
          id
          enabled
          ... on AuColdShippingProvider {
            includedStates
          }
        }
      }
    }
  `);

  const handleUpdateProvider = async (enabled: boolean) => {
    const resp = await updateProvider({
      variables: {
        input: {
          id: cell.row.original.id,
          enabled,
          includedStates: cell.row.original.includedStates,
        },
      },
    });

    if (
      resp.data?.updateColdShippingProvider?.coldShippingProvider.enabled !==
      cell.row.original.enabled
    ) {
      showNotification({
        message: `Provider ${cell.row.original.name} ${
          enabled ? 'enabled' : 'disabled'
        }`,
        type: 'success',
      });
    }

    if (showDisableModal) {
      setShowDisableModal(false);
    }
  };

  const onFormChange = handleSubmit(async (formData) => {
    if (!formData.enabled) {
      setShowDisableModal(true);
    } else {
      await handleUpdateProvider(true);
      control.setValue('enabled', true);
    }
  });

  return (
    <>
      <form className="flex space-x-4 items-center" onChange={onFormChange}>
        <Checkbox
          name="enabled"
          ref={register}
          label=""
          disabled={!canEditDeliveryProviders}
        />
        <Tag size="small" color={cell.value ? 'green' : 'red'}>
          <span className="capitalize">
            {cell.value ? 'enabled' : 'disabled'}
          </span>
        </Tag>
      </form>
      <DisableDeliveryProviderModal
        onConfirm={async () => {
          control.setValue('enabled', false);
          await handleUpdateProvider(false);
        }}
        showModal={showDisableModal}
        onClose={() => {
          setShowDisableModal(false);
          reset({
            enabled: true,
          });
        }}
        isLoading={loading}
        providerName={cell.row.original.name}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const servicedStates: (Option & { value: string })[] = [
  { label: 'NSW', value: 'NSW' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' },
  { label: 'ACT', value: 'ACT' },
  { label: 'NT', value: 'NT' },
];

const DeliveryProviderIncludedStatesColumn = (
  cell: CellProps<
    DeliveryProviderTableData,
    DeliveryProviderTableData['includedStates']
  >,
) => {
  const canEditDeliveryProviders = useHasPermissions([
    'UPDATE_COLD_SHIPPING_PROVIDER',
  ]);
  const showNotification = useNotifications();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { handleSubmit, control } = useForm<{ includedStates: string[] }>({
    defaultValues: {
      includedStates: cell.value,
    },
  });
  const [updateProvider, { loading }] = useMutation<
    UpdateColdShippingProviderMutation,
    UpdateColdShippingProviderMutationVariables
  >(gql`
    mutation UpdateColdShippingProvider(
      $input: UpdateColdShippingProviderInput!
    ) {
      updateColdShippingProvider(input: $input) {
        coldShippingProvider {
          id
          enabled
          ... on AuColdShippingProvider {
            includedStates
          }
        }
      }
    }
  `);
  const handleUpdateProvider = handleSubmit(async (formData) => {
    const resp = await updateProvider({
      variables: {
        input: {
          id: cell.row.original.id,
          enabled: cell.row.original.enabled,
          includedStates: formData.includedStates,
        },
      },
    });

    if (
      resp.data?.updateColdShippingProvider?.coldShippingProvider
        .includedStates !== cell.value
    ) {
      showNotification({
        message: `Delivery provider included states updated`,
        type: 'success',
      });
    }

    if (showUpdateModal) {
      setShowUpdateModal(false);
    }
  });

  return (
    <>
      <div
        className="flex items-center gap-2"
        onClick={() =>
          canEditDeliveryProviders ? setShowUpdateModal(true) : null
        }
      >
        {canEditDeliveryProviders && (
          <FaPencilAlt className="text-zinc-600" size={14} />
        )}
        <div className="mt-1">
          {cell.value?.map((includedState, i) => (
            <div
              key={`${i}_${includedState}`}
              className="inline-block mr-1 mb-1"
            >
              <Tag size="small">{includedState}</Tag>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        isAutoOverflow={false}
        width="max-w-screen-sm"
      >
        <form className="bg-slate-200 p-8 px-6 space-y-8">
          <div className="flex flex-col space-y-4">
            <div className="space-y-2">
              <h3 className="text-lg font-semibold">Update included states</h3>
              <p className="text-base">
                For delivery provider {cell.row.original.id}
              </p>
              <p className="text-base">
                This enables and disables the states that this delivery provider
                can deliver to
              </p>
            </div>
            <Dropdown
              name="includedStates"
              label="States"
              control={control}
              isMulti
              options={servicedStates}
              closeMenuOnSelect={false}
            />
          </div>
          <div className="flex space-x-5">
            <Button
              variant="outline"
              color="danger"
              fullWidth
              onClick={() => setShowUpdateModal(false)}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              color="success"
              fullWidth
              onClick={handleUpdateProvider}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const columns: Column<DeliveryProviderTableData>[] = [
  {
    Header: 'Enabled',
    accessor: 'enabled',
    Cell: DeliveryProviderEnabledColumn,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (c) => {
      return <div className="flex items-center gap-2">{c.value}</div>;
    },
  },
  {
    Header: 'States',
    accessor: 'includedStates',
    Cell: DeliveryProviderIncludedStatesColumn,
  },
];

const DeliveryProviders = (): ReactElement => {
  const { data, loading, error } = useQuery<
    DeliveryPageQuery,
    DeliveryPageQueryVariables
  >(deliveryPageQuery);

  const table = useTable({
    columns,
    data: data?.coldShippingProviders ?? [],
  });

  if (loading) {
    return <Loading />;
  }

  if (!data || error) {
    return (
      <div>
        Unable to load delivery providers, please contact #help-technology.
      </div>
    );
  }

  return (
    <section className="space-y-6">
      <div className="flex justify-between items-end space-x-5">
        <div className="flex flex-col space-y-3 text-slate-700">
          <p>
            This page shows the available Delivery Providers to ship the product
          </p>
        </div>
      </div>
      <Table tableInstance={table}>
        <TableHead />
        <TableBody>
          {table.rows.map((row) => {
            table.prepareRow(row);
            return (
              <TableRow key={row.id} row={row}>
                {row.cells.map((cell) => (
                  <TableCell
                    key={`${cell.column.id}-${cell.row.original.id}`}
                    cell={cell}
                  />
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
};

export default DeliveryProviders;
