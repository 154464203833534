import { Modal } from 'components/modal';
import { ConsultationStatus } from 'graphql/types';
import { ReactElement, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { upperSnakeCaseToCapitalCase } from 'utils/misc';
import { ConsultationStatusSelectList } from './consultation-status-select-list';
import { consultationStatusDescriptions } from './consultation-status-descriptions';

export const ConsultationStatusSelection = ({
  status,
  disabled,
  consultationId,
  onUpdate,
}: {
  consultationId: string;
  status: ConsultationStatus;
  disabled: boolean;
  onUpdate: () => void;
}): ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="bg-white flex p-4 shadow mb-4 space-x-2 text-left hover:bg-slate-100"
        onClick={(): void => setOpen((open) => !open)}
        disabled={disabled}
      >
        <div className="space-y-1">
          <div className="font-medium">
            {upperSnakeCaseToCapitalCase(status)}
          </div>
          <div className="text-slate-700">
            {consultationStatusDescriptions[status]}
          </div>
        </div>
        {!disabled && (
          <FaChevronDown className="my-auto text-slate-700" size={20} />
        )}
      </button>
      <Modal show={open} onClose={(): void => setOpen(false)}>
        <ConsultationStatusSelectList
          status={status}
          consultationId={consultationId}
          onUpdate={(): void => {
            setOpen(false);
            onUpdate();
          }}
        />
      </Modal>
    </>
  );
};
