import React from 'react';
import clsx from 'clsx';
import { FaClipboard, FaClipboardCheck } from 'react-icons/fa';
import { sleep } from 'utils/misc';
import { logger } from 'logging';

const NOTIFICATION_DURATION_MS = 5000;

type NotificationType = 'warning' | 'error' | 'success' | 'info';

type ShowNotification = (props: NotificationProps) => void;

interface NotificationProps {
  message: string;
  type: NotificationType;
}

const NotificationsContext = React.createContext<ShowNotification>(() =>
  logger.warn('No NotificationsProvider found as ancestor.'),
);

const typeToColor: Record<NotificationType, string> = {
  warning: 'bg-yellow-600',
  error: 'bg-red-600',
  success: 'bg-green-600',
  info: 'bg-slate-600',
};

export const useNotifications = (): ShowNotification =>
  React.useContext(NotificationsContext);

export const NotificationsProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [notifications, setNotifications] = React.useState<{
    [message: string]: NotificationProps;
  }>({});

  const showNotification = React.useCallback(
    (notification: NotificationProps): void => {
      setNotifications((notifications) => {
        if (!notifications[notification.message]) {
          setTimeout(
            () =>
              setNotifications(
                ({ [notification.message]: deleted, ...newNotifications }) =>
                  newNotifications,
              ),
            NOTIFICATION_DURATION_MS,
          );
          return {
            ...notifications,
            [notification.message]: notification,
          };
        }

        return notifications;
      });
    },
    [],
  );

  const [showCopiedFeedback, setShowCopiedFeedback] = React.useState(false);
  const createCopyHandler = (message: string) => async (): Promise<void> => {
    setShowCopiedFeedback(true);
    await navigator.clipboard.writeText(message);
    await sleep(1000);
    setShowCopiedFeedback(false);
  };

  return (
    <NotificationsContext.Provider value={showNotification}>
      {children}
      <div className="fixed w-full flex flex-col justify-center items-center bottom-0 left-0 z-50">
        {Object.entries(notifications).map(([message, { type }]) => (
          <div
            key={message}
            className={clsx(
              'border',
              'border-slate-100',
              'border-solid',
              'bg-white',
              'flex',
              'items-center',
              'leading-none',
              'rounded-full',
              'max-w-xl',
              'mb-4',
              'p-2',
              'shadow-md',
              'text-center',
            )}
            role="alert"
          >
            <span
              className={`flex font-bold rounded-full ${typeToColor[type]} uppercase px-2 py-1 text-white text-xs font-medium mr-3`}
            >
              {type}
            </span>
            <span className="text-slate-800 font-medium mr-3 text-left text-sm flex-auto">
              {message}
            </span>
            <span className="text-slate-500 cursor-pointer hover:text-slate-800">
              {showCopiedFeedback ? (
                <FaClipboardCheck />
              ) : (
                <FaClipboard onClick={createCopyHandler(message)} />
              )}
            </span>
          </div>
        ))}
      </div>
    </NotificationsContext.Provider>
  );
};
