export const cardHeading = {
  'text-lg': true,
  'leading-6': true,
  'font-medium': true,
  'text-slate-900 ': true,
  // spacing/containing styles
  'px-4': true,
  'py-5': true,
  'first:border-t-0': true,
  'last:border-b-0': true,
  'border-t': true,
  'border-b': true,
  'border-slate-200': true,
} as const;
