import { gql, useMutation } from '@apollo/client';
import React, { useRef } from 'react';
import { Button } from '../../../components/button';
import { Modal } from '../../../components/modal';
import { useNotifications } from '../../../notifications';
import { formatYMD } from '../../../utils/misc';
import { CustomerTreatmentView } from '../types';

const rescheduleTreatmentQuery = gql`
  mutation rescheduleTreatment($treatmentId: String!, $newDate: String!) {
    rescheduleTreatment(treatmentId: $treatmentId, newDate: $newDate) {
      id
    }
  }
`;

export const RescheduleModal = ({
  rescheduleActive,
  setRescheduleActive,
  refetchCustomer,
  treatment,
}: {
  rescheduleActive: boolean;
  setRescheduleActive: (active: boolean) => void;
  refetchCustomer: () => void;
  treatment: CustomerTreatmentView;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const nextPaymentDateInput = useRef<HTMLInputElement>(null);
  const [rescheduleMutation] = useMutation(rescheduleTreatmentQuery);

  const handleReschedule = async (
    treatmentId: string,
    date: string,
  ): Promise<void> => {
    await rescheduleMutation({
      variables: {
        treatmentId,
        newDate: date,
      },
    });
    showNotification({
      type: 'success',
      message: 'Treatment rescheduled',
    });
    refetchCustomer();
  };

  return (
    <Modal
      show={rescheduleActive}
      onClose={(): void => setRescheduleActive(false)}
    >
      <div
        className="bg-white shadow overflow-hidden rounded px-4 py-5 max-w-lg mr-auto ml-auto"
        onClick={(
          event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ): void => {
          event.stopPropagation();
        }}
      >
        <h3 className="text-lg leading-6 font-medium text-slate-900 mb-8">
          Reschedule next payment
        </h3>
        <input
          type="date"
          name="nextPaymentDate"
          id="nextPaymentDate"
          className="mb-8 text-lg border border-slate-400 hover:border-slate-500 rounded p-2"
          ref={nextPaymentDateInput}
          min={formatYMD(new Date())}
          defaultValue={
            treatment.nextPaymentDue
              ? formatYMD(treatment.nextPaymentDue)
              : undefined
          }
        />
        <div className="flex bg-slate-100">
          <Button
            fullWidth
            variant="outline"
            onClick={(): void => setRescheduleActive(false)}
          >
            Cancel
          </Button>
          <div className="mx-2" />
          <Button
            fullWidth
            color="success"
            onClick={async (): Promise<void> => {
              const newDate = nextPaymentDateInput.current?.value;
              if (!newDate) {
                throw new Error(
                  'unexpected invalid value from payment date input',
                );
              }
              await handleReschedule(treatment.id, newDate);
              setRescheduleActive(false);
            }}
          >
            Confirm Reschedule
          </Button>
        </div>
      </div>
    </Modal>
  );
};
