import { gql, useSuspenseQuery } from '@apollo/client';
import { NotesTable, noteRowFragment } from 'components/notes/notes-table';
import { Link, useParams } from 'react-router-dom';
import { NotesPageQuery, NotesPageQueryVariables } from 'graphql/types';
import { Card } from 'components/card';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { buildRoute } from 'utils/routes';
import { CardField } from 'pages/order/card-field';
import { AddNoteModalButton } from 'components/notes/add-note/add-note-modal-button';
import { addNoteModalConsultationFragment } from 'components/notes/add-note/add-note-modal';
import {
  CriticalNoteCard,
  customerCriticalNoteDocument,
} from 'components/notes/critical-note/critical-note-card';

const classNames = {
  heading: 'px-4 py-5 text-lg leading-6 font-medium text-slate-900',
};

const Notes: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();

  const { data } = useSuspenseQuery<NotesPageQuery, NotesPageQueryVariables>(
    gql`
      query NotesPage($customerId: ID!, $customerIdString: String!) {
        customer: user(where: { id: $customerIdString }) {
          id
          email
          fullName
          consultations {
            ...AddNoteModalConsultation
          }
          ...CustomerCriticalNote
        }
        filteredNotes(input: { customerId: $customerId }) {
          ...noteRow
        }
      }

      ${noteRowFragment}
      ${addNoteModalConsultationFragment}
      ${customerCriticalNoteDocument}
    `,
    {
      variables: {
        customerId,
        customerIdString: customerId,
      },
    },
  );

  if (!data.customer) {
    throw new Error('unable to load customer');
  }

  const mostRecentConsultation = data.customer.consultations.at(-1);
  const noteCount =
    (data.filteredNotes?.length ?? 0) + (data.customer.criticalNote ? 1 : 0);

  return (
    <div className="space-y-4">
      <Card>
        <h2 className={classNames.heading}>Patient Information</h2>

        <div>
          <CardField label="Patient ID">
            <span className="underline">
              <Link to={buildRoute.customer(customerId)}>{customerId}</Link>
            </span>
            <CopyToClipboardButton value={customerId} />
          </CardField>
          <CardField label="Name">{data.customer.fullName}</CardField>
          <CardField label="Email">{data.customer.email}</CardField>
        </div>
      </Card>

      <CriticalNoteCard customer={data.customer} />

      <Card>
        <div className="px-4 py-5 flex space-x-4">
          <h3 className="flex-grow text-lg leading-6 font-medium">
            Notes <span className="ml-2 text-slate-500">{noteCount}</span>
          </h3>

          <AddNoteModalButton
            customer={data.customer}
            consultations={data.customer.consultations}
            defaultValues={{
              consultation: mostRecentConsultation,
              canEditTarget: true,
            }}
          />
        </div>
        <NotesTable notes={data.filteredNotes ?? []} />
      </Card>
    </div>
  );
};

export default Notes;
