import React from 'react';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';
import { useNotifications } from 'notifications';
import clsx from 'clsx';

interface Props {
  value: string;
  variant?: 'paperclip-emoji' | 'svg-icon';
}

const SvgIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4463_28544)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.733674 0.0754251C0.635498 0.115809 0.503274 0.188449 0.43985 0.236801C0.283498 0.356065 0.163146 0.520129 0.0752096 0.733889L0.00173758 0.912449L0.00100158 5.69274C0.000425585 9.27478 0.00970559 10.506 0.0380256 10.6047C0.0890976 10.7827 0.267946 11.051 0.411018 11.1643C0.762826 11.4429 0.923978 11.4639 2.6098 11.4507C3.79134 11.4415 3.93044 11.4349 4.02395 11.3838C4.16964 11.3042 4.22775 11.1996 4.24516 10.9856C4.26254 10.7723 4.20206 10.6234 4.05393 10.5147C3.96622 10.4503 3.92219 10.4482 2.51969 10.4392C1.54334 10.4329 1.06222 10.4187 1.03399 10.3953C0.975146 10.3464 0.971818 1.08967 1.03063 1.03085C1.08945 0.972033 10.3462 0.975361 10.3951 1.03421C10.4185 1.06243 10.4327 1.54355 10.439 2.51991C10.448 3.9224 10.4501 3.96643 10.5145 4.05414C10.6232 4.20227 10.7721 4.26275 10.9854 4.24538C11.1994 4.22797 11.3039 4.16986 11.3836 4.02416C11.4355 3.92918 11.4402 3.79658 11.4402 2.44845C11.4402 0.827105 11.4393 0.818497 11.2422 0.521473C11.1051 0.314849 10.9324 0.174785 10.6939 0.0767051L10.5122 0.00195312H0.912234L0.733674 0.0754251ZM5.5555 4.57978C5.29988 4.61709 5.10372 4.71779 4.91067 4.91088C4.71057 5.11095 4.61972 5.29485 4.57787 5.58448C4.53393 5.88848 4.53367 14.6866 4.57758 14.9904C4.59521 15.1124 4.64174 15.277 4.68094 15.356C4.78337 15.5626 5.02583 15.7978 5.23959 15.8979L5.42423 15.9844H15.1522L15.3283 15.9019C15.5533 15.7963 15.7961 15.5535 15.9016 15.3285L15.9842 15.1524V5.42445L15.8977 5.23981C15.7975 5.02605 15.5623 4.78358 15.3558 4.68115C15.2767 4.64195 15.1122 4.59543 14.9902 4.57779C14.7147 4.53799 5.82913 4.53987 5.5555 4.57978ZM5.60663 5.60685C5.55083 5.66266 5.55083 14.9142 5.60663 14.97C5.66244 15.0259 14.914 15.0259 14.9698 14.97C15.0256 14.9142 15.0256 5.66266 14.9698 5.60685C14.914 5.55104 5.66244 5.55104 5.60663 5.60685Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4463_28544">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CopyToClipboardButton = ({
  value,
  variant = 'paperclip-emoji',
}: Props): React.ReactElement => {
  const [, copyToClipboard] = useCopyToClipboard();
  const showNotification = useNotifications();

  const copyValueToClipboard = (): void => {
    copyToClipboard(value);

    showNotification({
      type: 'success',
      message: `"${value}" copied to clipboard`,
    });
  };

  return (
    <button
      type="button"
      className={clsx(
        'inline-flex text-sm leading-5 font-medium text-slate-700 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white',
        variant === 'paperclip-emoji' && 'px-2',
      )}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        copyValueToClipboard();
      }}
    >
      {variant === 'paperclip-emoji' ? '📎' : <SvgIcon />}
    </button>
  );
};
