import { ProblemType } from 'graphql/types';
import { SmallTableHeadings } from 'components/small-table/small-table-headings';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { ReactElement } from 'react';
import { Customer, CustomerConsultationView } from '../types';
import { ConsultationActions } from './consultation-actions/consultationActions';
import { ConsultationInfoRow } from './consultation-info-row';
import { config } from 'config';

export const ConsultationPanel = ({
  consultations,
  customer,
  problemType,
  refetchCustomer,
}: {
  consultations: CustomerConsultationView[];
  customer: Customer;
  problemType: ProblemType;
  refetchCustomer: () => void;
}): ReactElement => {
  const headings = config.syncConsultationsEnabled
    ? [
        'ID',
        'Status',
        'Type',
        'Prompt',
        'Created',
        'Call Recorded',
        'Completed',
        'Practitioner',
      ]
    : [
        'ID',
        'Status',
        'Type',
        'Prompt',
        'Created',
        'Completed',
        'Practitioner',
      ];

  const featureFlagClient = useFeatureFlagClient();
  const ffAsyncConsultsEnabled = featureFlagClient.getBoolean(
    'ff-async-consults-enabled',
  );

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const hasHadCall = consultations.some((c) => c.callRecorded);
  const hasHadCallInThePastYear =
    customer.conditions
      ?.filter((c) => c.problemType === problemType)
      .some(
        (c) =>
          c.phoneCalls?.some(
            (p) => p.answeredAt && new Date(p.answeredAt) > oneYearAgo,
          ),
      ) ?? false;

  return (
    <div className="overflow-hidden rounded shadow bg-white">
      <div className="px-4 py-5 border-b border-slate-200 flex items-center justify-between">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Consultations
          <span className="ml-2 text-slate-500">{consultations.length}</span>
        </h3>
        {config.syncConsultationsEnabled &&
          (ffAsyncConsultsEnabled ? (
            <p className="text-sm leading-6 font-medium text-slate-900">
              Patient
              {hasHadCallInThePastYear && (
                <span className="text-green-500"> has </span>
              )}
              {!hasHadCallInThePastYear && (
                <span className="text-red-500"> has not </span>
              )}
              <span>had a phone call in the last 12 months</span>
            </p>
          ) : (
            <p className="text-sm leading-6 font-medium text-slate-900">
              📞 Patient
              {hasHadCall && <span className="text-green-500"> has </span>}
              {!hasHadCall && <span className="text-red-500"> has not </span>}
              <span>had a phone call</span>
            </p>
          ))}
      </div>
      {consultations.length > 0 && (
        <div className="overflow-x-auto">
          <div className="min-w-full">
            <table className="min-w-full divide-y divide-slate-200">
              <thead>
                <SmallTableHeadings headings={headings} />
              </thead>
              <tbody className="bg-white divide-y divide-slate-200">
                {consultations.map((c: CustomerConsultationView) => (
                  <ConsultationInfoRow key={c.id} consult={c} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {consultations.length > 0 && (
        <ConsultationActions
          customer={customer}
          consultations={consultations}
          problemType={problemType}
          refetchCustomer={refetchCustomer}
        />
      )}
    </div>
  );
};
