import { gql, useMutation } from '@apollo/client';
import { PathologyRequest } from 'graphql/types';
import { useAlert } from 'alert';
import { Button } from 'components/button';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import CustomerDetails from 'components/customer-details';
import { Tag } from 'components/tag';
import { useNotifications } from 'notifications';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  formatDate,
  getProblemTypeColor,
  getProblemTypeEmoji,
  upperSnakeCaseToCapitalCase,
} from 'utils/misc';
import { routes } from 'utils/routes';

const regeneratePathologyReferralDocument = gql`
  mutation RegeneratePathologyReferral($consultationId: String!) {
    regeneratePathologyReferral(consultationId: $consultationId) {
      id
      referral {
        id
        signedUrl
      }
    }
  }
`;

const PathologyReferralInfo = ({
  signedUrl,
  consultationId,
}: {
  signedUrl: string;
  consultationId: string;
}): React.ReactElement => {
  const [url, setUrl] = React.useState(signedUrl);
  const showNotification = useNotifications();
  const [regeneratePathologyReferral, { loading }] = useMutation<
    {
      regeneratePathologyReferral: PathologyRequest;
    },
    {
      consultationId: string;
    }
  >(regeneratePathologyReferralDocument);
  const showAlert = useAlert();
  async function onRegenerate(): Promise<void> {
    const result = await showAlert({
      content: `Are you sure you want to regenerate the referral letter?`,
    });
    if (!result) {
      return;
    }
    const updatedPathologyRequest = await regeneratePathologyReferral({
      variables: {
        consultationId,
      },
    });
    const newUrl =
      updatedPathologyRequest.data?.regeneratePathologyReferral.referral
        ?.signedUrl;
    if (newUrl) {
      setUrl(newUrl);
    }
    showNotification({
      type: 'success',
      message: `Referral letter has been regenerated`,
    });
  }
  return (
    <>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        className="block bg-slate-300 p-2 break-all text-slate-800 cursor-pointer mb-2"
      >
        {url}
      </a>
      <div className="w-1/2">
        <Button fullWidth loading={loading} onClick={onRegenerate}>
          Generate New Referral
        </Button>
      </div>
    </>
  );
};

const AsideEntry = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <section>
      <dt className="heading-sm mb-2">{title}</dt>
      <dd>{children}</dd>
    </section>
  );
};

const PathologyAside = ({
  pathologyRequest,
}: {
  pathologyRequest: PathologyRequest;
}): React.ReactElement => {
  return (
    <>
      {pathologyRequest.patient && (
        <CustomerDetails customer={pathologyRequest.patient} />
      )}
      <dl className="space-y-6">
        <section className="flex justify-between w-full">
          <AsideEntry title="Reference Number">
            {pathologyRequest.reference}
          </AsideEntry>
        </section>
        <section className="flex justify-between w-full">
          <AsideEntry title="Created">
            {formatDate(new Date(pathologyRequest.createdAt))}
          </AsideEntry>
          <AsideEntry title="Last updated">
            {pathologyRequest.consultation
              ? formatDate(new Date(pathologyRequest.consultation.updatedAt))
              : null}
          </AsideEntry>
        </section>
        {pathologyRequest.consultation && (
          <>
            <AsideEntry title="Problem type">
              <Tag
                color={getProblemTypeColor(pathologyRequest.consultation.type)}
              >
                {`${getProblemTypeEmoji(
                  pathologyRequest.consultation.type,
                )} ${upperSnakeCaseToCapitalCase(
                  pathologyRequest.consultation.type,
                )}`}
              </Tag>
            </AsideEntry>
            <AsideEntry title="Status">
              {upperSnakeCaseToCapitalCase(
                pathologyRequest.consultation.status,
              )}
            </AsideEntry>
            <AsideEntry title="Related consult">
              <Link
                to={`${routes.consultations}/${pathologyRequest.consultation.id}`}
              >
                {pathologyRequest.consultation.id}
              </Link>
              <CopyToClipboardButton value={pathologyRequest.consultation.id} />
            </AsideEntry>
          </>
        )}
        {pathologyRequest.doctor?.id && (
          <AsideEntry title="Requesting Practitioner">
            <Link to={`${routes.clinicians}/${pathologyRequest.doctor.id}`}>
              <Tag>{pathologyRequest.doctor.clinicianName}</Tag>
            </Link>
          </AsideEntry>
        )}
        {pathologyRequest?.consultation?.doctor?.id && (
          <AsideEntry title="Reviewing Practitioner">
            <Link
              to={`${routes.clinicians}/${pathologyRequest.consultation.doctor.id}`}
            >
              <Tag>{pathologyRequest.consultation.doctor.clinicianName}</Tag>
            </Link>
          </AsideEntry>
        )}
        {pathologyRequest.referral?.signedUrl &&
          pathologyRequest.consultation?.id && (
            <AsideEntry title="Pathology Referral PDF">
              <PathologyReferralInfo
                consultationId={pathologyRequest.consultation?.id}
                signedUrl={pathologyRequest.referral?.signedUrl}
              />
            </AsideEntry>
          )}
      </dl>
    </>
  );
};

export default PathologyAside;
