import React from 'react';
import clsx from 'clsx';
import { Label } from 'components/label';

interface Props {
  name: string;
  label?: string;
  labelPosition?: 'top' | 'right';
  value: string;
  checked?: boolean;
  disabled?: boolean;
}

const BaseSwitch = (
  {
    name,
    label,
    labelPosition = 'right',
    value,
    checked = false,
    disabled,
  }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => (
  <div
    className={clsx(
      'flex',
      {
        'flex-col items-start': labelPosition === 'top',
      },
      {
        'flex-row items-center': labelPosition === 'right',
      },
    )}
  >
    {label && labelPosition === 'top' && (
      <div className="mb-3">
        <Label>{label}</Label>
      </div>
    )}
    <label
      htmlFor={value}
      className={clsx(
        'w-12',
        'rounded-full',
        'border-2',
        'focus-within:ring ring-primary ring-opacity-50',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        {
          'bg-slate-300 border-slate-300': !checked,
        },
        {
          'bg-primary border-primary': checked,
        },
      )}
    >
      <input
        ref={ref}
        name={name}
        id={value}
        value={value}
        type="checkbox"
        className="visually-hidden"
        disabled={disabled}
        defaultChecked={checked}
      />
      <div
        className={clsx(
          'w-6',
          'h-6',
          'rounded-full',
          'bg-white',
          'transform',
          'transition-transform',
          'duration-200',
          {
            'translate-x-0 ml-0': !checked,
          },
          {
            'translate-x-full -ml-1': checked,
          },
        )}
      />
    </label>
    {label && labelPosition === 'right' && <div className="ml-4">{label}</div>}
  </div>
);

export const Switch = React.forwardRef<HTMLInputElement, Props>(BaseSwitch);
