import clsx from 'clsx';
import { CallStatus } from 'graphql/types';
import { formatDateAndTime } from 'utils/misc';

const statusText: Record<CallStatus, string> = {
  BUSY: 'Busy',
  CANCELED: 'Canceled',
  NO_ANSWER: 'No pickup',
  COMPLETED: 'Answered',
  VOICEMAIL: 'Voicemail',
  CREATED: 'Created',
  CONNECTED: 'Awaiting result',
  MANUAL_CONFIRMATION: 'Confirmed manually',
};

const StatusLozenge = ({
  status,
}: {
  status: CallStatus;
}): React.ReactElement => {
  const text = statusText[status];
  const commonStyles = 'px-2 py-1 text-sm rounded-md text-center';

  switch (status) {
    case 'COMPLETED': {
      return (
        <div className={clsx(commonStyles, 'bg-green-500 text-white')}>
          {text}
        </div>
      );
    }
    case 'MANUAL_CONFIRMATION': {
      return (
        <div className={clsx(commonStyles, 'bg-orange-500 text-white')}>
          {text}
        </div>
      );
    }
    case 'BUSY':
    case 'VOICEMAIL':
    case 'CANCELED':
    case 'NO_ANSWER':
      return (
        <div className={clsx(commonStyles, 'bg-red-500 text-white')}>
          {text}
        </div>
      );
    default: {
      return <div className={clsx(commonStyles, 'bg-slate-300')}>{text}</div>;
    }
  }
};

export const ConsultationPhoneCalls = ({
  phoneCalls,
}: {
  phoneCalls: {
    id: string;
    calleeNumber?: string | null;
    phoneCallProviderDetails?:
      | { __typename?: 'LinePhoneCallDetails'; id: string }
      | {
          __typename?: 'ZoomPhoneCallDetails';
          startedRingingAt?: string | null | undefined;
          duration?: string | null | undefined;
          id: string;
        }
      | null
      | undefined;
    status: CallStatus;
    caller?: {
      id: string;
      clinicianName?: string | null | undefined;
    } | null;
  }[];
}): React.ReactElement => {
  return (
    <>
      <div className="border-t border-slate-200">
        <div className="px-4 py-5 border-b border-slate-200">
          <h3 className="text-lg leading-6 font-medium text-slate-900">
            Phone Calls{' '}
            <span className="ml-2 text-slate-500">{phoneCalls.length}</span>
          </h3>
        </div>
        <div>
          <div className="-mt-2 overflow-x-auto">
            <div className="pt-2 min-w-full">
              <table className="min-w-full divide-y divide-slate-200">
                <thead>
                  <tr>
                    {[
                      'Status',
                      'Phone number',
                      'Caller',
                      'Duration',
                      'Called on',
                    ].map((h) => (
                      <th
                        key={h}
                        className="px-6 py-3 bg-slate-50 text-left text-xs leading-4 font-medium text-slate-500 uppercase tracking-wider"
                      >
                        {h}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-slate-200">
                  {phoneCalls.map((call) => (
                    <tr key={call.id}>
                      <td className="px-6 py-2 whitespace-nowrap w-6">
                        <StatusLozenge status={call.status} />
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-slate-900">
                            {call.calleeNumber ?? 'Unknown'}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-slate-900">
                            {call.caller?.clinicianName ?? 'Unknown'}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-slate-900">
                            {call.phoneCallProviderDetails?.__typename ===
                            'ZoomPhoneCallDetails'
                              ? call.phoneCallProviderDetails.duration
                              : 'Unknown'}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-slate-900">
                            {call.phoneCallProviderDetails?.__typename ===
                              'ZoomPhoneCallDetails' &&
                            call.phoneCallProviderDetails.startedRingingAt
                              ? formatDateAndTime(
                                  call.phoneCallProviderDetails
                                    .startedRingingAt,
                                )
                              : 'Unknown'}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
