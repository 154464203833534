import { gql, useMutation, useQuery } from '@apollo/client';

import { validate } from 'uuid';
import { config } from 'config';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import {
  CreateActionMutation,
  CreateActionMutationVariables,
  CreateVariantGoalMutation,
  CreateVariantGoalMutationVariables,
  DeleteActionMutation,
  DeleteActionMutationVariables,
  DeleteGoalMutation,
  DeleteGoalMutationVariables,
  GoalPageQuery,
  GoalPageQueryVariables,
  InputMaybe,
  LevelTreePublishedState,
  PublishDisabledReason,
  TemplateStatus,
  UpdateGoalsMutation,
  UpdateGoalsMutationVariables,
  VariantLevelTreeGoalTemplate,
  ReorderActionsMutation,
  ReorderActionsMutationVariables,
  ActionTemplateFragment,
  UpdateLevelTreeGoalInput,
} from '../../graphql/types';
import { Loading } from '../../components/loading';
import { TemplateStatusTag } from '../../components/pal-levels/template-status-tag';
import { Button } from '../../components/button';
import { buildRoute, routes } from '../../utils/routes';
import { Input } from '../../components/react-hook-form-6/input';
import { TextArea } from '../../components/text-area';
import { Label } from '../../components/label';
import { useFieldArray, useForm } from 'react-hook-form-6';
import { useCallback, useRef, useState } from 'react';
import { CreateVariantGoalModal } from './create-variant-goal-modal';
import { useNotifications } from 'notifications';
import { Modal } from 'components/modal';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { ActionCard, ActionForm } from './action-card';
import { VariantForm, VariantGoalCard } from './variant-card';

const goalNameCharacterLimit = 50;
const goalDescriptionCharacterLimit = 130;

const formattedPublishDisabledReasons: {
  [key in PublishDisabledReason]: string;
} = {
  NO_CHILDREN: 'Goals require at least one action to publish.',
  NO_PUBLISHED_CHILDREN:
    'Goals require at least one published action to publish.',
};

const ACTION_TEMPLATE_FRAGMENT = gql`
  fragment actionTemplate on LevelTreeActionTemplate {
    id
    name
    mediaAttachmentRequirement
    contentItems {
      id
    }
    repeatingActionTemplate {
      id
      repeatCount
    }
  }
`;

const GOAL_TEMPLATE_FRAGMENT = gql`
  fragment goalTemplate on LevelTreeGoalTemplate {
    id
    status
    canPublish
    name
    description
    publishDisabledReasons
    actions {
      ...actionTemplate
    }
    ... on DefaultLevelTreeGoalTemplate {
      level {
        id
        levelNumber
        pillar {
          id
          name
        }
      }
      variants {
        id
        name
        description
        useCase
        actions {
          ...actionTemplate
        }
      }
    }
  }
  ${ACTION_TEMPLATE_FRAGMENT}
`;

const mapGqlActionsToForm = (
  actions?: ActionTemplateFragment[] | null,
): ActionForm[] | undefined => {
  return actions?.map((a) => ({
    id: a.id,
    name: a.name ?? '',
    mediaRequirement: a.mediaAttachmentRequirement ?? 'NONE',
    canAttachMedia: a.mediaAttachmentRequirement !== 'NONE',
    repeatCount: `${a.repeatingActionTemplate?.repeatCount}`,
    isRepeatAction: !!a.repeatingActionTemplate?.repeatCount,
    // only supporting 1 content ID for release
    contentId: a.contentItems?.[0]?.id,
    hasLinkToResource: !!a.contentItems?.length,
  }));
};

const mapFormActionsToGql = (
  actions?: ActionForm[],
): UpdateLevelTreeGoalInput['actions'] => {
  return actions?.map((a) => ({
    id: a.id,
    name: a.name,
    repeat: a.isRepeatAction
      ? {
          count: parseInt(a.repeatCount),
          refreshTime: '03:00:00',
          refreshTimezone: config.defaultTimezone,
        }
      : undefined,
    mediaAttachmentRequirement: a.canAttachMedia ? a.mediaRequirement : 'NONE',
    contentIds: a.contentId ? [a.contentId] : [],
  }));
};

const GoalPage = (): React.ReactElement => {
  const { goalTemplateId } = useParams<{ goalTemplateId: string }>();
  const { data, loading } = useQuery<GoalPageQuery, GoalPageQueryVariables>(
    gql`
      query GoalPage($id: ID!) {
        levelTreeGoalTemplate(id: $id) {
          ...goalTemplate
        }
      }
      ${GOAL_TEMPLATE_FRAGMENT}
    `,
    {
      variables: { id: goalTemplateId },
      fetchPolicy: 'cache-first',
      skip: !validate(goalTemplateId),
    },
  );

  if (loading) {
    return <Loading />;
  }

  if (!data?.levelTreeGoalTemplate) {
    return (
      <div>
        Goal not found - please click{' '}
        <Link to={routes.goals} className="text-blue-500 underline">
          here
        </Link>{' '}
        to navigate to your desired goal
      </div>
    );
  }

  if (
    data.levelTreeGoalTemplate.__typename !== 'DefaultLevelTreeGoalTemplate'
  ) {
    return <Redirect to={routes.goals} />;
  }

  return (
    <GoalPageTemplate
      levelTreeGoalTemplate={data.levelTreeGoalTemplate}
      variants={data.levelTreeGoalTemplate.variants?.filter(
        (v): v is VariantLevelTreeGoalTemplate =>
          v.__typename === 'VariantLevelTreeGoalTemplate' && !!v.useCase,
      )}
    />
  );
};

const GoalPageTemplate = (props: {
  levelTreeGoalTemplate: {
    id: string;
    status?: TemplateStatus | null;
    name?: string | null;
    description?: string | null;
    canPublish?: boolean | null;
    publishDisabledReasons?: PublishDisabledReason[] | null;
    level?: {
      id: string;
      levelNumber: number;
      pillar?: {
        id: string;
        name?: string | null;
      } | null;
    } | null;
    actions?: ActionTemplateFragment[] | null;
  };
  variants?: {
    useCase?: string | null;
    id: string;
    name?: string | null;
    description?: string | null;
    actions?: ActionTemplateFragment[] | null;
  }[];
}) => {
  const history = useHistory();
  const showNotification = useNotifications();
  const [showModal, setShowModal] = useState(false);
  const [goalIdToBeDeleted, setGoalIdToBeDeleted] = useState<
    string | undefined
  >();
  const isDeletingVariantGoal =
    goalIdToBeDeleted !== props.levelTreeGoalTemplate.id;
  const submittingVariant = useRef<LevelTreePublishedState>();
  const [updateGoals, { loading: updateGoalsLoading }] = useMutation<
    UpdateGoalsMutation,
    UpdateGoalsMutationVariables
  >(
    gql`
      mutation UpdateGoals($input: [UpdateLevelTreeGoalInput!]!) {
        updateLevelTreeGoalTemplates(input: $input) {
          goalTemplates {
            ...goalTemplate
            ... on DefaultLevelTreeGoalTemplate {
              level {
                id
                canPublish
                publishDisabledReasons
              }
            }
          }
        }
      }
      ${GOAL_TEMPLATE_FRAGMENT}
    `,
    {
      onCompleted: () =>
        showNotification({
          type: 'success',
          message: 'Goal updated successfully',
        }),
    },
  );

  const form = useForm<{
    defaultGoal: {
      name: string;
      description: string;
      actions: ActionForm[];
    };
    variants: VariantForm[];
  }>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      defaultGoal: {
        name: props.levelTreeGoalTemplate.name ?? '',
        description: props.levelTreeGoalTemplate.description ?? '',
        actions: mapGqlActionsToForm(props.levelTreeGoalTemplate.actions),
      },
      variants: props.variants?.map((v) => ({
        id: v.id,
        name: v.name ?? '',
        description: v.description ?? '',
        useCase: v.useCase ?? '',
        actions: mapGqlActionsToForm(v.actions),
      })),
    },
  });

  const {
    fields: variantFields,
    append: appendVariant,
    remove: removeVariant,
  } = useFieldArray({
    control: form.control,
    name: 'variants',
  });

  const {
    fields: defaultActionFields,
    append: appendAction,
    remove: removeAction,
    move: moveAction,
  } = useFieldArray({
    control: form.control,
    name: 'defaultGoal.actions',
  });

  const [createGoal] = useMutation<
    CreateVariantGoalMutation,
    CreateVariantGoalMutationVariables
  >(
    gql`
      mutation CreateVariantGoal($input: CreateLevelTreeGoalTemplateInput!) {
        createLevelTreeGoalTemplate(input: $input) {
          goalTemplate {
            id
            useCase
            name
            description
            ... on VariantLevelTreeGoalTemplate {
              defaultGoal {
                id
                ... on DefaultLevelTreeGoalTemplate {
                  variants {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: ({ createLevelTreeGoalTemplate }) => {
        if (
          createLevelTreeGoalTemplate?.goalTemplate?.__typename ===
          'VariantLevelTreeGoalTemplate'
        ) {
          appendVariant({
            id: createLevelTreeGoalTemplate?.goalTemplate?.id,
            useCase: createLevelTreeGoalTemplate.goalTemplate.useCase,
            name: '',
            description: '',
          });
        }
      },
    },
  );

  const handleCreateVariantGoal = async (useCase: InputMaybe<string>) => {
    await createGoal({
      variables: {
        input: {
          goalTemplateId: props.levelTreeGoalTemplate.id,
          useCase,
        },
      },
    });
  };

  const [deleteGoal, { loading: deleteLoading }] = useMutation<
    DeleteGoalMutation,
    DeleteGoalMutationVariables
  >(
    gql`
      mutation DeleteGoal($input: DeleteLevelTreeGoalTemplateInput!) {
        deleteLevelTreeGoalTemplate(input: $input) {
          levelTemplate {
            id
            goals {
              ...goalTemplate
            }
          }
        }
      }
      ${GOAL_TEMPLATE_FRAGMENT}
    `,
    {
      onCompleted: () => {
        setGoalIdToBeDeleted(undefined);
        showNotification({
          type: 'success',
          message: 'Goal deleted successfully',
        });
        if (isDeletingVariantGoal) {
          const index = variantFields.findIndex(
            (v) => v.id === goalIdToBeDeleted,
          );
          removeVariant(index);
        } else {
          history.push(routes.goals);
        }
      },
    },
  );

  const [deleteAction] = useMutation<
    DeleteActionMutation,
    DeleteActionMutationVariables
  >(gql`
    mutation DeleteAction($input: DeleteLevelTreeActionTemplateInput!) {
      deleteLevelTreeActionTemplate(input: $input) {
        goalTemplate {
          ...goalTemplate
        }
      }
    }
    ${GOAL_TEMPLATE_FRAGMENT}
  `);

  const [actionIdDeleteLoading, setActionIdDeleteLoading] = useState<
    string | null
  >(null);
  const handleDeleteAction = async (actionId: string, index: number) => {
    setActionIdDeleteLoading(actionId);
    await deleteAction({
      variables: {
        input: {
          actionTemplateId: actionId,
        },
      },
      onCompleted: () => {
        setActionIdDeleteLoading(null);
        removeAction(index);
        showNotification({
          type: 'success',
          message: 'Action deleted successfully',
        });
      },
      onError: () => {
        setActionIdDeleteLoading(null);
      },
    });
  };

  const [createAction, { loading: createActionLoading }] = useMutation<
    CreateActionMutation,
    CreateActionMutationVariables
  >(
    gql`
      mutation CreateAction($input: CreateLevelTreeActionTemplateInput!) {
        createLevelTreeActionTemplate(input: $input) {
          actionTemplate {
            ...actionTemplate
          }
        }
      }
      ${ACTION_TEMPLATE_FRAGMENT}
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Action created successfully',
        });
      },
    },
  );

  const [reorderActions, { loading: reorderLoading }] = useMutation<
    ReorderActionsMutation,
    ReorderActionsMutationVariables
  >(
    gql`
      mutation ReorderActions($input: [ReorderLevelTreeActionTemplateInput!]!) {
        reorderLevelTreeActionTemplates(input: $input) {
          levelTreeGoalTemplate {
            id
            actions {
              ...actionTemplate
            }
          }
        }
      }
      ${ACTION_TEMPLATE_FRAGMENT}
    `,
    {
      onCompleted: () =>
        showNotification({
          type: 'success',
          message: 'Actions reordered successfully',
        }),
    },
  );

  const handleOnDragEnd = useCallback(
    async ({ source, destination }: DropResult): Promise<void> => {
      const movedActionId = defaultActionFields[source.index].id;
      if (
        !movedActionId ||
        !destination ||
        destination.index === source.index
      ) {
        return;
      }

      const actionIds = defaultActionFields
        .map((a) => a.id)
        .filter((id): id is string => id !== movedActionId && !!id);

      actionIds.splice(destination.index, 0, movedActionId);

      moveAction(source.index, destination.index);

      await reorderActions({
        variables: {
          input: actionIds.map((id, i) => ({
            levelTreeActionTemplateId: id,
            position: i,
          })),
        },
        onError: () => {
          moveAction(destination.index, source.index);
        },
      });
    },
    [defaultActionFields, reorderActions, moveAction],
  );

  const handleSubmit = (publishedState: LevelTreePublishedState) =>
    form.handleSubmit(async (data) => {
      submittingVariant.current = publishedState;
      await updateGoals({
        variables: {
          input: [
            {
              id: props.levelTreeGoalTemplate.id,
              name: data.defaultGoal.name,
              description: data.defaultGoal.description,
              publishedState,
              actions: mapFormActionsToGql(data.defaultGoal.actions),
            },
            ...(data.variants
              ? data.variants.map((v) => ({
                  publishedState,
                  ...v,
                  actions: mapFormActionsToGql(v.actions),
                }))
              : []),
          ],
        },
      });
      form.reset(data);
      submittingVariant.current = undefined;
    })();

  const formValues = form.watch();
  const { isDirty, isValid } = form.formState;
  const draftSubmitLoading =
    updateGoalsLoading && submittingVariant.current === 'DRAFT';
  const publishedSubmitLoading =
    updateGoalsLoading && submittingVariant.current === 'PUBLISHED';

  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 justify-end">
            <Button
              size="small"
              variant="outline"
              disabled={
                !isDirty ||
                !isValid ||
                publishedSubmitLoading ||
                !!actionIdDeleteLoading ||
                createActionLoading
              }
              loading={draftSubmitLoading}
              onClick={() => handleSubmit('DRAFT')}
            >
              Save as draft
            </Button>
            <div className="tooltip">
              <Button
                fillHeight
                size="small"
                disabled={
                  (!isDirty && props.levelTreeGoalTemplate.status === 'LIVE') ||
                  !props.levelTreeGoalTemplate.canPublish ||
                  !isValid ||
                  draftSubmitLoading ||
                  !!actionIdDeleteLoading ||
                  createActionLoading
                }
                onClick={() => handleSubmit('PUBLISHED')}
                loading={publishedSubmitLoading}
              >
                Publish
              </Button>
              {props.levelTreeGoalTemplate.publishDisabledReasons &&
                props.levelTreeGoalTemplate.publishDisabledReasons.length >
                  0 && (
                  <span className="tooltiptext text-sm bg-black text-white p-2 rounded-md normal-case font-normal">
                    {props.levelTreeGoalTemplate.publishDisabledReasons
                      .map((r) => formattedPublishDisabledReasons[r])
                      .join(', ')}
                  </span>
                )}
            </div>
          </div>
          <Link
            to={buildRoute.pillar(
              props.levelTreeGoalTemplate.level?.pillar?.id ?? '',
              'levels',
            )}
          >
            <div className="flex flex-row justify-between bg-white p-4 shadow-md">
              <div className="flex flex-col gap-1">
                <p className="uppercase font-semibold text-sm">
                  Pillar & Level
                </p>
                <p>
                  {props.levelTreeGoalTemplate.level?.pillar
                    ? `${props.levelTreeGoalTemplate.level.pillar.name} Level ${props.levelTreeGoalTemplate.level.levelNumber}`
                    : 'Pillar and/or level not found'}
                </p>
              </div>
              <div>
                <div className="flex gap-2 items-center">
                  <p className="uppercase font-semibold text-sm">
                    Current Status
                  </p>
                  {props.levelTreeGoalTemplate.status ? (
                    <TemplateStatusTag
                      status={props.levelTreeGoalTemplate.status}
                    />
                  ) : (
                    'Status not found'
                  )}
                </div>
              </div>
            </div>
          </Link>
        </div>
        <hr className="border-t border-slate-400" />
        <div className="flex gap-4">
          <div className="flex-1 flex flex-col gap-4">
            <p className="text-lg font-semibold">Default</p>
            <div className="bg-white p-4 flex flex-col gap-4">
              <Input
                label="Goal name*"
                name="defaultGoal.name"
                description="Visible to patients"
                ref={form.register({
                  validate: {
                    trailingWhitespace: (v) =>
                      v.trim() === v || 'Cannot have trailing whitespace',
                    required: (v) => !!v || 'Please enter a name',
                  },
                })}
                hint={`${
                  goalNameCharacterLimit - formValues.defaultGoal.name.length
                } characters remaining`}
                errorMessage={form.errors.defaultGoal?.name?.message}
                maxLength={goalNameCharacterLimit}
              />
              <div>
                <div className="mb-1">
                  <Label htmlFor="description">Description*</Label>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-sm text-slate-700">Visible to patients</p>
                  <p className="text-sm text-slate-700">
                    A short summary of this goal and how achieving it will
                    benefit the patient.
                  </p>
                </div>
                <TextArea
                  name="defaultGoal.description"
                  ref={form.register({
                    validate: {
                      trailingWhitespace: (v) =>
                        v.trim() === v || 'Cannot have trailing whitespace',
                    },
                  })}
                  placeholder=""
                  rows={6}
                  hint={`${
                    goalDescriptionCharacterLimit -
                    formValues.defaultGoal.description.length
                  } characters remaining`}
                  errorMessage={form.errors.defaultGoal?.description?.message}
                  maxLength={goalDescriptionCharacterLimit}
                />
              </div>
              <hr className="border-t -mb-5 border-slate-400" />
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="actions">
                  {(provided): JSX.Element => (
                    <div
                      className="flex flex-col"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {defaultActionFields
                        .filter((v): v is ActionForm => !!v.id)
                        .map((action, index) => (
                          <Draggable
                            key={action.id}
                            draggableId={action.id}
                            index={index}
                            isDragDisabled={reorderLoading}
                          >
                            {(provided): JSX.Element => (
                              <ActionCard
                                index={index}
                                formId={`defaultGoal.actions[${index}]`}
                                action={action}
                                provided={provided}
                                register={form.register}
                                formValues={
                                  formValues.defaultGoal.actions?.[index]
                                }
                                errors={
                                  form.errors.defaultGoal?.actions?.[index]
                                }
                                onActionDelete={async () => {
                                  handleDeleteAction(action.id, index);
                                }}
                                actionIdDeleteLoading={actionIdDeleteLoading}
                                control={form.control}
                                onContentItemsLoaded={() => form.trigger()}
                              />
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div>
                <Button
                  loading={createActionLoading}
                  onClick={async () => {
                    const resp = await createAction({
                      variables: {
                        input: {
                          goalTemplateId: props.levelTreeGoalTemplate.id,
                        },
                      },
                    });
                    appendAction({
                      id: resp.data?.createLevelTreeActionTemplate
                        ?.actionTemplate?.id,
                      name: '',
                      canAttachMedia: false,
                      mediaRequirement: 'NONE',
                      repeatCount: undefined,
                      isRepeatAction: false,
                      contentId: '',
                      hasLinkToResource: false,
                    });
                  }}
                  size="small"
                  variant="outline"
                >
                  Add action
                </Button>
              </div>
            </div>
            <div>
              <Button
                onClick={() =>
                  setGoalIdToBeDeleted(props.levelTreeGoalTemplate.id)
                }
                variant="solid"
                color="danger"
              >
                Delete goal
              </Button>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-4">
            <div className="flex justify-between">
              <p className="text-lg font-semibold">Variants</p>
              <Button
                onClick={() => setShowModal(true)}
                size="small"
                variant="outline"
              >
                Add variant
              </Button>
            </div>
            <div className="flex flex-col space-y-2">
              {variantFields
                .filter((v): v is VariantForm => !!v.id)
                .map((variant, i) => (
                  <div key={variant.id}>
                    <VariantGoalCard
                      index={i}
                      variant={variant}
                      control={form.control}
                      register={form.register}
                      formValues={formValues.variants?.[i]}
                      errors={form.errors.variants?.[i]}
                      goalNameCharacterLimit={goalNameCharacterLimit}
                      goalDescriptionCharacterLimit={
                        goalDescriptionCharacterLimit
                      }
                      onDeleteVariant={() => setGoalIdToBeDeleted(variant.id)}
                      onDeleteAction={async (actionId, onCompleted) => {
                        await deleteAction({
                          variables: {
                            input: {
                              actionTemplateId: actionId,
                            },
                          },
                          onCompleted,
                        });
                      }}
                      onCreateAction={async (goalId: string) => {
                        const resp = await createAction({
                          variables: {
                            input: {
                              goalTemplateId: goalId,
                            },
                          },
                        });
                        return {
                          actionId:
                            resp.data?.createLevelTreeActionTemplate
                              ?.actionTemplate?.id,
                        };
                      }}
                      onReorderActions={async (actions, onError) => {
                        await reorderActions({
                          variables: {
                            input: actions,
                          },
                          onError,
                        });
                      }}
                      actionIdDeleteLoading={actionIdDeleteLoading}
                      onContentItemsLoaded={() => form.trigger()}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <CreateVariantGoalModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onCreateVariantGoal={handleCreateVariantGoal}
      />
      <Modal
        show={!!goalIdToBeDeleted}
        onClose={() => setGoalIdToBeDeleted(undefined)}
      >
        <div className="bg-slate-200 rounded p-6 flex flex-col space-y-8">
          <div className="flex flex-col space-y-2">
            <p className="text-lg text-slate-900 font-semibold">
              ⚠️ Deleting this goal {isDeletingVariantGoal ? 'variant' : ''} may
              impact patients
            </p>
            <p className="text-slate-800">
              By deleting this {isDeletingVariantGoal ? 'goal variant' : 'goal'}
              , you are removing it as a requirement for all patients
              progressing through this level. As a result, patients will either
              move onto other required goals or level up.
            </p>
            {!isDeletingVariantGoal && (
              <p className="text-slate-800">
                Please note, if you delete this goal{' '}
                <span className="underline">
                  you will also be deleting all of its variants.
                </span>
              </p>
            )}
          </div>
          <div className="flex space-x-4">
            <Button
              fullWidth
              color="danger"
              variant="outline"
              onClick={() => setGoalIdToBeDeleted(undefined)}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              onClick={async () => {
                if (goalIdToBeDeleted) {
                  await deleteGoal({
                    variables: {
                      input: {
                        goalTemplateId: goalIdToBeDeleted,
                      },
                    },
                  });
                }
              }}
              variant="solid"
              loading={deleteLoading}
            >
              Delete goal {isDeletingVariantGoal ? 'variant' : ''}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GoalPage;
