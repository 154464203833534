import React from 'react';
import clsx from 'clsx';
import { Colors } from 'utils/misc';

const colorClasses = (color: Colors): string => {
  switch (color) {
    case 'gray':
      return 'bg-slate-100 border-slate-400 text-slate-600';
    case 'red':
      return 'bg-red-100 border-red-400 text-red-600';
    case 'orange':
      return 'bg-orange-100 border-orange-400 text-orange-600';
    case 'yellow':
      return 'bg-yellow-100 border-yellow-400 text-yellow-600';
    case 'green':
      return 'bg-green-100 border-green-400 text-green-600';
    case 'teal':
      return 'bg-teal-100 border-teal-400 text-teal-600';
    case 'blue':
      return 'bg-blue-100 border-blue-400 text-blue-600';
    case 'dark-blue':
      return 'bg-blue-200 border-blue-500 text-blue-800';
    case 'indigo':
      return 'bg-indigo-100 border-indigo-400 text-indigo-600';
    case 'purple':
      return 'bg-purple-100 border-purple-400 text-purple-600';
    case 'pink':
      return 'bg-pink-200 border-pink-400 text-pink-600';
    case 'rose':
      return 'bg-rose-200 border-rose-400 text-rose-600';
    case 'primary':
    default:
      return 'bg-primary-100 border-primary-300 text-primary';
  }
};

export interface TagProps {
  children: React.ReactNode;
  color?: Colors;
  icon?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  shape?: 'box' | 'pill';
}

export const Tag = ({
  children,
  color = 'primary',
  icon = null,
  size = 'medium',
  shape = 'box',
}: TagProps): JSX.Element => {
  const colors = colorClasses(color);

  const tagClass = clsx(
    'inline-flex',
    'items-center',
    'leading-loose',
    'text-xs',
    'border',
    'font-semibold',
    'transition-all',
    'duration-300',
    colors,
    {
      'rounded-md': shape === 'box',
      'rounded-full': shape === 'pill',
    },
    { 'p-0': size === 'small' },
    { 'p-1': size !== 'small' },
    { 'text-sm': size === 'large' },
  );

  return (
    <div className={tagClass}>
      <span className="px-2 inline-flex items-center whitespace-nowrap select-none">
        {icon}
        <span className={clsx({ 'pl-2': icon !== null })}>{children}</span>
      </span>
    </div>
  );
};
