import { gql, useMutation, useQuery } from '@apollo/client';
import type {
  ConfirmStepAllDoctorsQuery,
  ConfirmStepAllDoctorsQueryVariables,
  DispatchCreateReviewConsultationBatchMutation,
  DispatchCreateReviewConsultationBatchMutationVariables,
  ProblemType,
} from 'graphql/types';
import React from 'react';
import { Markdown } from 'components/markdown';
import { DispatchBatchStepTransitionProps } from './types';
import { AssignOption } from './bulk-review-button';
import { ModalLayout } from './modal-layout';

type ConfirmStepProps = DispatchBatchStepTransitionProps & {
  skipQuiz: boolean;
  adminNotes?: string;
  assignOption: AssignOption;
  doctorIds: string[];
  bookingWindows: { id: string; name: string }[];
  problemType: ProblemType;
  jobCount: number;
  batchId: string;
};

export const ConfirmStep: React.FC<ConfirmStepProps> = ({
  previousStep,
  nextStep: onCompleted,
  skipQuiz,
  adminNotes,
  doctorIds,
  assignOption,
  bookingWindows,
  jobCount,
  batchId,
}) => {
  const { data, loading } = useQuery<
    ConfirmStepAllDoctorsQuery,
    ConfirmStepAllDoctorsQueryVariables
  >(
    gql`
      query ConfirmStepAllDoctors {
        allDoctors {
          id
          clinicianName
        }
      }
    `,
    {
      skip: doctorIds.length === 0,
      fetchPolicy: 'cache-first',
    },
  );

  const clinicianNames = doctorIds.map(
    (doctorId) =>
      data?.allDoctors.find(({ id }) => id === doctorId)?.clinicianName,
  );
  const bookingWindowNames = bookingWindows.map((bw) => bw.name);

  const [dispatchCreateReviewConsultationBatch, { loading: dispatchingBatch }] =
    useMutation<
      DispatchCreateReviewConsultationBatchMutation,
      DispatchCreateReviewConsultationBatchMutationVariables
    >(
      gql`
        mutation DispatchCreateReviewConsultationBatch(
          $input: DispatchCreateReviewConsultationBatchInput!
        ) {
          dispatchCreateReviewConsultationBatch(input: $input) {
            batch {
              id
              status
            }
          }
        }
      `,
      { onCompleted },
    );

  const onConfirm = (): void => {
    dispatchCreateReviewConsultationBatch({
      variables: {
        input: {
          batchId,
          adminNotes,
          doctorToAssignIds: assignOption === 'doctor' ? doctorIds : [],
          practitionerBookingWindowIds:
            assignOption === 'queue' ? bookingWindows.map((bw) => bw.id) : [],
          skipQuiz,
        },
      },
    });
  };

  if (loading) {
    return <div>Loading practitioner names.</div>;
  }

  return (
    <ModalLayout
      back={previousStep}
      next={onConfirm}
      nextText={`Confirm ${jobCount} Consultations`}
      nextDisabled={dispatchingBatch}
    >
      <div className="w-full">
        <h3 className="heading-md mb-2">Confirm review consultations</h3>
        <div className="w-full p-4 bg-slate-400">
          <strong>
            You are about to trigger {jobCount} review consultations
          </strong>
          <ul>
            <li className="my-2">
              <strong>Can skip quiz?:</strong> {skipQuiz ? 'Yes' : 'No'}
            </li>
            <li>
              <strong>Has admin notes:</strong> {adminNotes ? 'Yes' : 'No'}
            </li>
            {adminNotes && (
              <li className="text-slate-700 ml-4 border-t border-b py-4 max-h-96 overflow-auto">
                <Markdown src={adminNotes} />
              </li>
            )}
          </ul>
        </div>
        {skipQuiz &&
          assignOption === 'queue' &&
          !!bookingWindowNames.length && (
            <div>
              {bookingWindowNames.length} call windows selected. Approx.{' '}
              {Math.ceil(jobCount / bookingWindowNames.length)} patients will be
              added to each window.
              <ul className="list-disc pl-6">
                {bookingWindowNames.map((name) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </div>
          )}
        {skipQuiz && assignOption === 'doctor' && !!clinicianNames.length && (
          <div>
            {clinicianNames.length} practitioners selected. Approx.{' '}
            {Math.ceil(jobCount / clinicianNames.length)} patients will be added
            to each practitioner.
            <ul className="list-disc pl-6">
              {clinicianNames.map((name) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          </div>
        )}
        <i>Are you ready to proceed?</i>
      </div>
    </ModalLayout>
  );
};
