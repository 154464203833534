import {
  Maybe,
  SubmittedQuizApplicationInstance,
  TrackerDetails,
  TrackerMeta,
} from 'graphql/types';
import React from 'react';
import { formatDateAndTime } from 'utils/misc';
import { UnMaybe } from 'utils/types';
import { TrackerActions } from '../tracker-actions/tracker-actions';
import { RefetchTrackerData } from '../types';
import { getDeletedAt, getQuizFlagLevel } from '../utils/trackerMeta';
import ProgressEntryImage from './common/progress-entry-image';
import { QuestionFormatter } from './common/questionFormatter';
import { QuizApplicationResponses } from './common/quizApplicationResponses';
import { AnswerFormatter } from './common/responseFormatter';

export const SkinTrackerQuestionnaire = ({
  trackerQuiz,
  refetch,
}: {
  trackerQuiz: TrackerDetails;
  refetch: RefetchTrackerData;
}): React.ReactElement | null => {
  if (
    !trackerQuiz.signedPhotoLeft ||
    !trackerQuiz.signedPhotoCenter ||
    !trackerQuiz.signedPhotoRight
  ) {
    return (
      <QuizApplicationResponses
        quizResponses={trackerQuiz.quizResponses ?? []}
      />
    );
  }

  return (
    <div>
      <SkinTrackerResponses
        skinConfidence={trackerQuiz.skinConfidence}
        skinConfidenceMeta={trackerQuiz.skinConfidenceMeta}
        photoLeft={trackerQuiz.signedPhotoLeft}
        photoCenter={trackerQuiz.signedPhotoCenter}
        photoRight={trackerQuiz.signedPhotoRight}
        skinPhotosMeta={trackerQuiz.skinPhotosMeta}
        submittedAt={
          trackerQuiz.submittedAt
            ? formatDateAndTime(trackerQuiz.submittedAt)
            : 'Date not available'
        }
        refetch={refetch}
      />
      {trackerQuiz.quizResponses && (
        <QuizApplicationResponses
          quizResponses={
            trackerQuiz.quizResponses as SubmittedQuizApplicationInstance[]
          }
        />
      )}
    </div>
  );
};

const SkinTrackerResponses = ({
  skinConfidence,
  skinConfidenceMeta,
  photoLeft,
  photoCenter,
  photoRight,
  skinPhotosMeta,
  submittedAt,
  refetch,
}: {
  skinConfidence: TrackerDetails['skinConfidence'];
  skinConfidenceMeta: Maybe<TrackerMeta>;
  photoLeft: UnMaybe<TrackerDetails['signedPhotoLeft']>;
  photoCenter: UnMaybe<TrackerDetails['signedPhotoCenter']>;
  photoRight: UnMaybe<TrackerDetails['signedPhotoRight']>;
  skinPhotosMeta: Maybe<TrackerMeta>;
  submittedAt: string;
  refetch: RefetchTrackerData;
}): React.ReactElement => {
  const skinConfidenceDeletedAt = getDeletedAt(skinConfidenceMeta);
  const skinPhotosDeletedAt = getDeletedAt(skinPhotosMeta);

  return (
    <>
      <div className="px-4 pb-2 odd:bg-slate-200">
        <div className="flex justify-between space-x-3">
          <QuestionFormatter>Photos</QuestionFormatter>
          {skinPhotosMeta?.observationId && !skinPhotosMeta?.deletedAt && (
            <div className="pt-2 pb-3">
              <TrackerActions
                observationId={skinPhotosMeta.observationId}
                type="SKIN_PHOTOS"
                entryDate={submittedAt}
                refetch={refetch}
                disableEdit={true}
              />
            </div>
          )}
        </div>

        <AnswerFormatter flag={getQuizFlagLevel(skinPhotosMeta)}>
          <div className="flex justify-between space-x-3 py-1">
            <div className="flex space-x-0.5 w-full">
              <ProgressEntryImage imageId={photoLeft} />
              <ProgressEntryImage imageId={photoCenter} />
              <ProgressEntryImage imageId={photoRight} />
            </div>

            <div className="flex flex-col items-end space-y-2">
              {skinPhotosDeletedAt && <div>Deleted {skinPhotosDeletedAt}</div>}
            </div>
          </div>
        </AnswerFormatter>
      </div>

      {skinConfidenceMeta && (
        <div className="px-4 pb-2 odd:bg-slate-200">
          <div className="flex justify-between space-x-3">
            <QuestionFormatter>
              How do you feel about your skin?
            </QuestionFormatter>
            {skinConfidenceMeta?.observationId &&
              !skinConfidenceMeta?.deletedAt && (
                <div className="pt-2 pb-3">
                  <TrackerActions
                    observationId={skinConfidenceMeta.observationId}
                    type="SKIN_CONFIDENCE"
                    entryDate={submittedAt}
                    refetch={refetch}
                    disableEdit={true}
                  />
                </div>
              )}
          </div>
          <AnswerFormatter flag={getQuizFlagLevel(skinConfidenceMeta)}>
            <div className="flex justify-between space-x-3">
              <div>{skinConfidence}</div>
              <div className="flex flex-col items-end space-y-2">
                {skinConfidenceDeletedAt && (
                  <div>Deleted {skinConfidenceDeletedAt}</div>
                )}
              </div>
            </div>
          </AnswerFormatter>
        </div>
      )}
    </>
  );
};
