import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import React from 'react';
import { useAlert } from '../alert';
import { useNotifications } from '../notifications';

export interface ActionButton {
  label: string;
  disabled: boolean;
  mutationGql: DocumentNode | TypedDocumentNode<unknown, OperationVariables>;
  mutationArgs: Record<string, unknown>;
  refetchCustomer: () => void;
  confirmMsg: string | React.ReactNode;
  successMsg: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ActionButton = ({
  label,
  disabled,
  mutationGql,
  mutationArgs,
  refetchCustomer,
  confirmMsg,
  successMsg,
}: ActionButton): React.ReactElement => {
  const showNotification = useNotifications();
  const showAlert = useAlert();
  const [mutation, { loading: loadingMutation }] = useMutation(mutationGql);
  const handleAction =
    (mutationArgs: Record<string, unknown>) => async (): Promise<void> => {
      const adminUserConfirmedAction = await showAlert({
        content: confirmMsg,
      });
      if (!adminUserConfirmedAction) {
        return;
      }
      await mutation({
        variables: {
          ...mutationArgs,
        },
      });
      showNotification({
        type: 'success',
        message: successMsg,
      });
      refetchCustomer();
    };
  return (
    <span className="hidden sm:block rounded">
      <button
        type="button"
        className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white transition duration-150 ease-in-out"
        disabled={disabled || loadingMutation}
        onClick={handleAction(mutationArgs)}
      >
        {label}
      </button>
    </span>
  );
};
