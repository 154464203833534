import React from 'react';
interface ModalButtonBase {
  label: string;
  disabled: boolean;
  toggleModal: () => void;
}
export interface ModalButton extends ModalButtonBase {
  modal: React.ReactNode;
}

interface ModalButtonProps extends ModalButtonBase {
  children: React.ReactNode;
}

export const ModalButton = ({
  toggleModal,
  disabled,
  label,
  children,
}: ModalButtonProps): React.ReactElement => (
  <span className="hidden sm:block rounded">
    {children}
    <button
      type="button"
      className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white transition duration-150 ease-in-out"
      disabled={disabled}
      onClick={toggleModal}
    >
      {label}
    </button>
  </span>
);
