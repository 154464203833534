import { gql } from '@apollo/client';
import { useAuth } from 'auth';
import clsx from 'clsx';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { AuthorTypeTag } from 'components/tags/author-type-tag';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { AddNoteModalConsultationFragment } from 'graphql/types';
import { useState } from 'react';
import { formatDate, upperSnakeCaseToCapitalCase } from 'utils/misc';
import { UpsertNoteForm } from '../upsert-note-form';
import { getNoteDomain } from '../utils';
import { SelectNoteTarget } from './select-note-target';

const classNames = {
  ul: 'text-sm space-y-1',
  h3: 'text-xs leading-4 font-medium text-slate-700 uppercase tracking-wider mb-1',
};

export const addNoteModalConsultationFragment = gql`
  fragment AddNoteModalConsultation on Consultation {
    id
    type
    stage
    createdAt
    customer {
      id
      fullName
    }
  }
`;

export type AddNoteModalProps = {
  customer: {
    id: string;
    fullName: string;
  };
  consultations: AddNoteModalConsultationFragment[];
  defaultValues: {
    consultation: AddNoteModalConsultationFragment | undefined;
    canEditTarget: boolean;
  };
  show: boolean;
  onClose: () => void;
};

export const AddNoteModal: React.FC<AddNoteModalProps> = ({
  customer,
  consultations,
  defaultValues,
  show,
  onClose,
}) => {
  const { user } = useAuth();

  const [consultation, setConsultation] = useState(defaultValues.consultation);
  const [showEditTarget, setShowEditTarget] = useState(false);

  return (
    <Modal show={show} onClose={onClose} width="w-5/6">
      <section className="bg-slate-200">
        <header className="border-b border-slate-500 py-3 px-5 bg-slate-300">
          <h2 className="text-lg font-semibold mb-2">Add a new note</h2>

          <div className="flex flex-row w-full items-start space-x-5">
            <ul className={classNames.ul}>
              <li>
                <strong>Author: </strong>
                {user?.fullName}
              </li>
              <li>
                <strong>Author type: </strong>
                <AuthorTypeTag author={user} />
              </li>
            </ul>

            {!showEditTarget && (
              <>
                <ul className={clsx(classNames.ul)}>
                  <li>
                    <strong>Patient: </strong>
                    {customer.fullName}
                  </li>
                  <li>
                    <strong>Problem type: </strong>
                    <ProblemTypeTag problemType={consultation?.type} />
                  </li>
                  <li>
                    <strong>Consultation: </strong>
                    {consultation
                      ? `${upperSnakeCaseToCapitalCase(consultation.stage)} - ${
                          consultation.id
                        } - ${formatDate(consultation.createdAt)}`
                      : '-'}
                  </li>
                </ul>
                {defaultValues.canEditTarget && consultations.length > 0 && (
                  <ul className={clsx(classNames.ul, 'pl-12 my-auto')}>
                    <li>
                      <Button
                        variant="outline"
                        size="small"
                        onClick={() => setShowEditTarget(true)}
                      >
                        Edit details
                      </Button>
                    </li>
                  </ul>
                )}
              </>
            )}

            {showEditTarget && (
              <ul className={clsx(classNames.ul, 'flex-grow')}>
                <SelectNoteTarget
                  consultations={consultations}
                  consultation={consultation}
                  onUpdate={(c) => {
                    setConsultation(c);
                    setShowEditTarget(false);
                  }}
                />
              </ul>
            )}
          </div>
        </header>

        <div className="space-y-3 px-5 py-3">
          <p className="text-sm">
            This is for internal use only. Other admins, practitioners and
            coaches will be able to see these notes.
          </p>

          <UpsertNoteForm
            title="Note"
            onCompleted={onClose}
            disabled={showEditTarget}
            noteInput={{
              targetType: consultation ? 'CONSULTATION' : 'CUSTOMER',
              targetId: consultation?.id ?? customer.id,
              domain: getNoteDomain(user),
            }}
          />
        </div>
      </section>
    </Modal>
  );
};
