import { useAuth } from 'auth';
import { Modal } from 'components/modal';
import { AuthorTypeTag } from 'components/tags/author-type-tag';
import { NoteRowFragment } from 'graphql/types';
import { UpsertNoteForm } from '../upsert-note-form';
import { Markdown } from 'components/markdown';

const classNames = {
  ul: 'text-sm space-y-1',
  h3: 'text-xs leading-4 font-medium text-slate-700 uppercase tracking-wider mb-1',
};

type NoteWithAddendums = Extract<
  NoteRowFragment,
  { __typename?: 'ConsultationNote' } | { __typename?: 'CustomerNote' }
>;

export type AddAddendumModalProps = {
  note: NoteWithAddendums;
  show: boolean;
  onClose: () => void;
};

export const AddAddendumModal: React.FC<AddAddendumModalProps> = ({
  note,
  show,
  onClose,
}) => {
  const { user } = useAuth();

  return (
    <Modal show={show} onClose={onClose} width="w-5/6">
      <section className="bg-slate-200">
        <header className="border-b border-slate-500 py-3 px-5 bg-slate-300">
          <h2 className="text-lg font-semibold mb-2">Add addendum</h2>

          <div className="flex flex-row w-full items-start space-x-5">
            <ul className={classNames.ul}>
              <li>
                <strong>Author: </strong>
                {user?.fullName}
              </li>
              <li>
                <strong>Author type: </strong>
                <AuthorTypeTag author={user} />
              </li>
            </ul>
          </div>
        </header>

        <div className="px-5 py-3 space-y-3">
          <p className="text-sm">
            This is for internal use only. Other admins, practitioners and
            coaches will be able to see these notes.
          </p>
          <div>
            <h3 className={classNames.h3}>Original Note</h3>
            <div className="max-h-32 overflow-y-scroll border border-slate-500 px-4 py-3 text-slate-700">
              <Markdown src={note.body ?? ''} />
            </div>
          </div>

          <UpsertNoteForm
            title="Addendum"
            onCompleted={onClose}
            noteInput={{
              targetType: 'NOTE',
              targetId: note.id,
              domain: note.domain,
            }}
          />
        </div>
      </section>
    </Modal>
  );
};
