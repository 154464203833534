import { ProblemType } from 'graphql/types';
import clsx from 'clsx';
import { formatDistanceToNow } from 'date-fns';
import React, { ReactElement } from 'react';
import { PatientMessage } from './patient-message';
import { Question } from './types';
import { isNoReplyAnswer } from './utils';
import { DoctorMessage } from './doctor-message';

const Message = ({
  name,
  direction,
  children,
  timestamp,
}: {
  name: string;
  direction: 'send' | 'receive';
  children?: React.ReactChild;
  timestamp?: Date;
}): JSX.Element => {
  const baseClass = 'rounded-xl w-4/5 py-2 px-4 mb-2';
  const sendClass = 'bg-primary text-white self-end';
  const receiveClass = 'bg-white text-black border border-slate-400 self-start';

  return (
    <div className="flex flex-col mb-2">
      <div
        className={clsx(
          'flex w-4/5 justify-between mb-2',
          direction === 'send' ? 'self-end' : 'self-start',
        )}
      >
        <div>{name}</div>
        {timestamp && (
          <div>{formatDistanceToNow(timestamp, { addSuffix: true })}</div>
        )}
      </div>
      <div
        className={clsx(
          baseClass,
          direction === 'send' ? sendClass : receiveClass,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const Messages = ({
  questions,
  patientName,
  customerId,
  problemType,
}: {
  questions: Question[];
  patientName: string;
  customerId: string;
  problemType: ProblemType;
}): ReactElement => {
  questions.sort((a, b) => {
    return (
      new Date(a.data.createdAt).getTime() -
      new Date(b.data.createdAt).getTime()
    );
  });

  return (
    <div className="px-4 pb-4">
      {questions.map((q) => {
        const doctorName =
          'doctor' in q.data && q.data.doctor
            ? q.data.doctor.clinicianName
            : "Practitioner's Question";
        return q.doctorInitiated ? (
          <div key={q.data.id}>
            <Message
              name={doctorName ?? 'Unknown'}
              timestamp={new Date(q.data.createdAt)}
              direction={'send'}
            >
              <DoctorMessage question={q} />
            </Message>
            {!isNoReplyAnswer(q.data) &&
              (q.data.answer ? (
                <Message
                  name={patientName}
                  timestamp={new Date(q.data.answer?.updatedAt)}
                  direction={'receive'}
                >
                  <PatientMessage
                    question={q}
                    customerId={customerId}
                    problemType={problemType}
                  />
                </Message>
              ) : (
                <Message name={patientName} direction={'receive'}>
                  <span className="text-slate-500">{`${patientName} has not answered your question yet.`}</span>
                </Message>
              ))}
          </div>
        ) : (
          <div key={q.data.id}>
            <Message
              name={patientName}
              timestamp={new Date(q.data.updatedAt)}
              direction={'receive'}
            >
              <DoctorMessage question={q} />
            </Message>
          </div>
        );
      })}
    </div>
  );
};
