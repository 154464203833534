import React from 'react';
import { useForm } from 'react-hook-form-6';
import { useMutation } from '@apollo/client';
import {
  PauseTreatmentMutation,
  PauseTreatmentMutationVariables,
} from 'graphql/types';
import { Dropdown } from 'components/dropdown';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { TextArea } from 'components/text-area';
import { useNotifications } from 'notifications';
import { config } from 'config';
import { CustomerTreatmentView } from '../types';
import { getPauseReasons } from './utils';
import { pauseTreatmentDocument } from './graphql-documents';

type PauseTreatmentSubmit = {
  reason: string;
  reasonExtended?: string;
};

export const PauseModal = ({
  pauseActive,
  setPauseActive,
  refetchCustomer,
  treatment,
}: {
  pauseActive: boolean;
  setPauseActive: (active: boolean) => void;
  refetchCustomer: () => void;
  treatment: CustomerTreatmentView;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const [pauseTreatment] = useMutation<
    PauseTreatmentMutation,
    PauseTreatmentMutationVariables
  >(pauseTreatmentDocument);
  const {
    control,
    handleSubmit,
    register,
    formState: { isSubmitting, isValid },
  } = useForm<PauseTreatmentSubmit>({
    mode: 'onChange',
  });

  const handlePause = async ({
    reason,
    reasonExtended,
  }: PauseTreatmentSubmit): Promise<void> => {
    await pauseTreatment({
      variables: {
        id: treatment.id,
        reason,
        ...(reasonExtended ? { reasonExtended } : {}),
      },
    });
    showNotification({
      type: 'success',
      message: 'Treatment successfully paused',
    });
    refetchCustomer();
    setPauseActive(false);
  };

  const options = getPauseReasons(treatment.type, config.brand).map(
    (reason) => ({
      label: reason,
      value: reason,
    }),
  );

  return (
    <Modal
      show={pauseActive}
      onClose={(): void => setPauseActive(false)}
      isAutoOverflow={false}
    >
      <div className="bg-white shadow rounded px-4 py-5 max-w-lg mr-auto ml-auto">
        <form onSubmit={handleSubmit(handlePause)}>
          <h3 className="text-lg leading-6 font-medium text-slate-900 mb-8">
            Please add a reason to improve our data
          </h3>
          <Dropdown
            label="Select Reason"
            name="reason"
            control={control}
            options={options}
            rules={{ required: true }}
          />
          <TextArea
            rows={4}
            ref={register}
            placeholder="Additional notes"
            name="reasonExtended"
          />
          <div className="flex bg-slate-100 mt-6">
            <Button
              fullWidth
              variant="outline"
              onClick={(): void => setPauseActive(false)}
            >
              Cancel
            </Button>
            <div className="mx-2" />
            <Button
              fullWidth
              type="submit"
              color="success"
              disabled={!isValid || isSubmitting}
            >
              Confirm Pause
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
