import { ReactElement } from 'react';
import { Drawer } from 'components/drawer';
import { AuthorTypeTag } from 'components/tags/author-type-tag';
import { MedicalRecordField } from 'graphql/types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from 'components/table';
import { CellProps, Column } from 'react-table';
import { getAuthorDisplayName } from 'utils/get-author-display-name';
import { formatDateAndTime } from 'utils/misc';
import { Customer } from '.././types';
import { Button } from 'components/button';

const mapMedicalRecordField = (
  field: MedicalRecordField | null | undefined,
): string => {
  switch (field) {
    case 'ALLERGY_INTOLERANCE':
      return 'Allergy intolerance';
    case 'BMI':
      return 'BMI';
    case 'BODY_WEIGHT':
      return 'Body weight';
    case 'DATE_OF_BIRTH':
      return 'Date of birth';
    case 'HEIGHT':
      return 'Height';
    case 'MEDICAL_CONDITION':
      return 'Condition';
    case 'MEDICATION_USAGE':
      return 'Medication usage';
    case 'PREGNANCY_STATUS':
      return 'Pregnancy status';
    case 'SEX':
      return 'Sex';
    case 'REQUIRES_CLINICAL_REVIEW':
      return 'Requires Clinical Review';
    case 'UNDERGOING_WEIGHT_MAINTENANCE':
      return 'Maintenance Programme';
    default:
      return 'Unknown';
  }
};

type MedicalRecordHistory = NonNullable<Customer['medicalRecordHistory']>;

const FieldEditedCell = ({
  value,
}: {
  value: MedicalRecordHistory[number]['field'];
}): ReactElement => <p className="font-bold">{mapMedicalRecordField(value)}</p>;

const AuthorRoleCell = ({
  value,
}: {
  value: MedicalRecordHistory[number]['updatedBy'];
}): ReactElement => {
  return <AuthorTypeTag author={value} />;
};

const UpdatedInfoCell = ({
  value,
}: CellProps<
  MedicalRecordHistory[number],
  MedicalRecordHistory[number]
>): React.ReactElement => {
  const { updatedAt, updatedBy } = value;
  return (
    <div className="text-xs">
      {updatedAt ? formatDateAndTime(updatedAt) : null}
      {updatedBy && (
        <>
          <br />
          By {getAuthorDisplayName(updatedBy)}
        </>
      )}
    </div>
  );
};

const ValueCell = ({
  value,
}: {
  value: string | null | undefined;
}): ReactElement => {
  if (value === null || value === 'null' || value === '') {
    return <p className=" text-slate-600">-</p>;
  }
  return <div>{value}</div>;
};

const columns: Column<MedicalRecordHistory[number]>[] = [
  {
    Header: 'Field Edited',
    accessor: 'field',
    Cell: FieldEditedCell,
    disableSortBy: true,
  },
  {
    Header: 'Previous',
    accessor: 'previousValue',
    Cell: ValueCell,
    disableSortBy: true,
  },
  {
    Header: 'New',
    accessor: 'currentValue',
    Cell: ValueCell,
    disableSortBy: true,
  },
  {
    Header: 'Updated At',
    accessor: (row): MedicalRecordHistory[number] => row,
    Cell: UpdatedInfoCell,
    disableSortBy: true,
  },
  {
    Header: 'Author Role',
    accessor: 'updatedBy',
    Cell: AuthorRoleCell,
    disableSortBy: true,
  },
];

const MedicalHistoryTable = ({
  medicalRecordHistory,
}: {
  medicalRecordHistory: MedicalRecordHistory;
}): ReactElement => {
  const tableInstance = usePaginatingSortingTable({
    columns,
    data: medicalRecordHistory,
    pageNumber: 1,
  });

  if (!medicalRecordHistory.length) {
    return (
      <p className=" text-slate-700 px-4 py-6 bg-white shadow-md w-full">
        No information has been updated
      </p>
    );
  }

  return (
    <Table tableInstance={tableInstance}>
      <TableHead />
      <TableBody>
        <>
          {tableInstance.page?.map((row) => {
            tableInstance.prepareRow(row);
            return (
              <TableRow row={row} key={row.id}>
                <>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                    />
                  ))}
                </>
              </TableRow>
            );
          })}
        </>
      </TableBody>
    </Table>
  );
};

export const MedicalHistoryDrawer = ({
  show,
  onClose,
  medicalRecordHistory,
}: {
  show: boolean;
  onClose: () => void;
  medicalRecordHistory: MedicalRecordHistory;
}): ReactElement => {
  return (
    <Drawer show={show} onClose={onClose} format="wide">
      <div className="h-full overflow-scroll">
        <div className="flex justify-between top-0 bg-slate-200 p-5">
          <h1 className="text-2xl font-semibold text-slate-900 mb-2">
            Medical history
          </h1>
          <div>
            <Button fullWidth variant="outline" onClick={(): void => onClose()}>
              Close
            </Button>
          </div>
        </div>
        <section className="flex flex-col bg-slate-200">
          <div className="flex flex-col mx-5 pb-5 whitespace-pre-wrap">
            <MedicalHistoryTable medicalRecordHistory={medicalRecordHistory} />
          </div>
        </section>
      </div>
    </Drawer>
  );
};
