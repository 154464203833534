import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Label } from 'components/label';
import { InputError } from 'components/input-error';
import { TooltipProps } from '../tooltip';

interface Props {
  label?: string;
  placeholder?: string;
  defaultValue?: string | number;
  type?: string;
  name: string;
  errorMessage?: string | JSX.Element;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isDirty?: boolean;
  step?: number;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  hint?: string;
  maxLength?: number;
  min?: number;
  description?: string;
  tooltipProps?: TooltipProps;
}

const BaseInput = (
  {
    label,
    placeholder,
    type = 'text',
    name,
    errorMessage,
    autoFocus,
    disabled = false,
    readOnly = false,
    isDirty = false,
    defaultValue,
    step,
    onChange,
    hint,
    maxLength,
    min,
    description,
    tooltipProps,
  }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => {
  // prevent trackpad scroll changing input value when field is in focus
  useEffect(() => {
    const input = document.getElementById(name);
    if (type === 'number') {
      input?.addEventListener('mousewheel', () => input.blur());
    }
    return (): void =>
      input?.removeEventListener('mousewheel', () => input.blur());
  }, [type, name]);

  return (
    <div>
      {(label || description) && (
        <div className="mb-3 flex flex-col gap-1">
          {label && (
            <Label htmlFor={name} tooltip={tooltipProps ?? undefined}>
              {label}
            </Label>
          )}
          {description && (
            <p className="text-sm text-slate-700">{description}</p>
          )}
        </div>
      )}
      <input
        autoFocus={autoFocus}
        id={name}
        ref={ref}
        name={name}
        className={clsx(
          'appearance-none',
          'border',
          'rounded-md',
          'w-full',
          'px-4',
          'py-3',
          'focus:outline-none',
          'transition-shadow',
          'duration-200',
          errorMessage
            ? 'border-red-500 focus:border-red-500 focus:ring ring-red-500 ring-opacity-50'
            : 'border-slate-300 focus:border-primary focus:ring ring-primary ring-opacity-50',
          disabled || readOnly
            ? 'bg-slate-50 text-slate-700 cursor-not-allowed'
            : 'bg-white text-slate-900',
          isDirty && 'border-purple-800',
        )}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        defaultValue={defaultValue}
        step={step}
        min={min}
        onChange={onChange}
        maxLength={maxLength}
      />
      {hint && <p className="text-xs mt-2 text-slate-600">{hint}</p>}
      <InputError>{errorMessage}</InputError>
    </div>
  );
};

export const Input = React.forwardRef<HTMLInputElement, Props>(BaseInput);
