import React from 'react';
import { ModalLayout } from './modal-layout';
import { MarkdownEditor } from 'components/markdown-editor';
import { requiredValidation } from 'utils/form-validation';
import { useAuth } from 'auth';
import { UseFormMethods } from 'react-hook-form-6';
import { AuthorTypeTag } from 'components/tags/author-type-tag';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { ProblemType } from 'graphql/types';
import { DispatchBatchStepTransitionProps } from './types';

type AdminNotesStepProps = DispatchBatchStepTransitionProps & {
  notes: string;
  skipQuiz: boolean;
  problemType: ProblemType;
} & Pick<UseFormMethods<{ adminNotes: string }>, 'register' | 'errors'>;

export const AdminNotesStep: React.FC<AdminNotesStepProps> = ({
  previousStep,
  skipQuiz,
  nextStep,
  register,
  notes,
  errors,
  problemType,
}) => {
  const { user } = useAuth();

  return (
    <ModalLayout
      nextDisabled={skipQuiz && notes.trim().length === 0}
      back={previousStep}
      next={nextStep}
    >
      <div className="w-full items-start bg-slate-300 px-4 py-2">
        <h2 className="mb-1">Add consultation Notes</h2>
        <div className="flex text-sm gap-4 items-center">
          <div>
            <strong>Author: </strong>
            {user?.fullName}
          </div>
          <div>
            <strong>Author type: </strong>
            <AuthorTypeTag author={user} />
          </div>
          <div>
            <strong>Problem type: </strong>
            <ProblemTypeTag problemType={problemType} />
          </div>
        </div>
      </div>
      <div className="border-b border-slate-500" />
      <div className="w-full bg-slate-200 space-y-3 px-4 py-2">
        <div className="text-sm">
          <p>This note will be added to all created review consultations</p>
        </div>
        <div className="flex flex-col w-full space-y-4 gap-2">
          <MarkdownEditor
            ref={
              skipQuiz ? register(requiredValidation('adminNotes')) : register()
            }
            name="adminNotes"
            markdown={notes}
            errorMessage={errors.adminNotes?.message}
          />
        </div>
      </div>
    </ModalLayout>
  );
};
