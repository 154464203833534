import { AdminRole, Maybe, UserRole } from 'graphql/types';

const classNames = {
  pill: `px-2 py-1 inline-flex text-xs leading-5 font-semibold bg-slate-200 rounded`,
};

export const AuthorTypeTag: React.FC<{
  author: Maybe<{
    role?: UserRole;
    roles?: Maybe<Array<{ value: AdminRole }>>;
  }>;
}> = ({ author }) => {
  if (!author || !author.role) {
    return '-';
  }

  if (author.role === 'CUSTOMER') {
    return <div className={classNames.pill}>💖 Customer</div>;
  }

  if (author.role === 'DOCTOR') {
    return <div className={classNames.pill}>👩‍⚕️ Practitioner</div>;
  }

  if (author.role === 'HEALTH_COACH') {
    return <div className={classNames.pill}>🍏 Health Coach</div>;
  }
  if (author.role === 'PHARMACY') {
    return <div className={classNames.pill}>💊 Pharmacy</div>;
  }

  if (author.role === 'SUPERADMIN') {
    if (author.roles?.find((r) => r.value === 'CLINICAL_OPS')) {
      return <div className={classNames.pill}>⛑️ Admin - Clinical</div>;
    }
    return <div className={classNames.pill}>💜 Admin - Other</div>;
  }

  return '-';
};
