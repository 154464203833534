import { gql } from '@apollo/client';
import { Modal } from 'components/modal';
import { NoteHistoryFragment } from 'graphql/types';
import { getAuthorDisplayName } from 'utils/get-author-display-name';
import { formatDateAndTime } from 'utils/misc';
import { Markdown } from 'components/markdown';

export const noteHistoryDocument = gql`
  fragment NoteHistory on Note {
    id
    history {
      id
      body
      createdAt
      author {
        id
        role
        fullName
        clinicianName
      }
    }
  }
`;

export type NoteHistoryModalProps = {
  title: 'Critical Note' | 'Note';
  show: boolean;
  onClose: () => void;
  note: NoteHistoryFragment;
};

export const NoteHistoryModal: React.FC<NoteHistoryModalProps> = ({
  title,
  show,
  onClose,
  note,
}) => {
  const history = note.history ?? [];

  return (
    <Modal show={show} onClose={onClose} width="w-5/6">
      <>
        <header className="border-b border-slate-500 p-5 bg-slate-300">
          <h2 className="text-lg font-medium leading 6">
            {title} History{' '}
            <span className="ml-2 text-slate-500">({history.length})</span>
          </h2>
        </header>
        <div className="bg-white p-5 space-y-4">
          {history.map((h) => (
            <HistoryChange key={h.id} entry={h} />
          ))}
        </div>
      </>
    </Modal>
  );
};

type NoteHistoryEntry = NonNullable<NoteHistoryFragment['history']>[number];

const HistoryChange: React.FC<{
  entry: NoteHistoryEntry;
}> = ({ entry }) => {
  return (
    <div className="border border-slate-500">
      <div className="bg-slate-200 border-b border-slate-500 py-2 px-3 text-sm font-semibold">
        Updated: {getAuthorDisplayName(entry.author)},{' '}
        {formatDateAndTime(entry.createdAt)}
      </div>
      <div className="flex divide-x divide-slate-500">
        <div className="px-3 py-2">
          {entry.body ? <Markdown src={entry.body} /> : '-'}
        </div>
      </div>
    </div>
  );
};
