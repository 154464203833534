import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  EScriptStatus,
  ScriptPageQuery,
  ScriptPageQueryVariables,
} from 'graphql/types';
import { AuditLogTable } from 'components/audit/audit-log-table';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import { Link, useParams } from 'react-router-dom';
import { Card } from '../../components/card';
import { ReissueTokenModal } from './reissue-token-modal';
import { BandedRow } from 'pages/customer/bandedRow';
import { buildRoute } from 'utils/routes';
import { Colors, formatDateAndTime } from 'utils/misc';
import { Tag } from 'components/tag';
import { Snipplet } from 'components/snipplet';
import { GenerateNewScriptModal } from './generate-new-script-modal';
import { ConvertEScriptToPaperModal } from './convert-to-paper-modal';

const scriptQuery = gql`
  query ScriptPage($scriptId: String!) {
    script(where: { id: $scriptId }) {
      id
      dob
      repeats
      currentMedications
      allergies
      existingConditions
      isDirect
      reg24
      type
      createdAt
      product {
        id
      }
      eScripts {
        id
        createdAt
        initialSCID
        repeatSCIDs
        cancelReason
        url
        status
      }
      patient {
        id
        email
        phone
      }
      scriptUrl
      unsignedScriptUrl
      signedScriptUrl
    }
    scriptV2(id: $scriptId) {
      id
      allowEOP
    }
  }
`;

const ScriptComponent = (): React.ReactElement => {
  const { scriptId } = useParams<{ scriptId: string }>();

  const [showConvertToPaperModal, setConvertToPaperModal] =
    React.useState(false);
  const [showGenerateNewScriptModal, setShowGenerateNewScriptModal] =
    React.useState(false);
  const [showReissueTokenModal, setShowReissueTokenModal] =
    React.useState(false);

  const {
    data: scriptData,
    loading: loadingScriptData,
    refetch,
  } = useQuery<ScriptPageQuery, ScriptPageQueryVariables>(scriptQuery, {
    variables: {
      scriptId,
    },
  });

  const isEscript = scriptData?.script?.type === 'ESCRIPT';

  if (loadingScriptData) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <Card className="divide-y divide-slate-300">
        <div className="p-5 mr-2 flex flex-row space-x-4">
          <h2 className="text-lg font-semibold text-slate-900">Script</h2>
          <ScriptTypeTag type={scriptData?.script?.type} />
          {scriptData?.script?.isDirect && (
            <Tag size="small" color="orange">
              DIRECT
            </Tag>
          )}
          {scriptData?.script?.reg24 && (
            <Tag size="small" color="indigo">
              REG 24
            </Tag>
          )}
        </div>
        <div className="grid grid-cols-2">
          <BandedRow
            bgColor="grey"
            label="ID"
            value={scriptData?.script?.id}
            placeholderValue="-"
          />
          <BandedRow
            bgColor="grey"
            label="Patient"
            value={
              scriptData?.script?.patient.id && (
                <Link to={buildRoute.customer(scriptData?.script?.patient.id)}>
                  <span className="underline">
                    {scriptData?.script?.patient.id}
                  </span>
                </Link>
              )
            }
            placeholderValue="-"
          />
          <BandedRow
            bgColor="white"
            label="Patient DOB"
            value={scriptData?.script?.dob}
            placeholderValue="-"
          />
          <BandedRow
            bgColor="white"
            label="State"
            value={<ScriptStateTag script={scriptData?.script} />}
            placeholderValue="-"
          />
          <BandedRow
            bgColor="grey"
            label="Product"
            value={
              scriptData?.script?.product?.id && (
                <Link to={buildRoute.product(scriptData?.script?.product.id)}>
                  <span className="underline">
                    {scriptData?.script?.product.id}
                  </span>
                </Link>
              )
            }
            placeholderValue="-"
          />
          <BandedRow
            bgColor="grey"
            label="Created At"
            value={
              scriptData?.script?.createdAt
                ? formatDateAndTime(scriptData?.script?.createdAt)
                : null
            }
            placeholderValue="-"
          />
          <BandedRow
            bgColor="white"
            label="Repeats"
            value={scriptData?.script?.repeats.toString()}
            placeholderValue="-"
          />
          <BandedRow
            bgColor="white"
            label="Current Medications"
            value={scriptData?.script?.currentMedications}
            placeholderValue="-"
          />
          <BandedRow
            bgColor="grey"
            label="Allergies"
            value={scriptData?.script?.allergies}
            placeholderValue="-"
          />
          <BandedRow
            bgColor="grey"
            label="Existing Conditions"
            value={scriptData?.script?.existingConditions}
            placeholderValue="-"
          />
        </div>
        {scriptData?.script &&
          ['LEGACY', 'PAPER'].includes(scriptData.script.type ?? '') && (
            <div className="grid grid-cols-1">
              <BandedRow
                bgColor="grey"
                label="Paper URL"
                value={
                  <Snipplet
                    text={
                      scriptData.script.signedScriptUrl ??
                      scriptData.script.unsignedScriptUrl ??
                      scriptData.script.scriptUrl ??
                      `Could not find a signed, or unsigned, or legacy URL for this script`
                    }
                    type="link"
                  />
                }
                placeholderValue="-"
              />
            </div>
          )}
        <div className="p-4 space-x-3 flex flex-row">
          <Button
            variant="outline"
            disabled={isEscript}
            onClick={() => setShowGenerateNewScriptModal(true)}
          >
            Generate new script
          </Button>
          <Button
            variant="outline"
            disabled={!isEscript}
            onClick={() => setConvertToPaperModal(true)}
          >
            Convert to paper
          </Button>
          <Button
            variant="outline"
            disabled={!isEscript || !scriptData?.scriptV2?.allowEOP}
            onClick={() => setShowReissueTokenModal(true)}
          >
            Re-issue Token(s)
          </Button>
        </div>
      </Card>

      {!!scriptData?.script?.eScripts?.length &&
        scriptData.script.eScripts.map((e) => {
          return (
            <Card key={e.id} className="divide-y divide-slate-300">
              <div className="px-4 py-5 space-y-2">
                <h2 className="text-lg font-semibold mr-2 text-slate-900">
                  E Script
                </h2>
              </div>
              <div className="grid grid-cols-2">
                <BandedRow
                  bgColor="grey"
                  label="ID"
                  value={e.id}
                  placeholderValue="-"
                />
                <BandedRow
                  bgColor="grey"
                  label="Created At"
                  value={formatDateAndTime(e.createdAt)}
                  placeholderValue="-"
                />
                <BandedRow
                  bgColor="white"
                  label="Status"
                  value={<EScriptStatusTag status={e.status} />}
                  placeholderValue="-"
                />
                <BandedRow
                  bgColor="white"
                  label="Cancel Reason"
                  value={e.cancelReason}
                  placeholderValue="-"
                />
                <BandedRow
                  bgColor="grey"
                  label="Initial SCID"
                  value={e.initialSCID}
                  placeholderValue="-"
                />
                <BandedRow
                  bgColor="grey"
                  label="Repeat SCIDs"
                  value={
                    e.repeatSCIDs?.length ? e.repeatSCIDs.join(', ') : null
                  }
                  placeholderValue="None"
                />
              </div>
              <BandedRow
                bgColor="grey"
                label="URL"
                value={e.url ? <Snipplet text={e.url} type="link" /> : null}
                placeholderValue="No URL for e-script"
              />
            </Card>
          );
        })}

      <div className="bg-white shadow overflow-hidden rounded mt-5">
        <AuditLogTable targetId={scriptId} targetType="SCRIPT" />
      </div>

      <GenerateNewScriptModal
        scriptId={scriptId}
        show={showGenerateNewScriptModal}
        onConfirm={() => {
          setShowGenerateNewScriptModal(false);
          refetch();
        }}
        onClose={() => setShowGenerateNewScriptModal(false)}
      />
      <ConvertEScriptToPaperModal
        scriptId={scriptId}
        show={showConvertToPaperModal}
        onConfirm={() => {
          setConvertToPaperModal(false);
          refetch();
        }}
        onClose={() => setConvertToPaperModal(false)}
      />
      <ReissueTokenModal
        patientMobile={scriptData?.script?.patient.phone ?? undefined}
        scriptId={scriptId}
        show={showReissueTokenModal}
        onConfirm={() => {
          setShowReissueTokenModal(false);
          refetch();
        }}
        onClose={() => setShowReissueTokenModal(false)}
      />
    </div>
  );
};

export default ScriptComponent;

const ScriptStateTag = ({ script }: { script?: ScriptPageQuery['script'] }) => {
  switch (script?.type) {
    case 'LEGACY': {
      return (
        <Tag size="small" color="gray">
          LEGACY
        </Tag>
      );
    }
    case 'ESCRIPT': {
      return (
        <Tag size="small" color="green">
          SIGNED
        </Tag>
      );
    }
    case 'PAPER': {
      if (script?.signedScriptUrl) {
        return (
          <Tag size="small" color="green">
            SIGNED
          </Tag>
        );
      } else {
        return (
          <Tag size="small" color="green">
            UNSIGNED
          </Tag>
        );
      }
    }
  }
};

const ScriptTypeTag = ({
  type,
}: {
  type?: NonNullable<ScriptPageQuery['script']>['type'];
}): JSX.Element => {
  let tagColor: Colors = 'gray';

  switch (type) {
    case 'LEGACY':
      tagColor = 'green';
      break;
    case 'PAPER':
      tagColor = 'yellow';
      break;
    case 'ESCRIPT':
      tagColor = 'blue';
      break;
    default:
      tagColor = 'gray';
      break;
  }

  return (
    <Tag size="small" color={tagColor}>
      {type}
    </Tag>
  );
};

const EScriptStatusTag = ({
  status,
}: {
  status: EScriptStatus;
}): JSX.Element => {
  let tagColor: Colors = 'gray';

  switch (status) {
    case 'ACTIVE':
      tagColor = 'green';
      break;
    case 'CANCELLED':
      tagColor = 'red';
      break;
    case 'CEASED':
      tagColor = 'teal';
      break;
    case 'ARCHIVED':
      tagColor = 'blue';
      break;
    default:
      tagColor = 'gray';
      break;
  }

  return (
    <Tag size="small" color={tagColor}>
      {status}
    </Tag>
  );
};
