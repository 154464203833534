import { useNotifications } from 'notifications';
import { MouseEvent as ReactMouseEvent, ReactElement } from 'react';

export const Snipplet = ({
  text,
  type,
}: {
  text: string;
  type: 'copy' | 'link';
}): ReactElement => {
  const showNotification = useNotifications();

  const handleClick =
    type === 'copy'
      ? async (
          event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>,
        ): Promise<void> => {
          event.preventDefault();
          await navigator.clipboard.writeText(text);
          showNotification({
            type: 'info',
            message: 'Text copied to clipboard',
          });
        }
      : undefined;

  return (
    <a
      className="block bg-slate-300 p-2 break-all text-slate-800 cursor-pointer"
      onClick={handleClick}
      href={text}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );
};
