import { gql, useQuery } from '@apollo/client';
import { BandedRow } from 'pages/customer/bandedRow';
import { Card } from 'components/card';
import React, { useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import {
  GetStockoutToolingBatchJobsQuery,
  GetStockoutToolingBatchJobsQueryVariables,
  GetStockoutToolingBatchOverviewQuery,
  GetStockoutToolingBatchOverviewQueryVariables,
} from 'graphql/types';
import { Loading } from 'components/loading';
import { Tag } from 'components/tag';
import { BulkReviewButton } from './dispatch-batch/bulk-review-button';
import { Copyable } from 'components/copyable';
import { BulkPauseButton } from './dispatch-batch/bulk-pause-button';
import { buildRoute } from 'utils/routes';
import { formatWindowDay, formatWindowInterval } from 'utils/queues';
import { DisplayableFilter } from './dispatch-batch/types';
import { validate } from 'uuid';
import { formatDateAndTime } from 'utils/misc';
import { batchStatusMap } from 'pages/batch-jobs';

export default function BatchJob(): React.ReactElement {
  const { batchJobId } = useParams<{ batchJobId: string }>();

  const { data: overviewQueryData, loading: overviewQueryLoading } = useQuery<
    GetStockoutToolingBatchOverviewQuery,
    GetStockoutToolingBatchOverviewQueryVariables
  >(
    gql`
      query GetStockoutToolingBatchOverview($id: ID!) {
        stockoutToolingBatch(id: $id) {
          id
          author {
            id
            fullName
          }
          status
          completedPercentage
          failureCount
          ... on CreateReviewConsultationStockoutToolingBatch {
            filters {
              ... on CreateReviewConsultationFilterByCustomers {
                problemType
              }
              ... on CreateReviewConsultationFilterByProperties {
                nextOrderDueBefore
                nextOrderDueAfter
                nextOrderContainsVariants {
                  id
                  name
                  inventory {
                    id
                    sku
                  }
                  product {
                    id
                    name
                    productType
                  }
                }
                statuses
                problemType
                lastAssignedDoctors {
                  id
                  fullName
                }
              }
            }
          }
          ... on PausePurchaseStockoutToolingBatch {
            filters {
              ... on PausePurchaseFilterByCustomers {
                problemType
              }
              ... on PausePurchaseFilterByProperties {
                nextOrderDueBefore
                nextOrderDueAfter
                nextOrderContainsVariants {
                  id
                  name
                  inventory {
                    id
                    sku
                  }
                  product {
                    id
                    name
                    productType
                  }
                }
                problemType
                lastAssignedDoctors {
                  id
                  fullName
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: batchJobId,
      },
      pollInterval: 5000,
      skip: !validate(batchJobId),
    },
  );

  const batch = overviewQueryData?.stockoutToolingBatch;

  let skipJobsQuery = false;
  if (overviewQueryData?.stockoutToolingBatch.status === 'BUILDING') {
    skipJobsQuery = true;
  }

  const [jobsQueryPollInterval, setJobsQueryPollInterval] = useState(0);
  const { data: jobsQueryData, loading: jobsQueryLoading } = useQuery<
    GetStockoutToolingBatchJobsQuery,
    GetStockoutToolingBatchJobsQueryVariables
  >(
    gql`
      query GetStockoutToolingBatchJobs($id: ID!) {
        stockoutToolingBatch(id: $id) {
          id
          ... on CreateReviewConsultationStockoutToolingBatch {
            jobs {
              id
              processedAt
              problemType
              failureDescription
              customer {
                id
                fullName
              }
              bookingWindow {
                id
                startAt
                endAt
              }
              state
              doctorToAssign {
                id
                fullName
              }
            }
          }
          ... on PausePurchaseStockoutToolingBatch {
            jobs {
              id
              processedAt
              state
              failureDescription
              purchase {
                customer {
                  id
                  fullName
                }
                id
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: batchJobId,
      },
      skip: skipJobsQuery || !validate(batchJobId),
      pollInterval: jobsQueryPollInterval,
    },
  );

  if (
    overviewQueryData?.stockoutToolingBatch.status === 'DISPATCHED' &&
    jobsQueryData?.stockoutToolingBatch.jobs.some(
      (j) => j.state === 'PENDING',
    ) &&
    jobsQueryPollInterval === 0
  ) {
    setJobsQueryPollInterval(5000);
  }

  const jobs = jobsQueryData?.stockoutToolingBatch.jobs;

  if (overviewQueryLoading) {
    return <Loading />;
  }

  if (!batch) {
    return <div>Unable to find batch with id {batchJobId}</div>;
  }

  const headers = ['ID', 'Status', 'Customer'];

  let batchType: string;
  switch (batch.__typename) {
    case 'PausePurchaseStockoutToolingBatch':
      batchType = 'Pause Customer Purchases';
      headers.push('Purchase');
      break;
    case 'CreateReviewConsultationStockoutToolingBatch':
      batchType = 'Create Review Consultations';
      if (batch.status === 'DISPATCHED') {
        headers.push('Assigned practitioner', 'Booking window');
      }
      break;
    default:
      batchType = `Unexpected batch type: ${batch.__typename}`;
  }

  if (batch.status === 'DISPATCHED') {
    headers.push('Completed');
    headers.push('Failure description');
  }

  return (
    <>
      <Card key={batch.id} className="divide-y divide-slate-300">
        <div className="px-4 py-5 space-y-2">
          <h2 className="text-sm text-slate-500">Batch Job</h2>
          <div className="flex w-full justify-between">
            <div className="flex justify-end w-full items-center">
              <div className="flex w-full items-center">
                <h2 className="text-lg font-semibold mr-2 text-slate-900">
                  {batchType}
                </h2>
                <Tag
                  size="small"
                  color={batch.status === 'ERROR_BUILDING' ? 'red' : 'green'}
                >
                  {batchStatusMap.get(batch.status)}
                </Tag>
              </div>
              {batch.filters && (
                <div>
                  {batch.status === 'DISPATCHED' && (
                    <div>{batch.completedPercentage}%</div>
                  )}
                  {batch.status === 'PENDING_DISPATCH' &&
                    jobs &&
                    jobs.length > 0 && (
                      <>
                        {batch.__typename ===
                          'CreateReviewConsultationStockoutToolingBatch' && (
                          <BulkReviewButton
                            problemType={batch.filters.problemType}
                            batchId={batch.id}
                            jobCount={jobs.length}
                            displayableFilters={filterAndStringifyFilters(
                              batch.filters,
                            )}
                          />
                        )}
                        {batch.__typename ===
                          'PausePurchaseStockoutToolingBatch' && (
                          <BulkPauseButton
                            batchId={batch.id}
                            jobCount={jobs.length}
                            displayableFilters={filterAndStringifyFilters(
                              batch.filters,
                            )}
                          />
                        )}
                      </>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <BandedRow
            bgColor="grey"
            label="Batch ID"
            value={batchJobId}
            placeholderValue="-"
          />
          <BandedRow
            bgColor="grey"
            label="Created By"
            value={batch.author?.fullName}
            placeholderValue="-"
          />
        </div>
      </Card>
      {(batch.filters.__typename ===
        'CreateReviewConsultationFilterByProperties' ||
        batch.filters.__typename === 'PausePurchaseFilterByProperties') && (
        <>
          <div className="p-4" />
          <Card
            key="pause-purchase-filters"
            className="divide-y divide-slate-300"
          >
            <div className="px-4 py-2 space-y-2">
              <h2 className="text-sm text-slate-500">Filters</h2>
            </div>
            <div className="grid grid-cols-2">
              <BandedRow
                bgColor="grey"
                label="Problem Type"
                value={batch.filters.problemType}
                placeholderValue="-"
              />
              <BandedRow
                bgColor="grey"
                label="Next Order Contains"
                value={
                  <>
                    {...batch.filters.nextOrderContainsVariants?.map((v) => (
                      <div key={v.id}>
                        <Link to={`/products/${v.product.id}`}>
                          {v.product.name}
                        </Link>
                      </div>
                    )) || [<>-</>]}
                  </>
                }
                placeholderValue="-"
              />
              <BandedRow
                bgColor="white"
                label="Next Order To Ship Before"
                value={batch.filters.nextOrderDueBefore}
                placeholderValue="-"
              />
              <BandedRow
                bgColor="white"
                label="Next Order To Ship After"
                value={batch.filters.nextOrderDueAfter}
                placeholderValue="-"
              />
              <BandedRow
                bgColor="grey"
                label="Last Assigned Doctors"
                value={batch.filters.lastAssignedDoctors
                  ?.map((d) => d.fullName)
                  ?.join(', ')}
                placeholderValue="-"
              />
              {batch.filters.__typename ===
              'CreateReviewConsultationFilterByProperties' ? (
                <BandedRow
                  bgColor="grey"
                  label="Purchase Statuses"
                  value={batch.filters.statuses?.join(', ')}
                  placeholderValue="-"
                />
              ) : (
                <BandedRow
                  bgColor="grey"
                  label=""
                  value={undefined}
                  placeholderValue=""
                />
              )}
            </div>
          </Card>
        </>
      )}
      {jobsQueryLoading && (
        <div className="p-4">
          <Loading />
        </div>
      )}
      {!jobsQueryLoading &&
        (jobs?.length ? (
          <>
            <div className="border-t border-slate-200">
              <div className="px-4 py-5 border-b border-slate-200">
                <h3 className="text-lg leading-6 font-medium text-slate-900">
                  Jobs{' '}
                  <span className="text-slate-500 text-sm">{`Failed: ${
                    jobs.filter((job) => job.failureDescription).length
                  }/${jobs.length}`}</span>
                </h3>
              </div>
              <div>
                <div className="-mt-2 overflow-x-auto">
                  <div className="pt-2 min-w-full">
                    <table className="min-w-full divide-y divide-slate-200">
                      <thead>
                        <tr>
                          {headers.map((h) => (
                            <th
                              key={h}
                              className="px-6 py-3 bg-slate-50 text-left text-xs leading-4 font-medium text-slate-500 uppercase tracking-wider"
                            >
                              {h}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-slate-200">
                        {jobs.map((j) => (
                          <tr key={j.id}>
                            <td className="px-6 py-2 whitespace-nowrap text-sm">
                              <Copyable text={j.id}>
                                {(copied) => (
                                  <pre className="cursor-pointer">
                                    {copied ? 'Copied' : j.id.slice(-6)}
                                  </pre>
                                )}
                              </Copyable>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm">
                              {j.state}
                            </td>
                            {j.__typename === 'PausePurchaseJob' && (
                              <>
                                <td className="px-6 py-2 whitespace-nowrap text-sm">
                                  <Link
                                    target="_blank"
                                    className="underline cursor-pointer"
                                    to={buildRoute.customer(
                                      j.purchase?.customer?.id ?? '',
                                    )}
                                  >
                                    {j.purchase?.customer?.fullName}
                                  </Link>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm">
                                  <Link
                                    target="_blank"
                                    className="underline cursor-pointer"
                                    to={buildRoute.customerPurchase(
                                      j.purchase?.customer?.id ?? '',
                                      j.purchase?.id ?? '',
                                    )}
                                  >
                                    {j.purchase?.id.slice(-6)}
                                  </Link>
                                </td>
                              </>
                            )}
                            {j.__typename === 'CreateReviewConsultationJob' && (
                              <>
                                <td className="px-6 py-2 whitespace-nowrap text-sm">
                                  <Link
                                    target="_blank"
                                    className="underline cursor-pointer"
                                    to={buildRoute.customer(
                                      j.customer?.id ?? '',
                                    )}
                                  >
                                    {j.customer?.fullName}
                                  </Link>
                                </td>
                                {batch.status === 'DISPATCHED' && (
                                  <>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm">
                                      <Link
                                        target="_blank"
                                        className="underline cursor-pointer"
                                        to={buildRoute.clinician(
                                          j.doctorToAssign?.id ?? '',
                                        )}
                                      >
                                        {j.doctorToAssign?.fullName}
                                      </Link>
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm">
                                      {j.bookingWindow
                                        ? `${formatWindowDay({
                                            startAt: j.bookingWindow.startAt,
                                          })}, ${formatWindowInterval({
                                            startAt: j.bookingWindow.startAt,
                                            endAt: j.bookingWindow.endAt,
                                          })}`
                                        : '-'}
                                    </td>
                                  </>
                                )}
                              </>
                            )}
                            {batch.status === 'DISPATCHED' && (
                              <>
                                <td className="px-6 py-2 whitespace-nowrap text-sm">
                                  {j.processedAt
                                    ? formatDateAndTime(j.processedAt)
                                    : '-'}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm">
                                  {j.state === 'FAILED'
                                    ? j.failureDescription
                                    : '-'}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="px-4 py-5 border-b border-slate-200">
            <h3 className="text-lg leading-6 font-medium text-slate-900">
              No jobs to process in batch.
            </h3>
          </div>
        ))}
    </>
  );
}

function filterAndStringifyFilters(
  filters: NonNullable<
    GetStockoutToolingBatchOverviewQuery['stockoutToolingBatch']['filters']
  >,
): DisplayableFilter[] {
  const filterArray: DisplayableFilter[] = [
    {
      title: 'Purchase Problem Type',
      values: [filters.problemType],
    },
  ];

  if (
    filters.__typename === 'CreateReviewConsultationFilterByProperties' ||
    filters.__typename === 'PausePurchaseFilterByProperties'
  ) {
    const {
      nextOrderDueBefore,
      nextOrderDueAfter,
      nextOrderContainsVariants,
      lastAssignedDoctors,
    } = filters;

    if (
      filters.__typename === 'CreateReviewConsultationFilterByProperties' &&
      filters.statuses?.length
    ) {
      filterArray.push({
        title: 'Purchase Status',
        values: [filters.statuses.join(', ')],
      });
    }

    if (nextOrderDueAfter && nextOrderDueBefore) {
      filterArray.push({
        title: `Next order is due between`,
        values: [
          `${new Date(nextOrderDueAfter).toLocaleString()} and ${new Date(
            nextOrderDueBefore,
          ).toLocaleString()}`,
        ],
      });
    } else if (nextOrderDueAfter) {
      filterArray.push({
        title: `Next order is due after`,
        values: [new Date(nextOrderDueAfter).toLocaleString()],
      });
    } else if (nextOrderDueBefore) {
      filterArray.push({
        title: `Next order is due before`,
        values: [new Date(nextOrderDueBefore).toLocaleString()],
      });
    }

    if (nextOrderContainsVariants) {
      filterArray.push({
        title: `Upcoming order contains`,
        values: nextOrderContainsVariants.map(
          (v) =>
            `${v.product.productType} - ${v.product.name} - ${v.name} - ${
              v.inventory?.sku || ''
            }`,
        ),
      });
    }

    if (lastAssignedDoctors) {
      filterArray.push({
        title: `Most recently prescribed Doctor`,
        values: lastAssignedDoctors.map((d) => d.fullName),
      });
    }
  }

  return filterArray;
}
