import { config } from 'config';
import React, { PropsWithChildren, ReactElement } from 'react';

function AuthLayout({ children }: PropsWithChildren<unknown>): ReactElement {
  return (
    <section className="w-full h-screen flex items-center justify-center">
      <div className="bg-white flex-1 shadow-card rounded-md max-w-md p-8">
        <img
          src={config.logoUrl}
          className="mx-auto h-12 mb-2 px-6"
          alt={config.brand}
        />
        <p className="text-center mb-6">{config.country}</p>
        <hr className="border-t border-slate-200 mb-6" />
        <div className="text-center">{children}</div>
      </div>
    </section>
  );
}

export default AuthLayout;
