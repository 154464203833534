import { OrdersTable } from './ordersTable';
import { OrdersTableItem } from './types';

export const ShopfrontOrdersSection = ({
  shopfrontOrders,
}: {
  shopfrontOrders: OrdersTableItem[];
}): React.ReactElement => {
  if (!shopfrontOrders.length) return <></>;

  return (
    <div className="bg-white shadow overflow-hidden rounded my-4">
      <div className="border-b border-slate-200">
        <OrdersTable
          completedOrders={shopfrontOrders}
          title="Orders without a Treatment"
          description="Including Shopfront orders & Legacy treatments"
          showConsultColumn={false}
        />
      </div>
    </div>
  );
};
