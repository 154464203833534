import { useMemo } from 'react';
import { useForm } from 'react-hook-form-6';
import { Modal } from 'components/modal';
import { Customer } from '../../pages/customer/types';
import { formatDateAndTime } from 'utils/misc';
import { Loading } from 'components/loading';
import { useAuditLogs } from './useAuditLogs';
import { AuditDetails } from './audit-details';
import { useHasPermissions } from 'components/permissions';
import { combineLogs } from './utils';
import { SmallTableHeadings } from 'components/small-table/small-table-headings';

export const AuditLogsModal = (props: {
  customer: Customer;
  onClose: () => void;
  show: boolean;
}): React.ReactElement => {
  const customer = props.customer;
  const consultationIds = customer.consultations.map((c) => c.id);
  const treatmentIds = customer.treatments.map((t) => t.id);
  const orderIds = customer.treatments.flatMap((t) =>
    t.orders.map((o) => o.id),
  );
  const membershipIds =
    customer.memberships?.map((membership) => membership.id) ?? [];
  const canViewScripts = useHasPermissions(['VIEW_SCRIPTS']);
  const scriptIds = canViewScripts
    ? (customer.treatments.map((t) => t.scriptId).filter(Boolean) as string[])
    : [];

  const { data, loading } = useAuditLogs({
    skip: !props.show,
    targetIds: [
      customer.id,
      ...consultationIds,
      ...orderIds,
      ...treatmentIds,
      ...scriptIds,
      ...membershipIds,
    ],
  });

  const { register, watch } = useForm<{
    searchString: string | undefined;
  }>({
    defaultValues: { searchString: undefined },
    mode: 'onChange',
  });

  const watchedSearchString = watch('searchString', '');
  const sortedLogs = combineLogs(data, customer).sort((a, b) =>
    a.created < b.created ? 1 : -1,
  );
  const logs = useMemo(() => {
    return sortedLogs.filter((log) => {
      if (!watchedSearchString) {
        return true;
      }
      const searchValues = [
        log.action,
        JSON.stringify(log.details),
        log.type,
        typeof log.target === 'string' ? log.target : log.target.key,
        log.user,
      ].map((s) => (s || '').toString().toLowerCase());

      return searchValues.some((value) =>
        value.match(watchedSearchString.toLowerCase()),
      );
    });
  }, [sortedLogs, watchedSearchString]);

  if (loading) {
    return <Loading />;
  }
  return (
    <Modal show={props.show} onClose={props.onClose} width="w-11/12">
      <div className="bg-white shadow overflow-hidden rounded">
        <div className="flow-root px-4 py-5 border-b border-slate-200">
          <h3 className="float-left text-lg leading-6 font-medium text-slate-900">
            Audit Logs{' '}
            <span className="ml-2 text-slate-500">{logs.length}</span>
          </h3>
          <span className="float-right">
            <form>
              <input
                className="bg-slate-200 border-2 border-slate-200 rounded py-1 px-2 text-slate-700"
                id="searchString"
                type="text"
                ref={register}
                name="searchString"
              />
            </form>
          </span>
        </div>
        <div>
          <div className="-mt-2 overflow-x-auto">
            <div className="pt-2 min-w-full">
              <table className="min-w-full divide-y divide-slate-200">
                <thead>
                  <SmallTableHeadings
                    headings={[
                      'Created',
                      'Type',
                      'Target ID',
                      'Action',
                      'User',
                      'Details',
                    ]}
                  />
                </thead>
                <tbody className="bg-white divide-y divide-slate-200">
                  {logs.map((auditLog) => (
                    <tr key={auditLog.id}>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-slate-900">
                            {formatDateAndTime(auditLog.created)}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-slate-900">
                          {auditLog.type}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 text-slate-500">
                          {auditLog.target}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm leading-5 text-slate-900">
                            {auditLog.action}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        {auditLog.user}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm">
                        <AuditDetails details={auditLog.details} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
