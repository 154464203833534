import React, { useMemo, useState } from 'react';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { AdminNotesStep } from './admin-notes-step';
import { ConfirmStep } from './confirm-step';
import { DispatchBatchStepTransitionProps, DisplayableFilter } from './types';
import { ProblemType } from 'graphql/types';
import { BookingWindowAssignStep } from './booking-window-assign-step';
import { Modal } from 'components/modal';
import { useNotifications } from 'notifications';
import { Button } from 'components/button';
import { ModalLayout } from './modal-layout';
import { Switch } from 'components/switch';
import { useForm } from 'react-hook-form-6';
import { DoctorSelect } from 'components/doctor-select';
import { RadioButton } from 'components/radio-button';

type BulkReviewStep =
  | 'reviewInfo'
  | 'skipQuiz'
  | 'adminNotes'
  | 'assignOption'
  | 'doctorAssign'
  | 'bookingWindowAssign'
  | 'confirm';

type BulkReviewButtonProps = {
  batchId: string;
  problemType: ProblemType;
  jobCount: number;
  displayableFilters: DisplayableFilter[];
};

type BulkReviewForm = {
  skipQuizValue: boolean | string;
  adminNotes: string;
  doctorIds: string[] | null;
  assignOption: AssignOption;
  bookingWindows: { id: string; name: string }[];
};

export const BulkReviewButton: React.FC<BulkReviewButtonProps> = ({
  problemType,
  jobCount,
  batchId,
  displayableFilters,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const showNotification = useNotifications();

  return (
    <>
      <Button size="large" onClick={() => setShowModal(true)}>
        <p className="whitespace-nowrap">Run bulk action</p>
      </Button>
      {showModal && (
        <Modal
          show
          onClose={(): void => setShowModal(false)}
          isAutoOverflow={false}
          width="w-5/6"
        >
          <BulkReview
            onCancel={(): void => setShowModal(false)}
            onCompleted={(): void => {
              setShowModal(false);
              showNotification({
                type: 'success',
                message: 'Successfully dispatched batch',
              });
            }}
            problemType={problemType}
            jobCount={jobCount}
            displayableFilters={displayableFilters}
            batchId={batchId}
          />
        </Modal>
      )}
    </>
  );
};

type BulkReviewProps = {
  onCancel: () => void;
  onCompleted: () => void;
  problemType: ProblemType;
  jobCount: number;
  displayableFilters: DisplayableFilter[];
  batchId: string;
};

const BulkReview: React.FC<BulkReviewProps> = ({
  onCancel,
  onCompleted,
  problemType,
  jobCount,
  displayableFilters,
  batchId,
}) => {
  const featureFlagClient = useFeatureFlagClient();
  const ffEnableSyncReviewConsults = featureFlagClient.getBoolean(
    'ff-enable-sync-review-consults',
  );
  const [step, setStep] = useState<BulkReviewStep>('reviewInfo');

  const form = useForm<BulkReviewForm>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      skipQuizValue: false,
      adminNotes: '',
      doctorIds: [],
      assignOption: 'doctor',
      bookingWindows: [],
    },
  });

  const { watch, register, control, errors } = form;
  const { skipQuizValue, adminNotes, doctorIds, assignOption, bookingWindows } =
    watch();
  const skipQuiz = useMemo(() => !!skipQuizValue, [skipQuizValue]);

  const stepTransitions: Record<
    BulkReviewStep,
    DispatchBatchStepTransitionProps
  > = {
    reviewInfo: {
      previousStep: onCancel,
      nextStep: (): void => setStep('skipQuiz'),
    },
    skipQuiz: {
      previousStep: (): void => setStep('reviewInfo'),
      nextStep: (): void => {
        setStep('adminNotes');
      },
    },
    adminNotes: {
      previousStep: (): void => setStep('skipQuiz'),
      nextStep: (): void => {
        if (skipQuiz) {
          if (ffEnableSyncReviewConsults) {
            setStep('assignOption');
          } else {
            setStep('doctorAssign');
          }
        } else {
          setStep('confirm');
        }
      },
    },
    assignOption: {
      previousStep: (): void => setStep('adminNotes'),
      nextStep: (): void =>
        assignOption === 'queue'
          ? setStep('bookingWindowAssign')
          : setStep('doctorAssign'),
    },
    doctorAssign: {
      previousStep: (): void =>
        ffEnableSyncReviewConsults
          ? setStep('assignOption')
          : setStep('adminNotes'),
      nextStep: (): void => setStep('confirm'),
    },
    bookingWindowAssign: {
      previousStep: (): void => setStep('assignOption'),
      nextStep: (): void => setStep('confirm'),
    },
    confirm: {
      previousStep: (): void => {
        if (skipQuiz) {
          if (assignOption === 'queue') {
            setStep('bookingWindowAssign');
          } else {
            setStep('doctorAssign');
          }
        } else {
          setStep('adminNotes');
        }
      },
      nextStep: onCompleted,
    },
  };

  const renderStep = (): React.ReactElement => {
    switch (step) {
      case 'reviewInfo':
        return (
          <ModalLayout
            back={stepTransitions[step].previousStep}
            backText="Cancel"
            next={stepTransitions[step].nextStep}
          >
            <div>
              <p>
                Put <b>{jobCount}</b> patients into a review consult that meet
                the following criteria:
              </p>
              <ul className="list-disc pl-4">
                {displayableFilters.map((filter) => (
                  <li className="" key={filter.title}>
                    {filter.values.length === 1 ? (
                      filter.values[0]
                    ) : (
                      <div>
                        {filter.title}:
                        <ul className="list-disc ml-4">
                          {filter.values.map((f) => (
                            <li key={f}>{f}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </ModalLayout>
        );
      case 'skipQuiz':
        return (
          <ModalLayout
            back={stepTransitions[step].previousStep}
            next={stepTransitions[step].nextStep}
          >
            <div>
              <p>
                If you select this option, please make sure you provide the
                practitioners with all the key notes.
              </p>
              <Switch
                label="Can skip quiz?"
                labelPosition="right"
                ref={register()}
                checked={skipQuiz}
                value="skipQuizValue"
                name="skipQuizValue"
              />
            </div>
          </ModalLayout>
        );
      case 'adminNotes':
        return (
          <AdminNotesStep
            {...stepTransitions[step]}
            register={register}
            notes={adminNotes}
            skipQuiz={skipQuiz}
            errors={errors}
            problemType={problemType}
          />
        );
      case 'assignOption':
        return (
          <ModalLayout
            back={stepTransitions[step].previousStep}
            next={stepTransitions[step].nextStep}
            nextDisabled={!assignOption}
          >
            {allAssignOptions.map(({ label, value }) => (
              <div key={value} className="flex">
                <RadioButton
                  key={value}
                  ref={register()}
                  id={value}
                  name="assignOption"
                  label={label}
                />
              </div>
            ))}
          </ModalLayout>
        );
      case 'doctorAssign':
        return (
          <ModalLayout
            back={stepTransitions[step].previousStep}
            next={stepTransitions[step].nextStep}
            nextDisabled={!doctorIds?.length}
          >
            <div className="p-4 bg-slate-400 my-4">
              <strong>
                Your {jobCount} review consultations require assigning.
              </strong>
              <p>
                If you select more than one practitioner, patients will be
                distributed evenly among them.
              </p>
            </div>
            <DoctorSelect
              name="doctorIds"
              control={control}
              label="Doctors"
              isMulti
              showAllDoctors={false}
              hasAllOption={false}
            />
          </ModalLayout>
        );
      case 'bookingWindowAssign':
        return (
          <BookingWindowAssignStep
            {...stepTransitions[step]}
            bookingWindows={bookingWindows}
            setBookingWindows={(bookingWindows) =>
              form.setValue('bookingWindows', bookingWindows)
            }
            problemType={problemType}
            jobCount={jobCount}
          />
        );
      case 'confirm':
        return (
          <ConfirmStep
            {...stepTransitions[step]}
            skipQuiz={skipQuiz}
            batchId={batchId}
            adminNotes={adminNotes}
            assignOption={assignOption}
            doctorIds={doctorIds || []}
            bookingWindows={bookingWindows}
            problemType={problemType}
            jobCount={jobCount}
          />
        );
    }
  };

  return (
    <div className="bg-white flex flex-col rounded p-4 w-full">
      <h2 className="text-xl">Bulk review</h2>
      {renderStep()}
    </div>
  );
};

const allAssignOptions = [
  {
    label: 'Assign directly to a practitioner (bypass sync consult queue)',
    value: 'doctor',
  },
  {
    label: 'Move to sync consult queue',
    value: 'queue',
  },
] as const;

export type AssignOption = (typeof allAssignOptions)[number]['value'];
