import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import type {
  ConfirmStepAllDoctorsQuery,
  ConfirmStepAllDoctorsQueryVariables,
  CreateReviewConsultationMutation,
  CreateReviewConsultationMutationVariables,
  ProblemType,
  ReviewReason,
} from 'graphql/types';
import React from 'react';
import type { RequestReviewStepTransitionProps } from './types';
import { Markdown } from 'components/markdown';
import type { AssignOption } from './assign-option-step';
import type { BookingWindow } from './booking-window-assign-step';
import { formatWindowDay, formatWindowInterval } from 'utils/queues';
import { reasonOptions } from 'utils/dropdown-options';

type ConfirmStepProps = RequestReviewStepTransitionProps & {
  skipQuiz: boolean;
  adminNotes?: string;
  assignOption?: AssignOption;
  doctorId?: string;
  bookingWindow?: BookingWindow;
  customerId: string;
  problemType: ProblemType;
  reason: ReviewReason | undefined;
};

export const ConfirmStep: React.FC<ConfirmStepProps> = ({
  previousStep,
  nextStep: onCompleted,
  skipQuiz,
  adminNotes,
  assignOption,
  doctorId,
  bookingWindow,
  customerId,
  problemType,
  reason,
}) => {
  const { data } = useQuery<
    ConfirmStepAllDoctorsQuery,
    ConfirmStepAllDoctorsQueryVariables
  >(
    gql`
      query ConfirmStepAllDoctors {
        allDoctors {
          id
          clinicianName
        }
      }
    `,
    {
      skip: !doctorId,
      fetchPolicy: 'cache-first',
    },
  );

  const clinicianName =
    data?.allDoctors.find(({ id }) => id === doctorId)?.clinicianName ?? '-';
  const bookingWindowName = bookingWindow
    ? `${formatWindowDay(bookingWindow)} ${formatWindowInterval(bookingWindow)}`
    : '-';

  const [
    createReviewConsultation,
    { loading: createReviewConsultationLoading },
  ] = useMutation<
    CreateReviewConsultationMutation,
    CreateReviewConsultationMutationVariables
  >(
    gql`
      mutation CreateReviewConsultation(
        $input: CreateReviewConsultationInput!
      ) {
        createReviewConsultation(input: $input) {
          consultation {
            id
            customer {
              id
              consultations(orderBy: { createdAt: desc }) {
                id
                status
                createdAt
                completedAt
                isApproved
                stage
                type
                doctor {
                  id
                  firstName
                  lastName
                  fullName
                  clinicianName
                }
              }
              purchases {
                id
                status
                purchaseGroup {
                  id
                  unmetActivationRequirements
                }
                contexts {
                  id
                  status
                }
              }
            }
          }
        }
      }
    `,
    { onCompleted },
  );

  const onConfirm = (): void => {
    createReviewConsultation({
      variables: {
        input: {
          customerId,
          problemType,
          skipQuiz,
          adminNotes: adminNotes,
          reason,
          ...(assignOption === 'queue'
            ? { practitionerBookingWindowId: bookingWindow?.id }
            : { assignToDoctorId: doctorId }),
        },
      },
    });
  };

  return (
    <div className="w-full space-y-4">
      <h3 className="heading-md mb-2">Confirm review consult</h3>
      <ul className="space-y-4">
        <li>
          <strong>Can skip quiz?:</strong> {skipQuiz ? 'Yes' : 'No'}
        </li>
        <li>
          <strong>Review reason:</strong>{' '}
          {reasonOptions.find((o) => o.value === reason)?.label}
        </li>
        <li>
          <strong>Has admin notes:</strong> {adminNotes ? 'Yes' : 'No'}
        </li>
        {adminNotes && (
          <li className="text-slate-700 ml-4 border-t border-b py-4 max-h-96 overflow-auto">
            <Markdown src={adminNotes} />
          </li>
        )}
        <li>
          <strong>Practitioner assigning:</strong>{' '}
          {assignOption === 'queue'
            ? `Sync consult queue (Window: ${bookingWindowName})`
            : `Assigned directly to ${clinicianName}`}
        </li>
      </ul>
      <div className="w-full flex flex-row justify-between mt-4">
        <div>
          <Button
            fullWidth
            variant="outline"
            type="button"
            onClick={previousStep}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            fullWidth
            loading={createReviewConsultationLoading}
            type="button"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
