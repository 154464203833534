import clsx from 'clsx';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  formatDateAndTime,
  formatCurrencyAmountCents,
  formatDate,
} from '../../utils/misc';
import { routes } from '../../utils/routes';
import { CustomerTreatmentView } from './types';

export const TreatmentTable = ({
  treatment,
}: {
  treatment: CustomerTreatmentView;
}): React.ReactElement => (
  <div className="flex">
    <dl className="w-1/2">
      <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">ID</dt>
        <dd className="text-sm leading-5 text-slate-500 sm:mt-0 sm:col-span-3">
          {treatment.id}
          <CopyToClipboardButton value={treatment.id} />
        </dd>
      </div>
      <div className="bg-white-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Created
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
          {formatDateAndTime(treatment.created)}
        </dd>
      </div>
      <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Updated
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
          {formatDateAndTime(treatment.updated)}
        </dd>
      </div>
      <div className="bg-white px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Completed
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
          {treatment.completed ? formatDateAndTime(treatment.completed) : ''}
        </dd>
      </div>
      <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Product
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3 underline">
          <Link to={`${routes.products}/${treatment.productId}`}>
            {treatment.name}
          </Link>
        </dd>
      </div>
      <div className="bg-white px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">Plan</dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3 underline">
          <Link to={`${routes.plans}/${treatment.planId}`}>
            {treatment.plan}
          </Link>
        </dd>
      </div>
    </dl>
    <dl className="w-1/2 bg-slate-100">
      <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Payment
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
          {treatment.nextPaymentDue
            ? formatDateAndTime(treatment.nextPaymentDue)
            : ''}
        </dd>
      </div>
      <div className="bg-white px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">Amount</dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
          {formatCurrencyAmountCents(treatment.amount)}
        </dd>
      </div>
      <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Repeats
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
          {treatment.repeats}
        </dd>
      </div>
      <div className="bg-white px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Refills left
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
          {treatment.refillsLeft || 0}
        </dd>
      </div>
      <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Current Script
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3 underline">
          <Link to={`${routes.scripts}/${treatment.scriptId}`}>
            {treatment.scriptId}
          </Link>
        </dd>
      </div>
      <div className="bg-white px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Commenced
        </dt>
        <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
          {treatment.customerStatedTreatmentStartedAt &&
            formatDate(treatment.customerStatedTreatmentStartedAt)}
        </dd>
      </div>
      <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
        <dt className="text-sm leading-5 font-medium text-slate-500">
          Script Expires
        </dt>
        <dd
          className={clsx(
            'text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3 underline',
            {
              'text-red-500 font-semibold':
                treatment.scriptExpiresAt &&
                new Date(treatment.scriptExpiresAt) < new Date(),
            },
          )}
        >
          {treatment.scriptExpiresAt &&
            formatDateAndTime(treatment.scriptExpiresAt)}
        </dd>
      </div>
    </dl>
  </div>
);
