import { useMemo } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useNotifications } from 'notifications';
import { Card } from 'components/card';
import { Loading } from 'components/loading';
import { Tooltip } from 'components/tooltip';
import {
  ChargeGateway,
  ConsignmentFulfillmentVendor,
  ConsignmentInvoiceUrlQuery,
  ConsignmentInvoiceUrlQueryVariables,
  ConsignmentStatus,
  CoreOrderConsignmentsInformationFragment,
  CoreOrderCustomerInformationFragment,
  CoreOrderInformationFragment,
  CoreOrderPageCancelEvermedOrderMutation,
  CoreOrderPageCancelEvermedOrderMutationVariables,
  CoreOrderPageQuery,
  CoreOrderPageQueryVariables,
  RefreshOrderDeliveryAddressMutation,
  RefreshOrderDeliveryAddressMutationVariables,
  TriggerProcessConsignmentMutation,
  TriggerProcessConsignmentMutationVariables,
} from 'graphql/types';
import { CardField } from 'pages/order/card-field';
import { Link, useParams } from 'react-router-dom';
import { cardHeading } from 'utils/styles';
import {
  Colors,
  formatCurrencyAmountCents,
  formatDateAndTime,
  upperSnakeCaseToCapitalCase,
} from 'utils/misc';
import { Tag } from 'components/tag';
import { buildRoute } from 'utils/routes';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { Copyable } from 'components/copyable';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { validate } from 'uuid';

const query = gql`
  fragment CoreOrderInformation on CoreOrder {
    id
    createdAt
    deliveryAddress
    address {
      id
      inColdShippingRange
    }
    total
    subtotal
    charges {
      id
      amount
      gateway
      gatewayTransactionId
      createdAt
    }
  }

  fragment CoreOrderCustomerInformation on User {
    id
    email
  }

  fragment CoreOrderConsignmentsInformation on Consignment {
    id
    status
    fulfillmentVendor
    fulfillmentVendorId
    updatedAt
    createdAt
    fulfilledAt
    deliveredAt
    lineItems {
      id
      variant {
        id
        price
        inventory {
          id
          sku
        }
        product {
          id
          friendlyName
          name
          productType
        }
      }
      price
      discount
      quantity
      script {
        id
      }
    }
    deliveryTracking {
      id
      provider
      reference
    }
    fulfillmentVendorDetails {
      id
      ... on PluripharmFulfillmentVendorDetails {
        trackingUrl
        pluripharmOrderId
      }
      ... on EvermedFulfillmentVendorDetails {
        comment
        cancelable
        holdReason
        orderNumber
        dispensingPharmacy {
          id
          name
          address
        }
        isProductVariantSubstituted
        trackingUrl
      }
      ... on TitanFulfillmentVendorDetails {
        trackingUrl
      }
    }
  }

  query CoreOrderPage($id: ID!) {
    coreOrder(id: $id) {
      ...CoreOrderInformation
      customer {
        ...CoreOrderCustomerInformation
      }
      consignments {
        ...CoreOrderConsignmentsInformation
      }
    }
  }
`;

export const CoreOrder = (): React.ReactElement => {
  const featureFlagClient = useFeatureFlagClient();
  const { coreOrderId } = useParams<{ coreOrderId: string }>();
  const { data, loading } = useQuery<
    CoreOrderPageQuery,
    CoreOrderPageQueryVariables
  >(query, {
    variables: { id: coreOrderId },
    skip: !validate(coreOrderId),
  });

  const coreOrder = data?.coreOrder;
  const customer = coreOrder?.customer;
  const consignments = coreOrder?.consignments;

  const disableRefreshDeliveryAddressButton = useMemo(
    () =>
      !consignments
        ?.filter(
          (consignment) =>
            consignment.fulfillmentVendor !== 'CONSULTATION' &&
            consignment.fulfillmentVendor !== 'SVC_CORE_ACCESS',
        )
        ?.every(
          (consignment) =>
            consignment.status === 'PENDING_VENDOR_CREATION' ||
            consignment.status === 'FAILED_VENDOR_CREATION',
        ),
    [consignments],
  );

  const {
    data: consignmentInvoiceUrls,
    loading: consignmentInvoiceUrlsLoading,
  } = useQuery<ConsignmentInvoiceUrlQuery, ConsignmentInvoiceUrlQueryVariables>(
    gql`
      query ConsignmentInvoiceUrl($id: ID!) {
        coreOrder(id: $id) {
          id
          consignments {
            id
            ... @defer {
              invoiceUrl
            }
          }
        }
      }
    `,
    {
      variables: { id: coreOrderId },
      skip:
        !featureFlagClient.getBoolean('ff_consignment_invoices') ||
        !validate(coreOrderId),
    },
  );

  if (loading) {
    return <Loading />;
  }

  if (!coreOrder) {
    return (
      <div>
        Couldn&apos;t resolve core order data for core order {coreOrderId}
      </div>
    );
  }

  if (!customer) {
    return (
      <div>
        Couldn&apos;t resolve customer data for core order {coreOrderId}
      </div>
    );
  }

  if (!consignments) {
    return (
      <div>
        Couldn&apos;t resolve consignments data for core order {coreOrderId}
      </div>
    );
  }

  let consignmentIdToInvoiceUrl: Map<string, string | null> | undefined;
  if (!consignmentInvoiceUrlsLoading) {
    consignmentIdToInvoiceUrl = new Map<string, string | null>();
    if (consignmentInvoiceUrls?.coreOrder?.consignments) {
      for (const consignment of consignmentInvoiceUrls.coreOrder.consignments) {
        consignmentIdToInvoiceUrl.set(
          consignment.id,
          consignment.invoiceUrl ?? null,
        );
      }
    }
  }

  return (
    <div className="space-y-8">
      <CoreOrderInformation
        coreOrder={coreOrder}
        disableRefreshDeliveryAddressButton={
          disableRefreshDeliveryAddressButton
        }
      />
      <CustomerInformation customer={customer} />
      <PaymentInformation coreOrder={coreOrder} />
      <ConsignmentsInformation
        consignments={consignments}
        consignmentIdToInvoiceUrl={consignmentIdToInvoiceUrl}
      />
    </div>
  );
};

const CoreOrderInformation: React.FC<{
  coreOrder: CoreOrderInformationFragment;
  disableRefreshDeliveryAddressButton: boolean;
}> = ({ coreOrder, disableRefreshDeliveryAddressButton }) => {
  const showNotification = useNotifications();

  const [
    refreshDeliveryAddressMutation,
    { loading: refreshDeliveryAddressSubmitting },
  ] = useMutation<
    RefreshOrderDeliveryAddressMutation,
    RefreshOrderDeliveryAddressMutationVariables
  >(gql`
    mutation refreshOrderDeliveryAddress(
      $input: RefreshOrderDeliveryAddressInput!
    ) {
      refreshOrderDeliveryAddress(input: $input) {
        order {
          id
          createdAt
          deliveryAddress
          address {
            id
            inColdShippingRange
          }
        }
      }
    }
  `);

  const submitRefreshDeliveryAddress = async () => {
    try {
      await refreshDeliveryAddressMutation({
        variables: { input: { orderId: coreOrder.id } },
      });
      showNotification({
        type: 'success',
        message: 'Refreshed the delivery address',
      });
    } catch (e) {
      if (e instanceof Error) {
        showNotification({
          type: 'error',
          message: e.message,
        });
      }
    }
  };

  return (
    <Card>
      <h2 className={clsx(cardHeading)}>Order Information</h2>
      <div>
        <CardField label="ID">
          <span>{coreOrder.id}</span>
          <CopyToClipboardButton value={coreOrder.id} />
        </CardField>
        <CardField label="Created">
          {formatDateAndTime(coreOrder?.createdAt)}
        </CardField>
        <CardField label="Delivery Address">
          <div className="flex items-center space-x-2">
            <p>{coreOrder.deliveryAddress}</p>
            <ColdShippableTag
              inRange={coreOrder.address?.inColdShippingRange}
            />
            <button
              onClick={submitRefreshDeliveryAddress}
              className="inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white hover:text-slate-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white transition duration-150 ease-in-out"
              disabled={
                disableRefreshDeliveryAddressButton ||
                refreshDeliveryAddressSubmitting
              }
            >
              Refresh
              <Tooltip
                hoverText={
                  disableRefreshDeliveryAddressButton
                    ? `Refreshing delivery address is only allowed when each consignment has either "Pending Vendor Creation" or "Failed Vendor Creation" status`
                    : `Resolve patient's latest shipping address`
                }
              />
            </button>
          </div>
        </CardField>
        <CardField label="Subtotal">
          {formatCurrencyAmountCents(coreOrder?.subtotal ?? 0)}
        </CardField>
        <CardField label="Discount">
          {formatCurrencyAmountCents(coreOrder?.subtotal - coreOrder?.total)}
        </CardField>
        <CardField label="Total">
          {formatCurrencyAmountCents(coreOrder?.total)}
        </CardField>
      </div>
    </Card>
  );
};

const PaymentInformation: React.FC<{
  coreOrder: CoreOrderInformationFragment;
}> = ({ coreOrder }) => {
  const columns = ['ID', 'Created At', 'Gateway', 'Gateway ID', 'Amount'];

  const charges: NonNullable<(typeof coreOrder)['charges']> =
    coreOrder.charges ?? [];

  return (
    <Card>
      <h2 className={clsx(cardHeading, 'space-x-2')}>
        <span>Payments</span>
        <span className="text-slate-500">{charges.length}</span>
      </h2>
      <div className="-mt-2 overflow-x-auto">
        <div className="pt-2 min-w-full">
          <table className="min-w-full divide-y divide-slate-200">
            <thead>
              <tr>
                {columns.map((columnHeader) => (
                  <th
                    key={columnHeader}
                    className="px-6 py-3 bg-slate-50 text-left text-xs leading-4 font-medium text-slate-500 uppercase tracking-wider"
                  >
                    {columnHeader}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-slate-200">
              {charges.length === 0 && (
                <div className="mx-4 my-2 text-slate-500 text-sm">
                  Customer has no payments for this order
                </div>
              )}
              {charges.map((charge) => (
                <tr className="text-sm" key={charge.id}>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <span>{charge.id}</span>
                    <CopyToClipboardButton value={charge.id} />
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <span>{formatDateAndTime(charge.createdAt)}</span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <span>{formatPaymentGateway(charge.gateway)}</span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <span className="underline">
                      <a
                        className="underline"
                        href={getPaymentGatewayTransactionUrl(
                          charge.gateway,
                          charge.gatewayTransactionId ?? '',
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {charge.gatewayTransactionId}
                      </a>
                    </span>
                    <CopyToClipboardButton
                      value={charge.gatewayTransactionId ?? ''}
                    />
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {formatCurrencyAmountCents(charge.amount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

const CustomerInformation: React.FC<{
  customer: CoreOrderCustomerInformationFragment;
}> = ({ customer }) => {
  return (
    <Card>
      <h2 className={clsx(cardHeading)}>Patient Information</h2>

      <div>
        <CardField label="Patient ID">
          <span className="underline">
            <Link to={buildRoute.customer(customer.id)}>{customer.id}</Link>
          </span>
          <CopyToClipboardButton value={customer.id} />
        </CardField>
        <CardField label="Patient">{customer.email}</CardField>
      </div>
    </Card>
  );
};

const ConsignmentsInformation: React.FC<{
  consignments: CoreOrderConsignmentsInformationFragment[];
  consignmentIdToInvoiceUrl: Map<string, string | null> | undefined;
}> = ({ consignments, consignmentIdToInvoiceUrl }) => {
  return (
    <Card>
      <h2 className={clsx(cardHeading, 'space-x-2')}>
        <span>Included Consignments</span>
        <span className="text-slate-500">{consignments.length}</span>
      </h2>
      <div className="space-y-8 p-8 bg-opacity-10 bg-slate-700 border border-slate-300">
        {consignments.map((c) => (
          <ConsignmentInformation
            consignment={c}
            key={c.id}
            invoiceUrl={
              consignmentIdToInvoiceUrl === undefined
                ? undefined
                : consignmentIdToInvoiceUrl.get(c.id) ?? null
            }
          />
        ))}
      </div>
    </Card>
  );
};

const ConsignmentInformation = ({
  consignment,
  invoiceUrl,
}: {
  consignment: CoreOrderConsignmentsInformationFragment;
  invoiceUrl: string | undefined | null;
}) => {
  const featureFlagClient = useFeatureFlagClient();
  const showNotification = useNotifications();

  const [
    triggerProcessConsignment,
    { loading: loadingTriggerProcessConsignment },
  ] = useMutation<
    TriggerProcessConsignmentMutation,
    TriggerProcessConsignmentMutationVariables
  >(
    gql`
      mutation triggerProcessConsignment(
        $input: TriggerProcessConsignmentInput!
      ) {
        triggerProcessConsignment(input: $input) {
          consignment {
            id
            status
            updatedAt
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Consignment is queued for vendor creation',
        });
      },
      onError: (err) => {
        showNotification({
          type: 'error',
          message: err.message,
        });
      },
    },
  );

  const handleRetryVendorCreation = async () => {
    await triggerProcessConsignment({
      variables: { input: { consignmentId: consignment.id } },
    });
  };

  const [cancelEvermedOrder, { loading: loadingCancelEvermedOrder }] =
    useMutation<
      CoreOrderPageCancelEvermedOrderMutation,
      CoreOrderPageCancelEvermedOrderMutationVariables
    >(
      gql`
        mutation coreOrderPageCancelEvermedOrder(
          $input: CancelEvermedOrderInput!
        ) {
          cancelEvermedOrder(input: $input) {
            evermedFulfillmentVendorDetails {
              id
              cancelable
            }
          }
        }
      `,
      {
        onCompleted: () => {
          showNotification({
            type: 'success',
            message: 'Cancelled Evermed order',
          });
        },
        onError: (err) => {
          showNotification({
            type: 'error',
            message: err.message,
          });
        },
        update: (cache) => {
          const cacheKey = `Consignment:${consignment.id}`;

          cache.modify({
            id: cacheKey,
            fields: {
              status: () => 'CANCELLED',
            },
          });
        },
      },
    );

  const handleCancelEvermedOrder = async () => {
    await cancelEvermedOrder({
      variables: { input: { evermedOrderId: consignment.fulfillmentVendorId } },
    });
  };

  const fields: { label: string; value: React.ReactNode }[] = [
    {
      label: 'Vendor ID',
      value: (
        <>
          {consignment.fulfillmentVendorId ? (
            <span className="underline">
              <a
                className="underline"
                href={getFulfillmentVendorUrl(
                  consignment.fulfillmentVendor,
                  consignment.fulfillmentVendorId,
                )}
                target="_blank"
                rel="noreferrer"
              >
                {consignment.fulfillmentVendorId}
              </a>
            </span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      label: 'Created At',
      value: formatDateAndTime(consignment.createdAt),
    },
    {
      label: 'Updated At',
      value: formatDateAndTime(consignment.updatedAt),
    },
  ];

  if (['FULFILLED', 'DELIVERED'].includes(consignment.status)) {
    fields.push(
      {
        label: 'Delivered At',
        value: consignment.deliveredAt
          ? formatDateAndTime(consignment.deliveredAt)
          : '-',
      },
      {
        label: 'Fulfilled at',
        value: consignment.fulfilledAt
          ? formatDateAndTime(consignment.fulfilledAt)
          : '-',
      },
    );
  }

  if (consignment.deliveryTracking) {
    fields.push(
      {
        label: 'Delivery Provider',
        value: consignment.deliveryTracking.provider,
      },
      {
        label: 'Deliver Provider Reference',
        value: consignment.deliveryTracking.reference ?? 'UNKNOWN',
      },
    );
  }

  if (
    consignment.fulfillmentVendorDetails?.__typename ===
      'PluripharmFulfillmentVendorDetails' &&
    consignment.fulfillmentVendorDetails.pluripharmOrderId
  ) {
    fields.push({
      label: 'Pluripharm Order ID',
      value: (
        <>
          <span>{consignment.fulfillmentVendorDetails.pluripharmOrderId}</span>
          <CopyToClipboardButton
            value={consignment.fulfillmentVendorDetails.pluripharmOrderId}
          />
        </>
      ),
    });
  }

  if (featureFlagClient.getBoolean('ff_consignment_invoices')) {
    fields.push({
      label: 'Invoice URL',
      value:
        invoiceUrl === undefined ? (
          <Loading />
        ) : invoiceUrl === null ? (
          <>-</>
        ) : (
          <a
            className="underline"
            href={invoiceUrl}
            target="_blank"
            rel="noreferrer"
          >
            Invoice
          </a>
        ),
    });
  }

  let cancelable = false;
  const fulfillmentVendorDetails = consignment.fulfillmentVendorDetails;
  switch (fulfillmentVendorDetails?.__typename) {
    case 'PluripharmFulfillmentVendorDetails':
      fields.push({
        label: 'Tracking URL',
        value: fulfillmentVendorDetails.trackingUrl ? (
          <a
            className="underline"
            href={fulfillmentVendorDetails.trackingUrl}
            target="_blank"
            rel="noreferrer"
          >
            {fulfillmentVendorDetails.trackingUrl}
          </a>
        ) : (
          '-'
        ),
      });
      break;

    case 'EvermedFulfillmentVendorDetails': {
      let substituted: string | undefined;
      if (fulfillmentVendorDetails.isProductVariantSubstituted === true) {
        substituted = 'Yes';
      } else if (
        fulfillmentVendorDetails.isProductVariantSubstituted === false
      ) {
        substituted = 'No';
      } else {
        substituted = 'Unknown';
      }

      if (fulfillmentVendorDetails.cancelable) {
        cancelable = true;
      }

      fields.push(
        {
          label: 'Order Number',
          value: fulfillmentVendorDetails.orderNumber ?? '-',
        },
        { label: 'Substituted', value: substituted },
        {
          label: 'Pharmacy',
          value: fulfillmentVendorDetails.dispensingPharmacy?.name ?? '-',
        },
        {
          label: 'Pharmacy Address',
          value: fulfillmentVendorDetails.dispensingPharmacy?.address ?? '-',
        },
        {
          label: 'Tracking URL',
          value: fulfillmentVendorDetails.trackingUrl ? (
            <a
              className="underline"
              href={fulfillmentVendorDetails.trackingUrl}
              target="_blank"
              rel="noreferrer"
            >
              {fulfillmentVendorDetails.trackingUrl}
            </a>
          ) : (
            '-'
          ),
        },
        {
          label: 'Comment',
          value: fulfillmentVendorDetails.comment ?? '-',
        },
        {
          label: 'Hold Reason',
          value: fulfillmentVendorDetails.holdReason ?? '-',
        },
      );

      break;
    }

    case 'TitanFulfillmentVendorDetails':
      fields.push({
        label: 'Tracking URL',
        value: fulfillmentVendorDetails.trackingUrl ? (
          <a
            className="underline"
            href={fulfillmentVendorDetails.trackingUrl}
            target="_blank"
            rel="noreferrer"
          >
            {fulfillmentVendorDetails.trackingUrl}
          </a>
        ) : (
          '-'
        ),
      });
      break;
  }

  return (
    <Card key={consignment.id}>
      <h2
        className={clsx(
          cardHeading,
          'space-x-2 flex items-center justify-between',
        )}
      >
        <div className="space-x-2">
          <span>Consignment - {consignment.fulfillmentVendor}</span>
          <span>
            <ConsignmentStatusTag status={consignment.status} />
          </span>
          {consignment.fulfillmentVendor === 'EVERMED' && (
            <button
              onClick={handleCancelEvermedOrder}
              className="ml-2 inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white hover:text-slate-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white transition duration-150 ease-in-out"
              disabled={!cancelable || loadingCancelEvermedOrder}
            >
              Cancel Evermed Order
              {
                !(
                  <Tooltip
                    hoverText={`Cancellation is not available for this order due to the order status. Please contact Evermed if cancellation is required.`}
                  />
                )
              }
            </button>
          )}
          {consignment.status === 'FAILED_VENDOR_CREATION' && (
            <button
              onClick={handleRetryVendorCreation}
              className="ml-2 inline-flex items-center px-4 py-2 border disabled:cursor-not-allowed disabled:opacity-25 border-slate-300 text-sm leading-5 font-medium rounded text-slate-700 bg-white hover:text-slate-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-slate-800 active:bg-white transition duration-150 ease-in-out"
              disabled={loadingTriggerProcessConsignment}
            >
              Retry
              <Tooltip
                hoverText={`This will attempt to create the order again in the vendor system (${consignment.fulfillmentVendor}). The process will take a few minutes to complete, after which please refresh the page to see if it has been successful`}
              />
            </button>
          )}
        </div>
        <span className="justify-end">
          <Copyable text={consignment.id}>
            {(copied) => (
              <pre className="text-sm cursor-pointer text-slate-500">
                {copied ? 'Copied' : consignment.id.slice(-6)}
              </pre>
            )}
          </Copyable>
        </span>
      </h2>

      <div className="flex flex-row">
        <div className="w-full">
          {fields.map(({ label, value }, index) => {
            if (index % 2 === 0) {
              return (
                <CardField key={label} label={label}>
                  {value}
                </CardField>
              );
            }
          })}
        </div>
        <div className="w-full">
          {fields.map(({ label, value }, index) => {
            if (index % 2 === 1) {
              return (
                <CardField key={label} label={label}>
                  {value}
                </CardField>
              );
            }
          })}
        </div>
      </div>
      <div className="block w-full overflow-scroll">
        <table className="divide-y divide-slate-200 mt-1 min-w-full">
          <thead>
            <tr>
              {TABLE_COLUMNS.map((h) => (
                <th
                  key={h}
                  className="px-6 py-3 bg-slate-50 text-left text-xs leading-4 font-medium text-slate-500 uppercase tracking-wider"
                >
                  {h}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-slate-200">
            {consignment.lineItems.map((li) => (
              <tr key={li.id} className="border-t h-12 text-sm">
                <td className="px-6 py-2">{li.variant?.inventory?.sku}</td>
                <td className="px-6 py-2 whitespace-nowrap underline">
                  {li.variant?.product.id && (
                    <Link to={buildRoute.product(li.variant?.product.id)}>
                      {li.variant?.product.friendlyName ||
                        li.variant?.product.name}
                    </Link>
                  )}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">{li.quantity}</td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatCurrencyAmountCents(li.price)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {formatCurrencyAmountCents(li.discount)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  {li.variant?.product.productType}
                </td>
                <td className="px-6 py-2 whitespace-nowrap underline">
                  {li.script && (
                    <Link to={buildRoute.script(li.script?.id)}>
                      View script
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

const TABLE_COLUMNS = [
  'sku',
  'product',
  'quantity',
  'price',
  'discount',
  'type',
  'script',
];

function formatPaymentGateway(gateway: ChargeGateway) {
  switch (gateway) {
    case 'stripe_charge':
    case 'stripe_payment_intent':
      return 'Stripe';
    case 'zip':
      return 'Zip Pay';
    default:
      return '';
  }
}

const getPaymentGatewayTransactionUrl = (
  gateway: ChargeGateway,
  gatewayTransactionId: string,
): string => {
  switch (gateway) {
    case 'stripe_charge':
    case 'stripe_payment_intent':
      return buildRoute.stripePayment(gatewayTransactionId);
    case 'zip':
      return buildRoute.zipPayment(gatewayTransactionId);
    default:
      return gateway;
  }
};

export const ConsignmentStatusTag = ({
  status,
}: {
  status: ConsignmentStatus;
}): JSX.Element => {
  let tagColor: Colors = 'gray';
  switch (status) {
    case 'PENDING_VENDOR_CREATION':
      tagColor = 'yellow';
      break;
    case 'VENDOR_CREATED':
      tagColor = 'indigo';
      break;
    case 'FULFILLED':
    case 'DELIVERED':
      tagColor = 'green';
      break;
    case 'FAILED_VENDOR_CREATION':
      tagColor = 'red';
      break;
    default:
      tagColor = 'gray';
      break;
  }

  return (
    <Tag size="small" shape="box" color={tagColor}>
      {upperSnakeCaseToCapitalCase(status)}
    </Tag>
  );
};

const ColdShippableTag = ({ inRange }: { inRange?: boolean | null }) => {
  if (typeof inRange !== 'boolean') {
    return null;
  }

  let tagColor: Colors = 'green';
  let tagLabel: string = 'In range';

  if (!inRange) {
    tagColor = 'orange';
    tagLabel = 'Out of range';
  }

  return (
    <Tag size="small" shape="pill" color={tagColor}>
      {tagLabel}
    </Tag>
  );
};

const getFulfillmentVendorUrl = (
  vendor: ConsignmentFulfillmentVendor,
  vendorId: string,
): string => {
  switch (vendor) {
    case 'SHOPIFY':
      return buildRoute.shopifyOrder(vendorId);
    case 'APONS':
      return buildRoute.aponsOrder(vendorId);
    default:
      return '';
  }
};
