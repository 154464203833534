import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { formatDateAndTime, formatCurrencyAmountCents } from '../../utils/misc';
import { CustomerTreatmentView } from './types';

export const PaymentPlanTable = ({
  treatment,
}: {
  treatment: CustomerTreatmentView;
}): React.ReactElement | null => {
  if (!treatment.experimentPaymentPlan) {
    return null;
  }

  const {
    experimentPaymentPlan: {
      id,
      createdAt,
      discountPercentage,
      paymentPlan,
      charge,
      numberOfOrdersRemaining,
      version,
    },
  } = treatment;

  return (
    <div className="border-t border-slate-200">
      <div className="px-4 py-5 border-b border-slate-200">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Payment plan
        </h3>
      </div>
      <div className="flex">
        <dl className="w-1/2">
          <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
            <dt className="text-sm leading-5 font-medium text-slate-500">ID</dt>
            <dd className="text-sm leading-5 text-slate-500 sm:mt-0 sm:col-span-3">
              {id}
              <CopyToClipboardButton value={id} />
            </dd>
          </div>
          <div className="bg-white-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
            <dt className="text-sm leading-5 font-medium text-slate-500">
              Created
            </dt>
            <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
              {formatDateAndTime(createdAt)}
            </dd>
          </div>
          <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
            <dt className="text-sm leading-5 font-medium text-slate-500">
              Amount
            </dt>
            {charge && (
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                {formatCurrencyAmountCents(charge.amount)}
              </dd>
            )}
          </div>
          <div className="bg-white-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
            <dt className="text-sm leading-5 font-medium text-slate-500">
              Charged at
            </dt>
            {charge && (
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                {formatDateAndTime(charge.updatedAt)}
              </dd>
            )}
          </div>
        </dl>
        <dl className="w-1/2 bg-white-100">
          <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
            <dt className="text-sm leading-5 font-medium text-slate-500">
              Plan
            </dt>
            <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
              {paymentPlan}
              <div className="inline-flex items-center leading-loose text-xs text-white bg-teal-500 rounded-md p-0 ml-2">
                <span className="px-2 inline-flex items-center whitespace-nowrap">
                  <span>{!version ? 'Legacy' : `Version ${version}`}</span>
                  {paymentPlan && (
                    <FaQuestionCircle
                      className="ml-1 inline align-top text-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="'Legacy' -> discount only, same shipping frequency. 'Version 3' -> discounted and shipped at once."
                    />
                  )}
                </span>
              </div>
            </dd>
          </div>
          <div className="bg-white px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
            <dt className="text-sm leading-5 font-medium text-slate-500">
              Discount
            </dt>
            <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
              {discountPercentage}%
            </dd>
          </div>
          <div className="bg-slate-100 px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
            <dt className="text-sm leading-5 font-medium text-slate-500">
              Charged status
            </dt>
            {charge && (
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                {charge.status}
              </dd>
            )}
          </div>
          <div className="bg-white px-6 py-2 flex items-center sm:grid sm:grid-cols-4 sm:gap-1">
            <dt className="text-sm leading-5 font-medium text-slate-500">
              Orders remaining
            </dt>
            {numberOfOrdersRemaining ? (
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-3">
                {numberOfOrdersRemaining}
              </dd>
            ) : null}
          </div>
        </dl>
      </div>
    </div>
  );
};
