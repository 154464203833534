import React from 'react';
import { TextArea } from './text-area';
import { Markdown } from './markdown';

interface Props {
  name: string;
  markdown: string | undefined;
  errorMessage?: string;
}

const BaseMarkdownEditor = (
  { name, markdown, errorMessage }: Props,
  ref: React.Ref<HTMLTextAreaElement>,
): React.ReactElement => {
  const columnClasses = 'flex flex-col min-h-full w-1/2';

  return (
    <div className="flex space-x-5 h-full min-h-[50vh]">
      <div className={columnClasses}>
        <TextArea
          rows={14}
          ref={ref}
          label="Markdown"
          placeholder="Start typing..."
          name={name}
          errorMessage={errorMessage}
        />
      </div>
      <div className={columnClasses}>
        <h3 className="heading-sm mb-3">Preview</h3>
        <div className="h-full border border-slate-400 rounded-md bg-slate-100">
          <Markdown
            src={markdown ?? ''}
            className="h-full px-3 py-2 tracking-tight"
          />
        </div>
      </div>
    </div>
  );
};

export const MarkdownEditor = React.forwardRef<HTMLTextAreaElement, Props>(
  BaseMarkdownEditor,
);
